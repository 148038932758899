import { IEmployeeProgramTaxRange } from 'app/shared/model/employee-program-tax-range.model';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

export interface TaxRangeCardDetailProps {
    tax: IEmployeeProgramTaxRange;
}

export const TaxRangeCardDetail = (props: TaxRangeCardDetailProps): JSX.Element => {
    const { tax } = props;
    return (
        <>
            <Row>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="taxes.mode">Type</Translate>
                        </dt>
                        <dd>
                            {tax.mode &&
                                {
                                    LOWER_INCLUSIVE_UPPER_INCLUSIVE: translate('TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_INCLUSIVE'),
                                    LOWER_EXCLUSIVE_UPPER_INCLUSIVE: translate('TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_INCLUSIVE'),
                                    LOWER_INCLUSIVE_UPPER_EXCLUSIVE: translate('TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_EXCLUSIVE'),
                                    LOWER_EXCLUSIVE_UPPER_EXCLUSIVE: translate('TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_EXCLUSIVE'),
                                }[tax.mode]}
                        </dd>
                    </dl>
                </Col>
                <Col md="8">
                    <dl>
                        <dt>
                            <Translate contentKey="taxes.taxAmount">taxAmount</Translate>
                        </dt>
                        <dd>
                            {tax.taxAmount?.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }) ?? ''}
                        </dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="taxes.lowerAmount">lowerAmount</Translate>
                        </dt>
                        <dd>
                            {tax.lowerAmount?.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }) ?? ''}
                        </dd>
                    </dl>
                </Col>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="taxes.upperAmount">upperAmount</Translate>
                        </dt>
                        <dd>
                            {tax.upperAmount?.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }) ?? ''}
                        </dd>
                    </dl>
                </Col>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="taxes.active">active</Translate>
                        </dt>
                        <dd>{tax.active ? 'Sim' : 'Não'}</dd>
                    </dl>
                </Col>
            </Row>
        </>
    );
};
