import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React, { Component } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { getOrganization, resetEntity } from './organization.reducer';

export interface IOrganizationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class OrganizationDetail extends Component<IOrganizationDetailProps> {
    componentWillMount() {
        if (this.props.match.params.id) {
            this.props.getOrganization(this.props.match.params.id);
        }
    }

    componentWillUnmount() {
        this.props.resetEntity();
    }

    render() {
        const { organization } = this.props;
        return (
            <div>
                <h2>
                    <Translate contentKey="organizationDetail.title">Organization</Translate>
                </h2>
                <div className="margin-left-15">
                    <Row>
                        <dl className="jh-entity-details">
                            <dt>
                                <Translate contentKey="organization.name">Name</Translate>
                            </dt>
                            <dd>{organization.name}</dd>
                            <dt>
                                <Translate contentKey="organization.organizationKey">Organization Key</Translate>
                            </dt>
                            <dd>{organization.organizationKey}</dd>
                        </dl>
                    </Row>
                </div>
                <Button tag={Link} to="/entity/organization" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" />{' '}
                    <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                    </span>
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    organization: storeState.organization.organization,
});

const mapDispatchToProps = { getOrganization, resetEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetail);
