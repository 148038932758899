import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import Organization from './organization';
import OrganizationDeleteDialog from './organization-delete-dialog';
import OrganizationDetail from './organization-detail';
import OrganizationManager from './organization.manager';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/new`} component={OrganizationManager} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={OrganizationManager} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={OrganizationDetail} />
            <ErrorBoundaryRoute path={match.url} component={Organization} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={OrganizationDeleteDialog} />
    </>
);

export default Routes;
