import { AUTHORITIES } from 'app/config/constants';
import PrivateRoute from 'app/shared/auth/private-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import Company from './code-base/company';
import Organization from './code-base/organization';
import Person from './code-base/person';
import CompanyEmployee from './company-employee';
import EmployeeAnticipation from './employee-anticipation';
import EmployeeAnticipationBatch from './employee-anticipation/employee-anticipation-batch';
import EmployeeMonthlyCredit from './employee-monthly-credit';
import EmployeeProgram from './employee-program';
import EmployeeProgramFinancial from './employee-program-financial';
import EmployeeProgramTaxRange from './employee-program-tax-range';
import EmployeeTimeEntry from './employee-time-entry';

/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
    <div>
        <Switch>
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/organization`} component={Organization} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/company`} component={Company} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/person`} component={Person} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/employee-program`} component={EmployeeProgram} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/employee-anticipation-batch`} component={EmployeeAnticipationBatch} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/employee-time-entry`} component={EmployeeTimeEntry} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/employee-program-financial`} component={EmployeeProgramFinancial} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/employee-program-tax-range`} component={EmployeeProgramTaxRange} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/company-employee`} component={CompanyEmployee} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/employee-monthly-credit`} component={EmployeeMonthlyCredit} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} path={`${match.url}/employee-anticipation`} component={EmployeeAnticipation} />
            {/* jhipster-needle-add-route-path - JHipster will add routes here */}
        </Switch>
    </div>
);

export default Routes;
