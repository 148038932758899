import { ICardEdition } from './card-edition';
import { PixKeyType } from './enumerations/pix-key-type';

export interface IPix extends ICardEdition {
    id: string;
    key: string;
    keyType: PixKeyType;
}

export const defaultValue: Readonly<IPix> = {
    id: undefined,
    key: undefined,
    keyType: undefined,
    isNew: true,
};
