import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { IAddress } from 'app/shared/model/address.model';
import { IBankAccount } from 'app/shared/model/bank-account.model';
import { ICompanyEmployee, MaritalStatus, writeToISODuration } from 'app/shared/model/company-employee.model';
import { IPhone } from 'app/shared/model/phone.model';
import { IPix } from 'app/shared/model/pix.model';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { getBanks } from '../code-base/bank/bank.reducer';
import { searchCities } from '../code-base/city/city.reducer';
import { CompanyAccountsManager } from '../code-base/company/company-accounts-manager';
import { CompanyAddressesManager } from '../code-base/company/company-addresses-manager';
import { EditActionType } from '../code-base/company/company-manager';
import { CompanyPhonesManager } from '../code-base/company/company-phones-manager';
import { CompanyPixListManager } from '../code-base/company/company-pix-manager';
import { createEntity, getEntity, reset, updateEntity } from './company-employee.reducer';

export interface ICompanyEmployeeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICompanyEmployeeUpdateState {
    isNew: boolean;
    editing: boolean;
    companyEmployee?: ICompanyEmployee;
    bankAccount?: IBankAccount;
    pix?: IPix[];
    address?: IAddress;
    mainPhoneNumber?: IPhone;
}

export class CompanyEmployeeUpdate extends Component<ICompanyEmployeeUpdateProps, ICompanyEmployeeUpdateState> {
    days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

    constructor(props) {
        super(props);
        this.state = {
            isNew: !this.props.match.params || !this.props.match.params.id,
            editing: false,
            companyEmployee: null,
            address: null,
            mainPhoneNumber: null,
            pix: null,
        };
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
        if (nextProps.fetching !== this.props.fetching && nextProps.fetching) {
            this.setState({
                companyEmployee: nextProps.companyEmployeeEntity,
                mainPhoneNumber: nextProps.companyEmployeeEntity?.info?.mainPhoneNumber ?? null,
                address: nextProps.companyEmployeeEntity?.info?.address ?? null,
                bankAccount: nextProps.companyEmployeeEntity.bankAccount ?? null,
                pix: nextProps.companyEmployeeEntity.pix ?? null,
            });
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }
    }

    saveEntity = (event, errors, values) => {
        if (errors.length === 0) {
            const { companyEmployee: companyEmployeeEntity } = this.state;

            const entity = {
                ...companyEmployeeEntity,
                ...values,
                person: {
                    ...companyEmployeeEntity.person,
                    identification: values.person.identification,
                },
                bankAccount: this.state.bankAccount,
                pixList: this.state.pix,
            } as ICompanyEmployee;

            entity.info = {
                ...entity.info,
                mainPhoneNumber: this.state.mainPhoneNumber,
                address: this.state.address,
            };

            writeToISODuration(entity);

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };

    handleClose = () => {
        this.props.history.goBack();
    };

    handleAccountsUpdated = (it: IBankAccount[]) => {
        this.setState({
            ...this.state,
            bankAccount: it.length > 0 ? it[0] : null,
        });
    };

    handlePhonesChanged = (it: IPhone[]) => {
        this.setState({
            mainPhoneNumber: it.length > 0 ? it[0] : null,
        });
    };

    handleAddressesUpdated = (it: IAddress[]) => {
        this.setState({
            ...this.state,
            address: it.length > 0 ? it[0] : null,
        });
    };

    handlePixListUpdated = (it: IPix[]) => {
        this.setState({
            ...this.state,
            pix: it.length > 0 ? it : null,
        });
    };

    handleAccountsEdit = (it: EditActionType) => {
        this.setState(prevState => ({
            ...prevState,
            editing: it === EditActionType.START,
        }));
    };

    handlePhonesEdit = (it: EditActionType) => {
        this.setState(prevState => ({
            ...prevState,
            editing: it === EditActionType.START,
        }));
    };

    handleAddressesEdit = (it: EditActionType) => {
        this.setState(prevState => ({
            ...prevState,
            editing: it === EditActionType.START,
        }));
    };

    handlePixListEdit = (it: EditActionType) => {
        this.setState(prevState => ({
            ...prevState,
            editing: it === EditActionType.START,
        }));
    };

    render() {
        const { loading, updating } = this.props;
        const { isNew, editing, companyEmployee } = this.state;

        if (!isNew && (companyEmployee == null || companyEmployee.id == null)) {
            return <Loading />;
        }

        const phones = [];
        if (this.state.mainPhoneNumber != null) {
            phones.push(this.state.mainPhoneNumber);
        }

        const bankAccounts = [];
        if (this.state.bankAccount != null) {
            bankAccounts.push(this.state.bankAccount);
        }

        const addresses = [];
        if (this.state.address != null) {
            addresses.push(this.state.address);
        }

        const pixList = [];
        if (this.state.pix != null) {
            pixList.push(this.state.pix);
        }

        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="giroEmployeeSalaryAdvanceApp.companyEmployee.home.createOrEditLabel">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.createOrEditLabel">Create or edit a CompanyEmployee</Translate>
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        {loading ? (
                            <Loading />
                        ) : (
                            <div>
                                <AvForm disabled={updating || editing} model={isNew ? {} : companyEmployee} onSubmit={this.saveEntity}>
                                    {!isNew ? (
                                        <AvGroup>
                                            <Label for="company-employee-id">
                                                <Translate contentKey="global.field.id">ID</Translate>
                                            </Label>
                                            <AvInput id="company-employee-id" type="text" className="form-control" name="id" required readOnly />
                                        </AvGroup>
                                    ) : null}
                                    <AvGroup>
                                        <Label id="activeLabel" check>
                                            <AvInput id="company-employee-active" type="checkbox" className="form-control" name="active" />
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.active">Active</Translate>
                                        </Label>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="dailyWorkloadLabel" for="company-employee-dailyWorkload">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.dailyWorkload">Daily Workload</Translate>
                                            <p>
                                                Formato:{' '}
                                                <i>
                                                    PT<b>|HORAS|</b>H<b>|MINUTOS|</b>M
                                                </i>
                                            </p>
                                        </Label>
                                        <AvField
                                            id="company-employee-dailyWorkload"
                                            type="text"
                                            name="dailyWorkload"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                            }}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="monthlyWorkloadLabel" for="company-employee-monthlyWorkload">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.monthlyWorkload">Monthly Workload</Translate>
                                            <p>
                                                Formato:{' '}
                                                <i>
                                                    PT<b>|HORAS|</b>H<b>|MINUTOS|</b>M
                                                </i>
                                            </p>
                                        </Label>
                                        <AvField
                                            id="company-employee-monthlyWorkload"
                                            type="text"
                                            name="monthlyWorkload"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                            }}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="hourlyRateLabel" for="company-employee-hourlyRate">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.hourlyRate">Hourly Rate</Translate>
                                        </Label>
                                        <AvField
                                            id="company-employee-hourlyRate"
                                            type="text"
                                            name="hourlyRate"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                                max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                                number: { value: true, errorMessage: translate('entity.validation.number') },
                                            }}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="salaryLabel" for="company-employee-salary">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.salary">Salary</Translate>
                                        </Label>
                                        <AvField
                                            id="company-employee-salary"
                                            type="text"
                                            name="salary"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                                max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                                number: { value: true, errorMessage: translate('entity.validation.number') },
                                            }}
                                        />
                                    </AvGroup>
                                  <AvGroup>
                                    <Label id="identificationNumberLabel" for="company-employee-identificationNumber">
                                      <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.identificationNumber">Identification Number</Translate>
                                    </Label>
                                    <AvField
                                      id="company-employee-identificationNumber"
                                      type="text"
                                      name="identificationNumber"
                                      validate={{
                                        required: { value: true, errorMessage: translate('entity.validation.required') },
                                        minLength: { value: 0, errorMessage: translate('entity.validation.minlength', { min: 0 }) },
                                        maxLength: { value: 128, errorMessage: translate('entity.validation.maxlength', { max: 128 }) },
                                      }}
                                    />
                                  </AvGroup>
                                    <AvGroup>
                                        <Label id="personIdentificationLabel" for="company-employee-personIdentification">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.identification">Identification</Translate>
                                        </Label>
                                        <AvField
                                            id="company-employee-personIdentification"
                                            type="text"
                                            name="person.identification"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                            }}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="canAnticipateLabel" check>
                                            <AvInput id="company-employee-canAnticipate" type="checkbox" className="form-control" name="canAnticipate" />
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.canAnticipate">Can Anticipate</Translate>
                                        </Label>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="contractStartDateLabel" for="company-employee-contractStartDate">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.contractStartDate">Contract Start Date</Translate>
                                        </Label>
                                        <AvField
                                            id="company-employee-contractStartDate"
                                            type="date"
                                            className="form-control"
                                            name="contractStartDate"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                            }}
                                        />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="contractEndDateLabel" for="company-employee-contractEndDate">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.contractEndDate">Contract End Date</Translate>
                                        </Label>
                                        <AvField id="company-employee-contractEndDate" type="date" className="form-control" name="contractEndDate" />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="workdaysLabel" for="company-employee-workdays">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.workdays">Workdays</Translate>
                                        </Label>
                                        <AvInput type="select" className="form-control" name="workdays" multiple required>
                                            {this.days.map(day => (
                                                <option value={day} key={day}>
                                                    {translate(`giroEmployeeSalaryAdvanceApp.companyEmployee.workdaysNames.${day}`)}
                                                </option>
                                            ))}
                                        </AvInput>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="maritalStatusLabel" for="companyEmployyee-maritalStatus">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.maritalStatus">Marital Status</Translate>
                                        </Label>
                                        <AvInput id="companyEmployyee-maritalStatus" type="select" className="form-control" name="info.maritalStatus" value={companyEmployee ? companyEmployee.info?.maritalStatus : ''}>
                                            <option value={undefined}></option>
                                            <option value={MaritalStatus.SINGLE}>{translate(`giroEmployeeSalaryAdvanceApp.maritalStatus.${MaritalStatus.SINGLE}`)}</option>
                                            <option value={MaritalStatus.MARRIED}>{translate(`giroEmployeeSalaryAdvanceApp.maritalStatus.${MaritalStatus.MARRIED}`)}</option>
                                            <option value={MaritalStatus.SEPARATED}>{translate(`giroEmployeeSalaryAdvanceApp.maritalStatus.${MaritalStatus.SEPARATED}`)}</option>
                                            <option value={MaritalStatus.DIVORCED}>{translate(`giroEmployeeSalaryAdvanceApp.maritalStatus.${MaritalStatus.DIVORCED}`)}</option>
                                            <option value={MaritalStatus.WIDOWED}>{translate(`giroEmployeeSalaryAdvanceApp.maritalStatus.${MaritalStatus.WIDOWED}`)}</option>
                                        </AvInput>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="nationality" for="company-employee-nationality">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.nationality">Nationality</Translate>
                                        </Label>
                                        <AvField id="company-employee-nationality" type="text" name="info.nationality" />
                                    </AvGroup>
                                    <AvGroup>
                                        <Label id="nationality" for="company-employee-profession">
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.profession">Nationality</Translate>
                                        </Label>
                                        <AvField id="company-employee-profession" type="text" name="info.profession" />
                                    </AvGroup>
                                    <CompanyPixListManager
                                        showModal={this.props.showModal}
                                        hideModal={this.props.hideModal}
                                        updateCallback={it => this.handlePixListUpdated(it)}
                                        editCallback={it => this.handlePixListEdit(it)}
                                        pixList={companyEmployee ? companyEmployee.pixList : []}
                                    />
                                    <CompanyAccountsManager
                                        showModal={this.props.showModal}
                                        hideModal={this.props.hideModal}
                                        getBanks={this.props.getBanks}
                                        accounts={bankAccounts}
                                        isSingle
                                        updateCallback={it => this.handleAccountsUpdated(it)}
                                        editCallback={it => this.handleAccountsEdit(it)}
                                    />
                                    <CompanyAddressesManager
                                        showModal={this.props.showModal}
                                        hideModal={this.props.hideModal}
                                        searchCities={this.props.searchCities}
                                        addresses={addresses}
                                        isSingle
                                        updateCallback={it => this.handleAddressesUpdated(it)}
                                        editCallback={it => this.handleAddressesEdit(it)}
                                    />
                                    <CompanyPhonesManager phones={phones} isSingle updateCallback={this.handlePhonesChanged} editCallback={this.handlePhonesEdit} />
                                    <Button tag={Link} id="cancel-save" to="/entity/company-employee" replace color="info">
                                        <FontAwesomeIcon icon="arrow-left" />
                                        &nbsp;
                                        <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.back">Back</Translate>
                                        </span>
                                    </Button>
                                    &nbsp;
                                    <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                        <FontAwesomeIcon icon="save" />
                                        &nbsp;
                                        <Translate contentKey="entity.action.save">Save</Translate>
                                    </Button>
                                </AvForm>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    companyEmployeeEntity: storeState.companyEmployee.entity,
    loading: storeState.companyEmployee.loading,
    updating: storeState.companyEmployee.updating,
    fetching: storeState.companyEmployee.fetching,
    updateSuccess: storeState.companyEmployee.updateSuccess,
});

const mapDispatchToProps = {
    getEntity,
    updateEntity,
    createEntity,
    searchCities,
    getBanks,
    hideModal,
    showModal,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmployeeUpdate);
