import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import EmployeeProgramTaxRange from './employee-program-tax-range';
import EmployeeProgramTaxRangeDeleteDialog from './employee-program-tax-range-delete-dialog';
import EmployeeProgramTaxRangeDetail from './employee-program-tax-range-detail';
import EmployeeProgramTaxRangeUpdate from './employee-program-tax-range-update';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EmployeeProgramTaxRangeUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EmployeeProgramTaxRangeUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EmployeeProgramTaxRangeDetail} />
            <ErrorBoundaryRoute path={match.url} component={EmployeeProgramTaxRange} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={EmployeeProgramTaxRangeDeleteDialog} />
    </>
);

export default Routes;
