import { Authority } from 'app/shared/model/enumerations/authorities';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { connect } from 'react-redux';
import CompanyDashboard from './company-dashboard';
import HomeLoggedContainer from './home-logged';
import HomeUnauthenticatedContainer from './home-unauthenticated';
import './home.scss';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
    const { account, isAuthenticated } = props;

    if (!isAuthenticated || !account?.login) {
        return <HomeUnauthenticatedContainer />;
    }

    if (account?.authorities?.some(it => Authority.ADMIN === it)) {
        return <HomeLoggedContainer />;
    }

    if (account?.authorities?.some(it => Authority.COMPANY === it)) {
        return <CompanyDashboard />;
    }

    return <HomeUnauthenticatedContainer />;
};

const mapStateToProps = ({ authentication }: IRootState) => ({
    account: authentication.account,
    isAuthenticated: authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
