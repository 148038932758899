import { AddressCardDetail } from 'app/entities/code-base/address/address-card-detail';
import { ICompanyEmployee } from 'app/shared/model/company-employee.model';
import StringUtils from 'app/util/StringUtils';
import { isCPF } from 'brazilian-values';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
export interface CompanyEmployeeDetailProps {
    employee: ICompanyEmployee;
}

export const CompanyEmployeeDetail = (props: CompanyEmployeeDetailProps): JSX.Element => {
    const { employee } = props;
    return (
        <>
            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.name">Name</Translate>
                </dt>
                <dd>{employee.person?.name ?? ''}</dd>
            </dl>
            <>
                <dt>
                    <Translate contentKey={`giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.identification.${employee.person?.type ?? 'label'}`}>Identification</Translate>
                </dt>
                {employee.person?.type && (
                    <>
                        {
                            {
                                INDIVIDUAL: <dd>{StringUtils.addCpfMask(employee.person?.identification)}</dd>,
                                CORPORATION: <dd>{StringUtils.addCnpjMask(employee.person?.identification)}</dd>,
                            }[employee.person.type]
                        }
                    </>
                )}
                <dd>
                    {!employee.person?.identification || employee.person?.identification === '' || !isCPF(employee.person?.identification) && (
                        <span style={{ color: 'red' }}>
                            <Translate contentKey={`giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.invalidIdentification`}> Invalid Identification </Translate>{' '}
                        </span>
                    )}
                </dd>
            </>
            <dt>
                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.email">Email</Translate>
            </dt>
            <dd>{employee.person?.email ?? ''}</dd>

            <dt>
                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.maritalStatus">maritalStatus</Translate>
            </dt>
            <dd>
                <Translate contentKey={`giroEmployeeSalaryAdvanceApp.maritalStatus.${employee.info?.maritalStatus}`}>maritalStatus</Translate>
            </dd>
            <dt>
                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.profession">profession</Translate>
            </dt>
            <dd>{employee.info?.profession ?? ''}</dd>

            <dt>
                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.nationality">nationality</Translate>
            </dt>
            <dd>{employee.info?.nationality ?? ''}</dd>

            <dt>
                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.mainPhoneNumber">mainPhoneNumber</Translate>
            </dt>
            <dd>{employee.info?.mainPhoneNumber?.number ?? ''}</dd>

            <dt>
                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.address">address</Translate>
            </dt>
            <dd>{employee.info?.address && <AddressCardDetail key={employee.info?.address?.id} address={employee.info?.address} />}</dd>

            <dt>
                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.phone">phone</Translate>
            </dt>
            <dd>{employee.person?.phones?.map(it => <p>{it?.number ?? 'Não informado'}</p>) ?? 'N/D'}</dd>

            <dt>
                <span>PIX</span>
            </dt>
            {employee.pixList && employee.pixList.map(pix => (
                <>
                    <dt>
                        <span>Chave PIX</span>
                    </dt>
                    <dd>{pix?.key ?? ''}</dd>
                    <dt>
                        <span>Tipo de Chave PIX</span>
                    </dt>
                    <dd>{pix?.keyType ? translate(`pixKeyType.${pix.keyType}`) : ''}</dd>
                </>

            ))}
        </>
    );
};

export default CompanyEmployeeDetail;
