import * as React from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { MODAL_TYPE_SELECT_OPTION, MODAL_TYPE_WARNING } from './modal-types';
import SelectOptionModal from './selectOption/SelectOptionModal';
import WarningModal from './warningModal/WarningModal';

const MODAL_COMPONENTS = {
    [MODAL_TYPE_SELECT_OPTION]: SelectOptionModal,
    [MODAL_TYPE_WARNING]: WarningModal,
};

export interface IModalRootProps extends StateProps, DispatchProps {
    type: string;
    payload: {};
}

export interface IModalRootState {
    type?: string;
    payload: {};
}

export class ModalRoot extends React.Component<IModalRootProps, IModalRootState> {
    constructor(props) {
        super(props);

        this.state = {
            type: null,
            payload: null,
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({ type: newProps.type, payload: newProps.payload });
    }

    render() {
        const ModalComponent = MODAL_COMPONENTS[this.state.type];
        return ModalComponent ? <ModalComponent config={this.state.payload} /> : <div />;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    type: storeState.modal.type,
    payload: storeState.modal.props,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
