import { IEmailAddress } from 'app/shared/model/email-address.model';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Col, Label, Row } from 'reactstrap';

export interface EmailAddressCardManagerProps {
    emailAddress: IEmailAddress;
    updateCallback?: (emailAddress: IEmailAddress) => void;
}

export interface EmailAddressCardManagerState {
    emailAddress: IEmailAddress;
}

export class EmailAddressCardManager extends React.Component<EmailAddressCardManagerProps, EmailAddressCardManagerState> {
    constructor(props: Readonly<EmailAddressCardManagerProps>) {
        super(props);
        this.state = {
            emailAddress: props.emailAddress,
        };
    }

    emailChange = (event: any, value: string) => {
        this.setState(
            prevState => ({
                emailAddress: { ...prevState.emailAddress, email: value },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.emailAddress);
                }
            }
        );
    };

    render() {
        const { emailAddress } = this.state;
        return (
            <>
                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="emailAddress.email">Email</Translate>
                            </Label>
                            <AvField
                                type="email"
                                className="form-control"
                                name="number"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('emailAddressManager.validate.email.required'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('emailAddressManager.validate.email.maxlength'),
                                    },
                                    email: {
                                        value: true,
                                        errorMessage: translate('emailAddressManager.validate.email.invalid'),
                                    },
                                }}
                                onChange={this.emailChange}
                                value={emailAddress.email}
                            />
                        </AvGroup>
                    </Col>
                </Row>
            </>
        );
    }
}
