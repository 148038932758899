import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getEntities } from './employee-program-tax-range.reducer';

export interface IEmployeeProgramTaxRangeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IEmployeeProgramTaxRangeState = IPaginationBaseState;

export class EmployeeProgramTaxRange extends React.Component<IEmployeeProgramTaxRangeProps, IEmployeeProgramTaxRangeState> {
    state: IEmployeeProgramTaxRangeState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    render() {
        const { employeeProgramTaxRangeList, match, totalItems } = this.props;
        return (
            <div>
                <h2 id="employee-program-tax-range-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.home.title">Employee Program Tax Ranges</Translate>
                    <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.home.createLabel">Create new Employee Program Tax Range</Translate>
                    </Link>
                </h2>
                <div className="table-responsive">
                    {employeeProgramTaxRangeList && employeeProgramTaxRangeList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('lowerAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.lowerAmount">Lower Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('upperAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.upperAmount">Upper Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('taxAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.taxAmount">Tax Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('active')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.active">Active</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('mode')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.mode">Mode</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('program.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.program">Program</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {employeeProgramTaxRangeList.map((employeeProgramTaxRange, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>
                                            <Button tag={Link} to={`${match.url}/${employeeProgramTaxRange.id}`} color="link" size="sm">
                                                {employeeProgramTaxRange.id}
                                            </Button>
                                        </td>
                                        <td>{employeeProgramTaxRange.name ?? '-'}</td>
                                        <td>
                                            {employeeProgramTaxRange.lowerAmount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>
                                            {employeeProgramTaxRange.upperAmount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>
                                            {employeeProgramTaxRange.taxAmount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>{employeeProgramTaxRange.active ? 'Sim' : 'Não'}</td>
                                        <td>
                                            <Translate contentKey={`TaxRangeStrategy.${employeeProgramTaxRange.mode}`} />
                                        </td>
                                        <td>{employeeProgramTaxRange.program ? <Link to={`employee-program/${employeeProgramTaxRange.program.id}`}>{employeeProgramTaxRange.program.name}</Link> : ''}</td>
                                        <td className="text-right">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button tag={Link} to={`${match.url}/${employeeProgramTaxRange.id}`} color="info" size="sm">
                                                    <FontAwesomeIcon icon="eye" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.view">View</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employeeProgramTaxRange.id}/edit`} color="primary" size="sm">
                                                    <FontAwesomeIcon icon="pencil-alt" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employeeProgramTaxRange.id}/delete`} color="danger" size="sm">
                                                    <FontAwesomeIcon icon="trash" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.delete">Delete</Translate>
                                                    </span>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.home.notFound">No Employee Program Tax Ranges found</Translate>
                        </div>
                    )}
                </div>
                <div className={employeeProgramTaxRangeList && employeeProgramTaxRangeList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ employeeProgramTaxRange }: IRootState) => ({
    employeeProgramTaxRangeList: employeeProgramTaxRange.entities,
    totalItems: employeeProgramTaxRange.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgramTaxRange);
