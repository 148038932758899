import { defaultValue, IBank } from 'app/shared/model/bank.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudSearchAction } from 'react-jhipster';

export const ACTION_TYPES = {
    FETCH_BANKS: 'banks/FETCH_BANKS',
};

const initialState = {
    loading: false,
    findItem: false,
    errorMessage: null,
    data: [] as ReadonlyArray<IBank>,
    entity: defaultValue,
    updating: false,
    updateSuccess: false,
    totalItems: 0,
    page: null,
};

export type BankState = Readonly<typeof initialState>;

// Reducer
export default (state: BankState = initialState, action): BankState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_BANKS):
            return {
                ...state,
                findItem: false,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_BANKS):
            return {
                ...state,
                findItem: false,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_BANKS):
            return {
                ...state,
                loading: false,
                data: action.payload.data.content,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        default:
            return state;
    }
};

const apiUrl = 'api/banks';

// Actions

export const getBanks: ICrudSearchAction<IBank> = (search, page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${search ? `&search=${search}` : ''}`;
    return {
        type: ACTION_TYPES.FETCH_BANKS,
        payload: axios.get<IBank>(requestUrl),
    };
};
