import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities } from 'app/entities/company-employee/company-employee.reducer';
import { readDailyDurationFromISODuration } from 'app/shared/model/company-employee.model';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';

export interface ICompanyEmployeeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ICompanyEmployeeState = IPaginationBaseState;

export class CompanyEmployee extends React.Component<ICompanyEmployeeProps, ICompanyEmployeeState> {
    state: ICompanyEmployeeState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    render() {
        const { companyEmployeeList, match, totalItems } = this.props;
        return (
            <div>
                <h2 id="company-employee-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.title">Company Employees</Translate>
                </h2>
                <div className="table-responsive">
                    {companyEmployeeList && companyEmployeeList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('active')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.active">Active</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('dailyWorkload')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.dailyWorkload">Daily Workload</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('identificationNumber')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.identificationNumber">Identification Number</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('canAnticipate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.canAnticipate">Can Anticipate</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('person.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.person">Person</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {companyEmployeeList.map((employee, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>{employee.id}</td>
                                        <td>{employee.active ? 'Sim' : 'Não'}</td>
                                        <td>{readDailyDurationFromISODuration(employee)}</td>
                                        <td>{employee.identificationNumber}</td>
                                        <td>{employee.canAnticipate ? 'Sim' : 'Não'}</td>
                                        <td>{employee.person.name}</td>
                                        <td className="text-right">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button tag={Link} to={`${match.url}/${employee.id}/activation`} color={employee.active ? 'danger' : 'success'} size="sm">
                                                    <FontAwesomeIcon icon={employee.active ? 'times' : 'check'} />
                                                    &nbsp;
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey={`entity.action.${employee.active ? 'inactivate' : 'activate'}`}>Inactivate</Translate>
                                                    </span>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.notFound">No Company Employees found</Translate>
                        </div>
                    )}
                </div>
                <div className={companyEmployeeList && companyEmployeeList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ companyEmployee }: IRootState) => ({
    companyEmployeeList: companyEmployee.entities,
    totalItems: companyEmployee.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmployee);
