import CompanyFeatures from 'app/company-features';
import Entities from 'app/entities';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import Register from 'app/modules/account/register/register';
import Home from 'app/modules/home/home';
import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import React from 'react';
import Loadable from 'react-loadable';
import { Switch } from 'react-router-dom';
import { AUTHORITIES } from './config/constants';
import AcceptInvite from 'app/modules/account/invite/accept-invite';


const Account = Loadable({
    loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
    loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
    loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
    loading: () => <div>loading ...</div>,
});

const Routes = () => (
    <div className="view-routes">
        <Switch>
            <ErrorBoundaryRoute path="/login" component={Login} />
            <ErrorBoundaryRoute path="/logout" component={Logout} />
            <ErrorBoundaryRoute path="/account/register" component={Register} />
            <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
            <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
            <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
            <ErrorBoundaryRoute path="/accept-invite/:token" component={AcceptInvite} />
            <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
            <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.COMPANY]} />
            <ErrorBoundaryRoute path="/" exact component={Home} />
            <PrivateRoute path="/entity" component={Entities} hasAnyAuthorities={[AUTHORITIES.USER]} />
            <PrivateRoute path="/company" component={CompanyFeatures} hasAnyAuthorities={[AUTHORITIES.COMPANY]} />
            <ErrorBoundaryRoute component={PageNotFound} />
        </Switch>
    </div>
);

export default Routes;
