import { AUTHORITIES } from 'app/config/constants';
import PrivateRoute from 'app/shared/auth/private-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import CompanyEmployee from './company-employee';
import EmployeeAnticipation from './employee-anticipation';

/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
    <div>
        <Switch>
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.COMPANY]} path={`${match.url}/company-employee`} component={CompanyEmployee} />
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.COMPANY]} path={`${match.url}/employee-anticipation`} component={EmployeeAnticipation} />
            {/* jhipster-needle-add-route-path - JHipster will add routes here */}
        </Switch>
    </div>
);
CompanyEmployee;

export default Routes;
