import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityCard } from 'app/components/card/entityCard/entity-card';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { defaultValue, IPix } from 'app/shared/model/pix.model';
import { IRootState } from 'app/shared/reducers';
import React, { Component } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { PixCardDetail } from '../pix/pix-card-detail';
import { PixCardManager } from '../pix/pix-card-manager';
import { EditActionType } from './company-manager';

export interface ICompanyPixListManagerProps extends StateProps, DispatchProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    pixList?: IPix[];
    updateCallback?: (pixList: IPix[]) => void;
    editCallback?: (action: EditActionType) => void;
}

export interface ICompanyPixListManagerState {
    pixList: IPix[];
    editing: boolean;
}

type OnClickEvent = React.MouseEvent<any, MouseEvent>;

export class CompanyPixListManager extends Component<ICompanyPixListManagerProps, ICompanyPixListManagerState> {
    constructor(props: Readonly<ICompanyPixListManagerProps>) {
        super(props);
        this.state = {
            pixList: props.pixList ? props.pixList : [],
            editing: false,
        };
    }

    handleAdd = (event: OnClickEvent): void => {
        const { pixList } = this.state;
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
                pixList: [...pixList, defaultValue],
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleRemove = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                pixList: this.state.pixList.filter((_value, i) => {
                    return i !== index;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.pixList);
                }
            }
        );
    };

    handleCancel = (index: number) => {
        if (this.state.pixList[index].isNew) {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                    pixList: this.state.pixList.filter((_value, i) => {
                        return i !== index;
                    }),
                }),
                () => {
                    if (this.props.updateCallback) {
                        this.props.updateCallback(this.state.pixList);
                    }
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        } else {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                }),
                () => {
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        }
    };

    handleEdit = () => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleUpdate = (index: number, update: IPix): void => {
        const { pixList } = this.state;
        this.setState(prevState => ({
            ...prevState,
            pixList: pixList.map((value: IPix, i: number) => {
                return i === index ? update : value;
            }),
        }));
    };

    handleSave = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
                pixList: prevState.pixList.map((value: IPix, i: number) => {
                    return i === index ? { ...value, isNew: false } : value;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.pixList);
                }
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.FINISH);
                }
            }
        );
    };

    render() {
        const { pixList, editing } = this.state;

        return (
            <>
                <h3 id="company-pix-heading">
                    <Translate contentKey="pixList.title"> Pix Keys </Translate>

                    <Button color="primary" className="float-right" type="button" disabled={editing} onClick={this.handleAdd}>
                        <FontAwesomeIcon icon="plus" />
                        <Translate contentKey="pixList.createLabel">Create a new pix key</Translate>
                    </Button>
                </h3>
                <div className="pix-manager">
                    {pixList && pixList.length > 0 ? (
                        pixList.map((pix, i) => (
                            <EntityCard key={i} entity={pix} editable={!editing} editCallback={() => this.handleEdit()} saveCallback={() => this.handleSave(i)} cancelCallback={() => this.handleCancel(i)} isRemoveable={false}>
                                {{
                                    manager: <PixCardManager pix={pix} updateCallback={it => this.handleUpdate(i, it)} showModal={this.props.showModal} hideModal={this.props.hideModal} />,
                                    detail: <PixCardDetail pix={pix} />,
                                }}
                            </EntityCard>
                        ))
                    ) : (
                        <p>
                            <Translate contentKey="pixManager.empty.pixList">No registered pix key</Translate>
                        </p>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPixListManager);
