import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { AddressType, IAddress } from 'app/shared/model/address.model';
import { ICity } from 'app/shared/model/city.model';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Button, Col, Label, Row } from 'reactstrap';
import { searchCities } from '../city/city.reducer';

export interface IAddressCardManagerProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    searchCities: typeof searchCities;
    address: IAddress;
    updateCallback?: (emailAddress: IAddress) => void;
}

export interface IAddressCardManagerState {
    address: IAddress;
}

export class AddressCardManager extends React.Component<IAddressCardManagerProps, IAddressCardManagerState> {
    constructor(props: Readonly<IAddressCardManagerProps>) {
        super(props);
        this.state = {
            address: props.address,
        };
    }

    handleOnCityPressed = (event: any) => {
        this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
            displayProperty: [['name'], ['stateAcronym']],
            title: translate('addressManager.city.search'),
            action: this.props.searchCities,
            placeholderInput: translate('addressManager.city.placeholder'),
            stateAction: 'city',
            cancelCallback: () => this.props.hideModal(),
            saveCallback: (item: any) => this.handleCitySelected(item),
        });
        event.preventDefault();
    };

    handleCitySelected = (item: ICity) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, city: item },
            }),
            () => this.updateCallback()
        );
        this.props.hideModal();
    };

    handleNameChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, name: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleTypeChange = (event: OnChangeEvent, value: AddressType) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, type: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleStreetChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, street: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleNumberChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, number: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleComplementChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, complement: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleNeighborhoodChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, neighborhood: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleZipCodeChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            ps => ({
                ...ps,
                address: { ...ps.address, zipCode: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    updateCallback(): void {
        if (this.props.updateCallback) {
            this.props.updateCallback(this.state.address);
        }
    }

    render() {
        const { address } = this.state;
        return (
            <>
                <Row>
                    <Col md="8">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.name">Name</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="name"
                                validate={{
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('addressManager.validate.name.minlength'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('addressManager.validate.name.maxlength'),
                                    },
                                }}
                                onChange={this.handleNameChange}
                                value={address.name}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="4">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.type">Type</Translate>
                            </Label>
                            <AvField type="select" className="form-control" name="type" onChange={this.handleTypeChange} value={address.type}>
                                <option value={AddressType.HOME}>{translate('addressType.HOME')}</option>
                                <option value={AddressType.PROFESSIONAL}>{translate('addressType.PROFESSIONAL')}</option>
                                <option value={AddressType.OTHER}>{translate('addressType.OTHER')}</option>
                            </AvField>
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="9">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.street">Street</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="street"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('addressManager.validate.street.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('addressManager.validate.street.minlength'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('addressManager.validate.street.maxlength'),
                                    },
                                }}
                                onChange={this.handleStreetChange}
                                value={address.street}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="3">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.number">Number</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="number"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('addressManager.validate.number.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('addressManager.validate.number.minlength'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('addressManager.validate.number.maxlength'),
                                    },
                                }}
                                onChange={this.handleNumberChange}
                                value={address.number}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.complement">Complement</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="complement"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('addressManager.validate.complement.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('addressManager.validate.complement.minlength'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('addressManager.validate.complement.maxlength'),
                                    },
                                }}
                                onChange={this.handleComplementChange}
                                value={address.complement}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="6">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.neighborhood">Neighborhood</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="neighborhood"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('addressManager.validate.neighborhood.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('addressManager.validate.neighborhood.minlength'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('addressManager.validate.neighborhood.maxlength'),
                                    },
                                }}
                                onChange={this.handleNeighborhoodChange}
                                value={address.neighborhood}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="7" style={{ paddingRight: '0px' }}>
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.city">City</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="city"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('addressManager.validate.city.required'),
                                    },
                                }}
                                onFocus={this.handleOnCityPressed}
                                onClick={this.handleOnCityPressed}
                                readOnly
                                value={address.city ? address.city.name + '-' + address.city.stateAcronym : ''}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="1" style={{ paddingLeft: '0px' }}>
                        <Button color="primary" style={{ marginTop: '30px' }} type="button" onClick={this.handleOnCityPressed}>
                            <FontAwesomeIcon icon="search" />
                        </Button>
                    </Col>
                    <Col md="4">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="address.zipCode">Zip Code</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="zipCode"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('addressManager.validate.zipCode.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('addressManager.validate.zipCode.minlength'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('addressManager.validate.zipCode.maxlength'),
                                    },
                                }}
                                onChange={this.handleZipCodeChange}
                                value={address.zipCode}
                            />
                        </AvGroup>
                    </Col>
                </Row>
            </>
        );
    }
}
