import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { getEntities as getCompanyEmployees } from 'app/entities/company-employee/company-employee.reducer';
import { getEntities as getEmployeeMonthlyCredits } from 'app/entities/employee-monthly-credit/employee-monthly-credit.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './employee-time-entry.reducer';

export interface IEmployeeTimeEntryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEmployeeTimeEntryUpdateState {
    isNew: boolean;
    employeeId: string;
    monthId: string;
}

export class EmployeeTimeEntryUpdate extends React.Component<IEmployeeTimeEntryUpdateProps, IEmployeeTimeEntryUpdateState> {
    constructor(props) {
        super(props);
        this.state = {
            employeeId: '0',
            monthId: '0',
            isNew: !this.props.match.params || !this.props.match.params.id,
        };
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }

        this.props.getCompanyEmployees();
        this.props.getEmployeeMonthlyCredits();
    }

    saveEntity = (event, errors, values) => {
        if (errors.length === 0) {
            const { employeeTimeEntryEntity } = this.props;
            const entity = {
                ...employeeTimeEntryEntity,
                ...values,
            };

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };

    handleClose = () => {
        this.props.history.push('/entity/employee-time-entry');
    };

    render() {
        const { employeeTimeEntryEntity, companyEmployees, employeeMonthlyCredits, loading, updating } = this.props;
        const { isNew } = this.state;

        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.home.createOrEditLabel">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.home.createOrEditLabel">Create or edit a EmployeeTimeEntry</Translate>
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        {loading ? (
                            <Loading />
                        ) : (
                            <AvForm model={isNew ? {} : employeeTimeEntryEntity} onSubmit={this.saveEntity}>
                                {!isNew ? (
                                    <AvGroup>
                                        <Label for="employee-time-entry-id">
                                            <Translate contentKey="global.field.id">ID</Translate>
                                        </Label>
                                        <AvInput id="employee-time-entry-id" type="text" className="form-control" name="id" required readOnly />
                                    </AvGroup>
                                ) : null}
                                <AvGroup>
                                    <Label id="hourlyRateLabel" for="employee-time-entry-hourlyRate">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.hourlyRate">Hourly Rate</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-time-entry-hourlyRate"
                                        type="text"
                                        name="hourlyRate"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 1000, errorMessage: translate('entity.validation.max', { max: 1000 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="durationLabel" for="employee-time-entry-duration">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.duration">Duration</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-time-entry-duration"
                                        type="text"
                                        name="duration"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="employeeIdentificationNumberLabel" for="employee-time-entry-employeeIdentificationNumber">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.employeeIdentificationNumber">Employee Identification Number</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-time-entry-employeeIdentificationNumber"
                                        type="text"
                                        name="employeeIdentificationNumber"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            minLength: { value: 0, errorMessage: translate('entity.validation.minlength', { min: 0 }) },
                                            maxLength: { value: 128, errorMessage: translate('entity.validation.maxlength', { max: 128 }) },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="dateLabel" for="employee-time-entry-date">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.date">Date</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-time-entry-date"
                                        type="date"
                                        className="form-control"
                                        name="date"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="creditAmountLabel" for="employee-time-entry-creditAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.creditAmount">Credit Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-time-entry-creditAmount"
                                        type="text"
                                        name="creditAmount"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="statusLabel" for="employee-time-entry-status">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.status">Status</Translate>
                                    </Label>
                                    <AvInput id="employee-time-entry-status" type="select" className="form-control" name="status" value={(!isNew && employeeTimeEntryEntity.status) || 'PENDING'}>
                                        <option value="PENDING">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.PENDING')}</option>
                                        <option value="NEW">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.NEW')}</option>
                                        <option value="REJECTED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.REJECTED')}</option>
                                        <option value="PROCESSED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.PROCESSED')}</option>
                                    </AvInput>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="employee-time-entry-employee">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.employee">Employee</Translate>
                                    </Label>
                                    <AvInput id="employee-time-entry-employee" type="select" className="form-control" name="employee.id" value={isNew ? companyEmployees[0] && companyEmployees[0].id : employeeTimeEntryEntity.employee.id} required>
                                        {companyEmployees
                                            ? companyEmployees.map(otherEntity => (
                                                  <option value={otherEntity.id} key={otherEntity.id}>
                                                      {otherEntity.id}
                                                  </option>
                                              ))
                                            : null}
                                    </AvInput>
                                    <AvFeedback>
                                        <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                    </AvFeedback>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="employee-time-entry-month">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.month">Month</Translate>
                                    </Label>
                                    <AvInput id="employee-time-entry-month" type="select" className="form-control" name="month.id" value={isNew ? employeeMonthlyCredits[0] && employeeMonthlyCredits[0].id : employeeTimeEntryEntity.month.id} required>
                                        {employeeMonthlyCredits
                                            ? employeeMonthlyCredits.map(otherEntity => (
                                                  <option value={otherEntity.id} key={otherEntity.id}>
                                                      {otherEntity.id}
                                                  </option>
                                              ))
                                            : null}
                                    </AvInput>
                                    <AvFeedback>
                                        <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                    </AvFeedback>
                                </AvGroup>
                                <Button tag={Link} id="cancel-save" to="/entity/employee-time-entry" replace color="info">
                                    <FontAwesomeIcon icon="arrow-left" />
                                    &nbsp;
                                    <span className="d-none d-md-inline">
                                        <Translate contentKey="entity.action.back">Back</Translate>
                                    </span>
                                </Button>
                                &nbsp;
                                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                    <FontAwesomeIcon icon="save" />
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Save</Translate>
                                </Button>
                            </AvForm>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    companyEmployees: storeState.companyEmployee.entities,
    employeeMonthlyCredits: storeState.employeeMonthlyCredit.entities,
    employeeTimeEntryEntity: storeState.employeeTimeEntry.entity,
    loading: storeState.employeeTimeEntry.loading,
    updating: storeState.employeeTimeEntry.updating,
    updateSuccess: storeState.employeeTimeEntry.updateSuccess,
});

const mapDispatchToProps = {
    getCompanyEmployees,
    getEmployeeMonthlyCredits,
    getEntity,
    updateEntity,
    createEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimeEntryUpdate);
