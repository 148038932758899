import { defaultValue, IEmailAddress } from 'app/shared/model/email-address.model';

const initialState = {
    emailAddresses: [] as ReadonlyArray<IEmailAddress>,
    emailAddress: defaultValue,
};

export type EmailAddressState = Readonly<typeof initialState>;

// Reducer
export default (state: EmailAddressState = initialState, action): EmailAddressState => {
    return state;
};
