import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Button, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import './company-employee-modal-filter.scss';

export interface CompanyEmployeeModalFilterParameters {
    id: string;
    person: string;
    identificationNumber: string;
}

export const defaultCompanyEmployeeModalFilterParameters: Readonly<CompanyEmployeeModalFilterParameters> = {
    id: '',
    person: '',
    identificationNumber: '',
};

interface CompanyEmployeeModalFilterProps {
    showModal: boolean;
    handleClose: () => void;
    filterParameters: CompanyEmployeeModalFilterParameters;
    handleSetFilterParameters: (value: CompanyEmployeeModalFilterParameters) => void;
    getEntities: () => void;
}

export class CompanyEmployeeModalFilter extends React.Component<CompanyEmployeeModalFilterProps> {
    saveEntity = (event, errors, values) => {
        this.props.handleSetFilterParameters(values);
        this.props.handleClose();
        this.props.getEntities();
    };

    render() {
        const { showModal, handleClose, filterParameters } = this.props;
        return (
            <Modal isOpen={showModal} toggle={handleClose} className="modal-filter">
                <ModalHeader toggle={handleClose}>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.filterLabel">Filter Company Employee</Translate>
                </ModalHeader>
                <ModalBody>
                    <AvForm model={filterParameters} onSubmit={this.saveEntity}>
                        <AvGroup>
                            <Label for="company-employee-id">
                                <Translate contentKey="global.field.id">ID</Translate>
                            </Label>
                            <AvInput id="company-employee-id" type="text" className="form-control" name="id" />
                        </AvGroup>
                        <AvGroup>
                            <Label for="company-employee-identificationNumber">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.identificationNumber">ID</Translate>
                            </Label>
                            <AvInput id="company-employee-identificationNumber" type="text" className="form-control" name="identificationNumber" />
                        </AvGroup>
                        <AvGroup>
                            <Label for="company-employee-person">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.person"> Person </Translate>
                            </Label>
                            <AvInput id="company-employee-person" type="text" className="form-control" name="person" />
                        </AvGroup>

                        <div className="modal-filter__button">
                            <Button color="primary" id="search-entity" type="submit">
                                <FontAwesomeIcon icon="search" />
                                &nbsp;
                                <Translate contentKey="entity.action.filter">Filter</Translate>
                            </Button>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
        );
    }
}
