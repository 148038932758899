import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { getEntities } from 'app/entities/employee-anticipation/employee-anticipation.reducer';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import moment from 'moment';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row, Table } from 'reactstrap';

export interface IEmployeeAnticipationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IEmployeeAnticipationState = IPaginationBaseState;

export class EmployeeAnticipation extends React.Component<IEmployeeAnticipationProps, IEmployeeAnticipationState> {
    state: IEmployeeAnticipationState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    render() {
        const { employeeAnticipationList, totalItems, match } = this.props;
        return (
            <div>
                <h2 id="employee-anticipation-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.title">Employee Anticipations</Translate>
                    <Link to={`${match.url}/report`} className="btn btn-primary float-right jh-create-entity" id="anticipation-report">
                        <FontAwesomeIcon icon="book" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.report">Anticipation Report</Translate>
                    </Link>
                </h2>
                <div className="table-responsive">
                    {employeeAnticipationList && employeeAnticipationList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('amount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('requestedDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.requestedDate">Requested Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('acceptedDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.acceptedDate">Accepted Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('paidDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.paidDate">Paid Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('status')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.month">Month</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employeeAnticipationList.map((employeeAnticipation, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>{employeeAnticipation.id}</td>
                                        <td>
                                            {employeeAnticipation.amount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.requestedDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.acceptedDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.paidDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <Translate contentKey={`giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.${employeeAnticipation.status}`} />
                                        </td>
                                        <td>{employeeAnticipation.month ? moment(employeeAnticipation.month.month).format('MM/YYYY') : ''}</td>
                                        <td>{employeeAnticipation.employee ? employeeAnticipation.employee.person?.name ?? employeeAnticipation.employee.identificationNumber : ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.notFound">No Employee Anticipations found</Translate>
                        </div>
                    )}
                </div>
                <div className={employeeAnticipationList && employeeAnticipationList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ employeeAnticipation }: IRootState) => ({
    employeeAnticipationList: employeeAnticipation.entities,
    totalItems: employeeAnticipation.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAnticipation);
