import { ICompanyEmployee } from 'app/shared/model/company-employee.model';
import { IEmployeeAnticipation } from 'app/shared/model/employee-anticipation.model';
import { IEmployeeTimeEntry } from 'app/shared/model/employee-time-entry.model';
import { Moment } from 'moment';

export interface IEmployeeMonthlyCredit {
    id?: number;
    month?: Moment;
    workedAmount?: number;
    availableAmount?: number;
    workedDays?: number;
    blockedAmount?: number;
    anticipatedAmount?: number;
    monthlyDeadlineReached?: boolean;
    employee?: ICompanyEmployee;
    entries?: IEmployeeTimeEntry[];
    anticipations?: IEmployeeAnticipation[];
}

export const defaultValue: Readonly<IEmployeeMonthlyCredit> = {
    monthlyDeadlineReached: false,
};
