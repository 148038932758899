import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './employee-program-financial.reducer';

export interface IEmployeeProgramFinancialDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class EmployeeProgramFinancialDetail extends React.Component<IEmployeeProgramFinancialDetailProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    render() {
        const { employeeProgramFinancialEntity } = this.props;
        return (
            <Row>
                <Col md="8">
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramFinancial.detail.title">EmployeeProgramFinancial</Translate> [<b>{employeeProgramFinancialEntity.id}</b>]
                    </h2>
                    <dl className="jh-entity-details">
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramFinancial.company">Company</Translate>
                        </dt>
                        <dd>{employeeProgramFinancialEntity.company ? employeeProgramFinancialEntity.company.name : ''}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramFinancial.program">Program</Translate>
                        </dt>
                        <dd>{employeeProgramFinancialEntity.program ? employeeProgramFinancialEntity.program.name : ''}</dd>
                    </dl>
                    <Button tag={Link} to="/entity/employee-program-financial" replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.back">Back</Translate>
                        </span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/entity/employee-program-financial/${employeeProgramFinancialEntity.id}/edit`} replace color="primary">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ employeeProgramFinancial }: IRootState) => ({
    employeeProgramFinancialEntity: employeeProgramFinancial.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgramFinancialDetail);
