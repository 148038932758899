import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/util/StringUtils';
import React, { Component } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { AddressCardDetail } from '../address/address-card-detail';
import { BankAccountCardDetail } from '../bankaccount/bankaccount-card-detail';
import { EmailAddressCardDetail } from '../emailaddress/emailaddress-card-detail';
import { PhoneCardDetail } from '../phone/phone-card-detail';
import { getCompany, resetEntity } from './company.reducer';

export interface ICompanyDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CompanyDetail extends Component<ICompanyDetailProps> {
    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getCompany(this.props.match.params.id);
        }
    }

    componentWillUnmount() {
        this.props.resetEntity();
    }

    render() {
        const { company } = this.props;
        const { organizations, accounts, addresses, phones, emailAddresses } = this.props.company;
        return (
            <div>
                <h2>
                    <Translate contentKey="companyDetail.title">Company</Translate>
                </h2>
                <div className="margin-left-15">
                    <Row>
                        <dl className="jh-entity-details">
                            <dt>
                                <Translate contentKey="company.name">Name</Translate>
                            </dt>
                            <dd>{company.name}</dd>
                            <dt>
                                <Translate contentKey="company.type">Type</Translate>
                            </dt>
                            <dd>
                                {company.type
                                    ? {
                                          INDIVIDUAL: translate('companyType.INDIVIDUAL'),
                                          CORPORATION: translate('companyType.CORPORATION'),
                                      }[company.type]
                                    : company.type}
                            </dd>
                            <dt>
                                {company.type ? (
                                    {
                                        INDIVIDUAL: <Translate contentKey="company.identification.cpf">CPF</Translate>,
                                        CORPORATION: <Translate contentKey="company.identification.cnpj">CNPJ</Translate>,
                                    }[company.type]
                                ) : (
                                    <Translate contentKey="company.identification.label">Identification</Translate>
                                )}
                            </dt>
                            <dd>
                                {company.type &&
                                    company.identification &&
                                    {
                                        INDIVIDUAL: StringUtils.addCpfMask(company.identification),
                                        CORPORATION: StringUtils.addCnpjMask(company.identification),
                                    }[company.type]}
                            </dd>
                        </dl>
                    </Row>
                </div>

                <div className="company-organizations">
                    <h3>
                        <Translate contentKey="company.organizations">Organizations</Translate>
                    </h3>
                    {organizations && organizations.length > 0 ? (
                        organizations.map(organization => (
                            <Row key={organization.id}>
                                <Col>
                                    <dl>
                                        <dt>
                                            <Translate contentKey="organization.self">Organization</Translate>
                                        </dt>
                                        <dd>{organization.name}</dd>
                                    </dl>
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <Translate contentKey="companyManager.empty.organizations" />
                    )}
                </div>

                <div className="company-accounts">
                    <h3>
                        <Translate contentKey="company.accounts">Accounts</Translate>
                    </h3>
                    {accounts && accounts.length > 0 ? accounts.map(account => <BankAccountCardDetail key={account.id} account={account} />) : <Translate contentKey="companyManager.empty.accounts" />}
                </div>

                <div className="company-addresses">
                    <h3>
                        <Translate contentKey="company.addresses">Addresses</Translate>
                    </h3>
                    {addresses && addresses.length > 0 ? addresses.map(address => <AddressCardDetail key={address.id} address={address} />) : <Translate contentKey="companyManager.empty.addresses" />}
                </div>

                <div className="company-phones">
                    <h3>
                        <Translate contentKey="company.phones">Phones</Translate>
                    </h3>
                    {phones && phones.length > 0 ? phones.map(phone => <PhoneCardDetail key={phone.id} phone={phone} />) : <Translate contentKey="companyManager.empty.phones" />}
                </div>

                <div className="company-emails">
                    <h3>
                        <Translate contentKey="company.emailAddresses">Emails</Translate>
                    </h3>
                    {emailAddresses && emailAddresses.length > 0 ? emailAddresses.map(email => <EmailAddressCardDetail key={email.id} emailAddress={email} />) : <Translate contentKey="companyManager.empty.emails" />}
                </div>

                <Button tag={Link} to="/entity/company" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" />{' '}
                    <span className="d-none  d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                    </span>
                </Button>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    company: storeState.company.company,
});

const mapDispatchToProps = { getCompany, resetEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail);
