import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './employee-time-entry.reducer';

export interface IEmployeeTimeEntryDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class EmployeeTimeEntryDetail extends React.Component<IEmployeeTimeEntryDetailProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    render() {
        const { employeeTimeEntryEntity } = this.props;
        return (
            <Row>
                <Col md="8">
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.detail.title">EmployeeTimeEntry</Translate> [<b>{employeeTimeEntryEntity.id}</b>]
                    </h2>
                    <dl className="jh-entity-details">
                        <dt>
                            <span id="hourlyRate">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.hourlyRate">Hourly Rate</Translate>
                            </span>
                        </dt>
                        <dd>{employeeTimeEntryEntity.hourlyRate}</dd>
                        <dt>
                            <span id="duration">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.duration">Duration</Translate>
                            </span>
                        </dt>
                        <dd>{employeeTimeEntryEntity.duration}</dd>
                        <dt>
                            <span id="employeeIdentificationNumber">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.employeeIdentificationNumber">Employee Identification Number</Translate>
                            </span>
                        </dt>
                        <dd>{employeeTimeEntryEntity.employeeIdentificationNumber}</dd>
                        <dt>
                            <span id="date">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.date">Date</Translate>
                            </span>
                        </dt>
                        <dd>
                            <TextFormat value={employeeTimeEntryEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} />
                        </dd>
                        <dt>
                            <span id="creditAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.creditAmount">Credit Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeTimeEntryEntity.creditAmount}</dd>
                        <dt>
                            <span id="status">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.status">Status</Translate>
                            </span>
                        </dt>
                        <dd>{employeeTimeEntryEntity.status}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.employee">Employee</Translate>
                        </dt>
                        <dd>{employeeTimeEntryEntity.employee ? employeeTimeEntryEntity.employee.id : ''}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.month">Month</Translate>
                        </dt>
                        <dd>{employeeTimeEntryEntity.month ? employeeTimeEntryEntity.month.id : ''}</dd>
                    </dl>
                    <Button tag={Link} to="/entity/employee-time-entry" replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.back">Back</Translate>
                        </span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/entity/employee-time-entry/${employeeTimeEntryEntity.id}/edit`} replace color="primary">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ employeeTimeEntry }: IRootState) => ({
    employeeTimeEntryEntity: employeeTimeEntry.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimeEntryDetail);
