import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getEntities } from './employee-monthly-credit.reducer';

export interface IEmployeeMonthlyCreditProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IEmployeeMonthlyCreditState = IPaginationBaseState;

export class EmployeeMonthlyCredit extends React.Component<IEmployeeMonthlyCreditProps, IEmployeeMonthlyCreditState> {
    state: IEmployeeMonthlyCreditState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    render() {
        const { employeeMonthlyCreditList, match, totalItems } = this.props;
        return (
            <div>
                <h2 id="employee-monthly-credit-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.home.title">Employee Monthly Credits</Translate>
                    <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.home.createLabel">Create new Employee Monthly Credit</Translate>
                    </Link>
                </h2>
                <div className="table-responsive">
                    {employeeMonthlyCreditList && employeeMonthlyCreditList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('month')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.month">Month</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('workedAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.workedAmount">Worked Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('availableAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.availableAmount">Available Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('workedDays')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.workedDays">Worked Days</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('blockedAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.blockedAmount">Blocked Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('anticipatedAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.anticipatedAmount">Anticipated Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('employee.person.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {employeeMonthlyCreditList.map((employeeMonthlyCredit, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>
                                            <Button tag={Link} to={`${match.url}/${employeeMonthlyCredit.id}`} color="link" size="sm">
                                                {employeeMonthlyCredit.id}
                                            </Button>
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeMonthlyCredit.month} format={APP_LOCAL_DATE_FORMAT} />
                                        </td>
                                        <td>{employeeMonthlyCredit.workedAmount}</td>
                                        <td>
                                            {employeeMonthlyCredit.availableAmount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>{employeeMonthlyCredit.workedDays}</td>
                                        <td>
                                            {employeeMonthlyCredit.blockedAmount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>
                                            {employeeMonthlyCredit.anticipatedAmount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>{employeeMonthlyCredit.employee ? <Link to={`company-employee/${employeeMonthlyCredit.employee.id}`}>{employeeMonthlyCredit.employee.person.name}</Link> : ''}</td>
                                        <td className="text-right">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button tag={Link} to={`${match.url}/${employeeMonthlyCredit.id}`} color="info" size="sm">
                                                    <FontAwesomeIcon icon="eye" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.view">View</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employeeMonthlyCredit.id}/edit`} color="primary" size="sm">
                                                    <FontAwesomeIcon icon="pencil-alt" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                                    </span>
                                                </Button>
                                                {/* <Button tag={Link} to={`${match.url}/${employeeMonthlyCredit.id}/delete`} color="danger" size="sm">
                                                    <FontAwesomeIcon icon="trash" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.delete">Delete</Translate>
                                                    </span>
                                                </Button> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.home.notFound">No Employee Monthly Credits found</Translate>
                        </div>
                    )}
                </div>
                <div className={employeeMonthlyCreditList && employeeMonthlyCreditList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ employeeMonthlyCredit }: IRootState) => ({
    employeeMonthlyCreditList: employeeMonthlyCredit.entities,
    totalItems: employeeMonthlyCredit.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMonthlyCredit);
