import { IState } from 'app/shared/model/state.model';

export interface ICity {
    id?: string;
    name?: string;
    state?: IState;
    stateAcronym?: string;
}

export const defaultValue: Readonly<ICity> = {};
