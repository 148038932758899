import Button from 'app/components/button/Button';
import { IRootState } from 'app/shared/reducers';
import * as React from 'react';
import { translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'reactstrap';
import './warning-modal.css';

export interface IWarningModalProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
    config: {
        stateAction?: string;
        title: string;
        confirmButtonText: string;
        cancelCallback: () => void;
        confirmCallback: () => void;
    };
}

class WarningModal extends React.Component<IWarningModalProps, {}> {
    constructor(props) {
        super(props);
    }

    handleOnBackPressed = () => {
        this.props.config.cancelCallback();
    };

    handleOnConfirmPressed = () => {
        this.props.config.confirmCallback();
    };

    render() {
        return (
            <Modal isOpen className={'warning-modal-card-style'}>
                <div className={'warning-modal-title-container'}>
                    <label className={'warning-modal-title'}>{this.props.config.title}</label>
                </div>

                <div className={'warning-modal-buttons-container'}>
                    <label onClick={this.handleOnBackPressed} className={'warning-modal-back-button'}>
                        {translate('calendar.buttons.back')}
                    </label>

                    <Button onClick={this.handleOnConfirmPressed} title={translate(this.props.config.confirmButtonText)} />
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: IWarningModalProps) => ({});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);
