import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityCard } from 'app/components/card/entityCard/entity-card';
import { defaultValue, IPhone } from 'app/shared/model/phone.model';
import { IRootState } from 'app/shared/reducers';
import React, { Component } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { PhoneCardDetail } from '../phone/phone-card-detail';
import { PhoneCardManager } from '../phone/phone-card-manager';
import { EditActionType } from './company-manager';

export interface ICompanyPhonesManagerProps extends StateProps, DispatchProps {
    updateCallback?: (phones: IPhone[]) => void;
    editCallback?: (action: EditActionType) => void;
    isSingle?: boolean;
}

export interface ICompanyPhonesManagerState {
    phones: IPhone[];
    editing: boolean;
}

export class CompanyPhonesManager extends Component<ICompanyPhonesManagerProps, ICompanyPhonesManagerState> {
    constructor(props: ICompanyPhonesManagerProps) {
        super(props);
        this.state = {
            phones: props.phones ? props.phones : [],
            editing: false,
        };
    }

    handleAdd = (_event: any) => {
        const { phones } = this.state;
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
                phones: [...phones, defaultValue],
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleRemove = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                phones: this.state.phones.filter((_phone, i) => {
                    return i !== index;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.phones);
                }
            }
        );
    };

    handleCancel = (index: number) => {
        if (this.state.phones[index].isNew) {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                    phones: this.state.phones.filter((_phone, i) => {
                        return i !== index;
                    }),
                }),
                () => {
                    if (this.props.updateCallback) {
                        this.props.updateCallback(this.state.phones);
                    }
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        } else {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                }),
                () => {
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        }
    };

    handleEdit = () => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleUpdate = (index: number, update: IPhone) => {
        this.setState(prevState => ({
            ...prevState,
            phones: this.state.phones.map((value: IPhone, i: number) => {
                return i === index ? update : value;
            }),
        }));
    };

    handleSave = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
                phones: prevState.phones.map((phone: IPhone, i: number) => {
                    return i === index ? { ...phone, isNew: false } : phone;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.phones);
                }
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.FINISH);
                }
            }
        );
    };

    render() {
        const { phones, editing } = this.state;
        return (
            <>
                <h3 id="company-phones-heading">
                    <Translate contentKey="company.phones">Phones</Translate>
                    {!this.props.isSingle ||
                        (this.props.isSingle && phones.length === 0 && (
                            <Button color="primary" className="float-right" type="button" disabled={editing} onClick={this.handleAdd}>
                                <FontAwesomeIcon icon="plus" />
                                <Translate contentKey="phones.createLabel">Create a new email address</Translate>
                            </Button>
                        ))}
                </h3>
                <div className="phones-manage">
                    {phones && phones.length > 0 ? (
                        phones.map((phone, i) => (
                            <EntityCard key={i} entity={phone} editable={!editing} editCallback={this.handleEdit} removeCallback={() => this.handleRemove(i)} saveCallback={() => this.handleSave(i)} cancelCallback={() => this.handleCancel(i)}>
                                {{
                                    manager: <PhoneCardManager phone={phone} updateCallback={it => this.handleUpdate(i, it)} />,
                                    detail: <PhoneCardDetail phone={phone} />,
                                }}
                            </EntityCard>
                        ))
                    ) : (
                        <p>
                            <Translate contentKey="companyManager.empty.phones">No registered phone</Translate>
                        </p>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    phones: storeState.phone.phones,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPhonesManager);
