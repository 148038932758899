import { defaultValue, ICompany } from 'app/shared/model/company.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster';

export const ACTION_TYPES = {
    FETCH_COMPANIES: 'company/FETCH_COMPANIES',
    FETCH_COMPANY: 'company/FETCH_COMPANY',
    CREATE_COMPANY: 'company/CREATE_COMPANY',
    UPDATE_COMPANY: 'company/UPDATE_COMPANY',
    DELETE_COMPANY: 'company/DELETE_COMPANY',
    RESET_ENTITY: 'company/RESET_ENTITY',
    RESET: 'company/RESET',
};

const initialState = {
    loading: false,
    findItem: false,
    errorMessage: null,
    data: [] as ReadonlyArray<ICompany>,
    company: defaultValue,
    updating: false,
    updateSuccess: false,
    totalItems: 0,
    page: null,
};

export type CompanyState = Readonly<typeof initialState>;

// Reducer
export default (state: CompanyState = initialState, action): CompanyState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_COMPANIES):
        case REQUEST(ACTION_TYPES.FETCH_COMPANY):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_COMPANY):
        case REQUEST(ACTION_TYPES.UPDATE_COMPANY):
        case REQUEST(ACTION_TYPES.DELETE_COMPANY):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_COMPANIES):
        case FAILURE(ACTION_TYPES.FETCH_COMPANY):
        case FAILURE(ACTION_TYPES.CREATE_COMPANY):
        case FAILURE(ACTION_TYPES.UPDATE_COMPANY):
        case FAILURE(ACTION_TYPES.DELETE_COMPANY):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_COMPANIES):
            return {
                ...state,
                loading: false,
                data: action.payload.data.content,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_COMPANY):
            return {
                ...state,
                findItem: true,
                loading: false,
                company: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_COMPANY):
        case SUCCESS(ACTION_TYPES.UPDATE_COMPANY):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                company: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_COMPANY):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                company: defaultValue,
            };
        case ACTION_TYPES.RESET_ENTITY:
            return { ...state, company: defaultValue };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/companies';
export const getCompanies: ICrudSearchAction<ICompany> = (search, page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${search ? `&search=${search}` : ''}`;
    return {
        type: ACTION_TYPES.FETCH_COMPANIES,
        payload: axios.get<ICompany>(requestUrl),
    };
};

export const getCompany: ICrudGetAction<ICompany> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_COMPANY,
        payload: axios.get<ICompany>(requestUrl),
    };
};

export const createCompany: ICrudPutAction<ICompany> = company => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_COMPANY,
        payload: axios.post(apiUrl, company),
    });
    dispatch(getCompanies());
    return result;
};

export const updateCompany: ICrudPutAction<ICompany> = company => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_COMPANY,
        payload: axios.put(apiUrl, company),
    });
    dispatch(getCompanies());
    return result;
};

export const deleteCompany: ICrudDeleteAction<ICompany> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_COMPANY,
        payload: axios.delete(requestUrl),
    });
    dispatch(getCompanies());
    return result;
};

export const resetEntity = () => ({
    type: ACTION_TYPES.RESET_ENTITY,
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
