import { languages, locales } from 'app/config/translation';
import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavDropdown } from './menu-components';

export const LocaleMenu = ({ currentLocale, onClick }: { currentLocale: string; onClick: Function }) =>
    Object.keys(languages).length > 1 ? (
        <NavDropdown icon="flag" name={currentLocale ? languages[currentLocale].name : undefined}>
            {locales.map(locale => (
                <DropdownItem key={locale} value={locale} onClick={onClick}>
                    {languages[locale].name}
                </DropdownItem>
            ))}
        </NavDropdown>
    ) : null;
