import { hideModal, showModal } from 'app/components/modal/modal-action';
import { PixKeyType } from 'app/shared/model/enumerations/pix-key-type';
import { IPix } from 'app/shared/model/pix.model';
import { IRootState } from 'app/shared/reducers';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Col, Label, Row } from 'reactstrap';

export interface IPixCardManagerProps extends StateProps, DispatchProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    pix: IPix;
    updateCallback?: (pix: IPix) => void;
}

export interface IPixCardManagerState {
    pix: IPix;
}

export class PixCardManager extends React.Component<IPixCardManagerProps, IPixCardManagerState> {
    constructor(props: Readonly<IPixCardManagerProps>) {
        super(props);
        this.state = {
            pix: props.pix,
        };
    }

    handlePixKeyChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                pix: { ...ps.pix, key: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handlePixKeyTypeChange = (event: OnChangeEvent, value: PixKeyType): void => {
        this.setState(
            ps => ({
                ...ps,
                pix: { ...ps.pix, keyType: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    updateCallback(): void {
        if (this.props.updateCallback) {
            this.props.updateCallback(this.state.pix);
        }
    }

    render() {
        const { pix } = this.state;
        return (
            <>
                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="pix.key"> PIX Key </Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="key"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('pixManager.validate.key.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('pixManager.validate.key.minlength'),
                                    },
                                    maxLength: {
                                        value: 150,
                                        errorMessage: translate('pixManager.validate.key.maxlength'),
                                    },
                                }}
                                onChange={this.handlePixKeyChange}
                                value={pix.key}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="12">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="pix.keyType">Key Type</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="type"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('pixManager.validate.keyType.required'),
                                    },
                                }}
                                onChange={this.handlePixKeyTypeChange}
                                value={pix.keyType}>
                                <option value={undefined}></option>
                                <option value={PixKeyType.CPF}>{translate('pixKeyType.CPF')}</option>
                                <option value={PixKeyType.EMAIL}>{translate('pixKeyType.EMAIL')}</option>
                                <option value={PixKeyType.PHONE_NUMBER}>{translate('pixKeyType.PHONE_NUMBER')}</option>
                                <option value={PixKeyType.RANDOM}>{translate('pixKeyType.RANDOM')}</option>
                            </AvField>
                        </AvGroup>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = dispatch => {
    // return {
    //   hideModal: () => dispatch(hideModal()),
    //   dispatch
    // };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PixCardManager);
