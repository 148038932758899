import { IPhone, PhoneType } from 'app/shared/model/phone.model';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { Col, Label, Row } from 'reactstrap';

export interface PhoneCardManagerProps {
    phone: IPhone;
    updateCallback?: (phone: IPhone) => void;
}

export interface PhoneCardManagerState {
    phone: IPhone;
}

export class PhoneCardManager extends React.Component<PhoneCardManagerProps, PhoneCardManagerState> {
    constructor(props: Readonly<PhoneCardManagerProps>) {
        super(props);
        this.state = {
            phone: props.phone,
        };
    }

    nameChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            prevState => ({
                phone: { ...prevState.phone, number: value },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.phone);
                }
            }
        );
    };

    observationChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            prevState => ({
                phone: { ...prevState.phone, observation: value },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.phone);
                }
            }
        );
    };

    typeChange = (event: OnChangeEvent, value: PhoneType) => {
        this.setState(
            prevState => ({
                phone: { ...prevState.phone, type: value },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.phone);
                }
            }
        );
    };

    render() {
        const { phone } = this.state;
        return (
            <>
                <Row>
                    <Col md="4">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="phone.type">Type</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="type"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'required',
                                    },
                                }}
                                onChange={this.typeChange}
                                value={phone.type}>
                                <option value={PhoneType.HOME}>{translate('phoneType.HOME')}</option>
                                <option value={PhoneType.CELLULAR}>{translate('phoneType.CELLULAR')}</option>
                                <option value={PhoneType.PROFESSIONAL}>{translate('phoneType.PROFESSIONAL')}</option>
                                <option value={PhoneType.OTHER}>{translate('phoneType.OTHER')}</option>
                            </AvField>
                        </AvGroup>
                    </Col>
                    <Col md="8">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="phone.number">Number</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="number"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'required',
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: 'max length',
                                    },
                                }}
                                onChange={this.nameChange}
                                value={phone.number}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="phone.observation">Observation</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="observation"
                                validate={{
                                    maxLength: {
                                        value: 255,
                                        errorMessage: 'max length',
                                    },
                                }}
                                onChange={this.observationChange}
                                value={phone.observation}
                            />
                        </AvGroup>
                    </Col>
                </Row>
            </>
        );
    }
}
