import { Card } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import './home.scss';

export type ICompanyDashboardProps = StateProps;

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export const CompanyDashboard = (props: ICompanyDashboardProps) => {
    const { account } = props;

    return (
        <Container>
            <Alert color="success">
                <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                    You are logged in as user {account.login}.
                </Translate>
            </Alert>

            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Card>
                        <List>
                            <ListItemLink href="/company/company-employee">
                                <ListItemText primary={translate(`global.menu.company.companyEmployee`)} />
                            </ListItemLink>
                            <ListItemLink href="/company/employee-anticipation">
                                <ListItemText primary={translate(`global.menu.company.employeeAnticipation`)} />
                            </ListItemLink>
                        </List>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = storeState => ({
    account: storeState.authentication.account,
    isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(CompanyDashboard);
