import { IAddress } from 'app/shared/model/address.model';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

export interface IAddressCardDetailProps {
    address: IAddress;
}

export const AddressCardDetail = (props: IAddressCardDetailProps): JSX.Element => {
    const { address } = props;
    return (
        <>
            <Row>
                <Col md="8">
                    <dl>
                        <dt>
                            <Translate contentKey="address.name">Name</Translate>
                        </dt>
                        <dd>{address.name}</dd>
                    </dl>
                </Col>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="address.type">Type</Translate>
                        </dt>
                        <dd>
                            {address.type &&
                                {
                                    HOME: translate('addressType.HOME'),
                                    PROFESSIONAL: translate('addressType.PROFESSIONAL'),
                                    OTHER: translate('addressType.OTHER'),
                                }[address.type]}
                        </dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col md="9">
                    <dl>
                        <dt>
                            <Translate contentKey="address.street">Street</Translate>
                        </dt>
                        <dd>{address.street}</dd>
                    </dl>
                </Col>
                <Col md="3">
                    <dl>
                        <dt>
                            <Translate contentKey="address.number">Number</Translate>
                        </dt>
                        <dd>{address.number}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <dl>
                        <dt>
                            <Translate contentKey="address.complement">Complement</Translate>
                        </dt>
                        <dd>{address.complement}</dd>
                    </dl>
                </Col>
                <Col md="6">
                    <dl>
                        <dt>
                            <Translate contentKey="address.neighborhood">Neighborhood</Translate>
                        </dt>
                        <dd>{address.neighborhood}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col md="8">
                    <dl>
                        <dt>
                            <Translate contentKey="address.city">City</Translate>
                        </dt>
                        <dd>{address.city ? address.city.name + '-' + address.city.stateAcronym : ''}</dd>
                    </dl>
                </Col>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="address.zipCode">Zip Code</Translate>
                        </dt>
                        <dd>{address.zipCode}</dd>
                    </dl>
                </Col>
            </Row>
        </>
    );
};
