import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getOrganizations } from './organization.reducer';

export interface IOrganizationProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export const Organization = (props: IOrganizationProps) => {
    const [pagination, setPagination] = useState(getSortState(props.location, ITEMS_PER_PAGE));

    const getAllOrganizations = () => props.getOrganizations('', pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`);

    useEffect(() => {
        getAllOrganizations();
    }, []);

    const sortOrganizations = () => getAllOrganizations();

    useEffect(() => {
        sortOrganizations();
    }, [pagination.activePage, pagination.order, pagination.sort]);

    const sort = p => () => {
        setPagination({
            ...pagination,
            order: pagination.order === 'asc' ? 'desc' : 'asc',
            sort: p,
        });
        props.history.push(`${props.location.pathname}?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`);
    };

    const handlePagination = currentPage =>
        setPagination({
            ...pagination,
            activePage: currentPage,
        });

    const { organizations, match, totalItems } = props;
    return (
        <div>
            <h2 id="organization-page-heading">
                <Translate contentKey="organizations.title">Organization</Translate>
                <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity">
                    <FontAwesomeIcon icon="plus" /> <Translate contentKey="organizations.createLabel">Create a new organization</Translate>
                </Link>
            </h2>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th className="hand" onClick={sort('name')}>
                            <Translate contentKey="organization.name">name</Translate>
                            <FontAwesomeIcon icon="sort" />
                        </th>
                        <th className="hand" onClick={sort('organizationKey')}>
                            <Translate contentKey="organization.organizationKey">key</Translate>
                            <FontAwesomeIcon icon="sort" />
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {organizations.map((organization, i) => (
                        <tr id={organization.organizationKey} key={`user-${i}`}>
                            <td>{organization.name}</td>
                            <td>{organization.organizationKey}</td>
                            <td className="text-right">
                                <div className="btn-group flex-btn-group-container">
                                    <Button tag={Link} to={`${match.url}/${organization.id}`} color="info" size="sm">
                                        <FontAwesomeIcon icon="eye" />{' '}
                                        <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.view">View</Translate>
                                        </span>
                                    </Button>
                                    <Button tag={Link} to={`${match.url}/${organization.id}/edit`} color="primary" size="sm">
                                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                                        <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.edit">Edit</Translate>
                                        </span>
                                    </Button>
                                    <Button tag={Link} to={`${match.url}/${organization.id}/delete`} color="danger" size="sm">
                                        <FontAwesomeIcon icon="trash" />{' '}
                                        <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.delete">Delete</Translate>
                                        </span>
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className={organizations && organizations.length > 0 ? '' : 'd-none'}>
                <Row className="justify-content-center">
                    <JhiItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} i18nEnabled />
                </Row>
                <Row className="justify-content-center">
                    <JhiPagination activePage={pagination.activePage} onSelect={handlePagination} maxButtons={5} itemsPerPage={pagination.itemsPerPage} totalItems={props.totalItems} />
                </Row>
            </div>
        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    organizations: storeState.organization.data,
    totalItems: storeState.organization.totalItems,
});

const mapDispatchToProps = { getOrganizations };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
