import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialButton from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from 'app/components/loading/loading';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { EmployeeAnticipationStatus } from 'app/shared/model/enumerations/employee-anticipation-status.model';
import { HttpRequestStatus } from 'app/shared/model/enumerations/http-request-status';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import Axios from 'axios';
import moment from 'moment';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, TextFormat, Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { getPending } from './employee-anticipation.reducer';

export interface IEmployeeAnticipationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IEmployeeAnticipationBatchState extends IPaginationBaseState {
    selected: number[];
    openAccept: boolean;
    openDecline: boolean;
    status: HttpRequestStatus;
}

export class EmployeeAnticipationBatch extends React.Component<IEmployeeAnticipationProps, IEmployeeAnticipationBatchState> {
    state: IEmployeeAnticipationBatchState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        selected: [],
        openAccept: false,
        openDecline: false,
        status: HttpRequestStatus.NOOP,
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        this.setState(
            ps => ({ ...ps, selected: [] }),
            () => {
                const { activePage, itemsPerPage, sort, order } = this.state;
                this.props.getPending(activePage - 1, itemsPerPage, `${sort},${order}`);
            }
        );
    };

    handleSelection = (item?: number) => {
        if (!item) return;
        this.setState(ps => {
            if (ps.selected?.some(it => item === it)) {
                return { ...ps, selected: ps.selected?.filter(it => it !== item) };
            }
            return { ...ps, selected: [...ps.selected, item] };
        });
    };

    acceptAnticipation = () => this.setState({ openAccept: true });

    rejectAnticipation = () => this.setState({ openDecline: true });

    handleAcceptCancel = () => this.setState({ openAccept: false });

    handleAcceptConfirm = () => {
        if (!this.state.selected?.length) return;
        const request = { ids: this.state.selected };
        this.setState({ status: HttpRequestStatus.ONGOING, openAccept: false });
        Axios.patch('/api/employee-anticipations/accept', request)
            .then(result => {
                this.setState({ status: HttpRequestStatus.SUCCESS });
                this.getEntities();
            })
            .catch(error => {
                this.setState({ status: HttpRequestStatus.ERROR });
                alert(translate('giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.accept.error'));
            });
    };

    handleRejectCancel = () => this.setState({ openDecline: false });

    handleRejectConfirm = () => {
        if (!this.state.selected?.length) return;
        const request = { ids: this.state.selected };
        this.setState({ status: HttpRequestStatus.ONGOING, openDecline: false });
        Axios.patch('/api/employee-anticipations/decline', request)
            .then(result => {
                this.setState({ status: HttpRequestStatus.SUCCESS });
                this.getEntities();
            })
            .catch(error => {
                this.setState({ status: HttpRequestStatus.ERROR });
                alert(translate('giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.reject.error'));
            });
    };

    render() {
        const { employeeAnticipationList, match, totalItems } = this.props;

        const { status } = this.state;

        if (status === HttpRequestStatus.ONGOING) {
            return (
                <Row style={{ marginTop: '100px' }}>
                    <Col>
                        <Loading />
                    </Col>
                </Row>
            );
        }
        return (
            <div>
                <Dialog open={this.state.openAccept} onClose={this.handleAcceptCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.accept.title">Accept Confirmation</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.accept.question">Do you confirm the anticipation?</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleAcceptCancel} color="secondary">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.accept.cancel">Cancel</Translate>
                        </MaterialButton>
                        <MaterialButton onClick={this.handleAcceptConfirm} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.accept.confirm">Confirm</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.openDecline} onClose={this.handleRejectCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.reject.title">Accept Confirmation</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.reject.question">Do you confirm the anticipation?</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleRejectCancel} color="secondary">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.reject.cancel">Cancel</Translate>
                        </MaterialButton>
                        <MaterialButton onClick={this.handleRejectConfirm} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.reject.confirm">Confirm</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>
                <h2 id="employee-anticipation-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.title">Employee Anticipations</Translate>
                    <div className="float-right ">
                        <MaterialButton variant="contained" color="primary" disabled={!this.state.selected?.length} onClick={this.acceptAnticipation}>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.accept.label">Accept</Translate>
                        </MaterialButton>
                        <MaterialButton variant="contained" color="secondary" disabled={!this.state.selected?.length} onClick={this.rejectAnticipation}>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.batch.reject.label">Reject</Translate>
                        </MaterialButton>
                    </div>
                </h2>
                <div className="table-responsive">
                    {employeeAnticipationList && employeeAnticipationList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('amount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('requestedDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.requestedDate">Requested Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('acceptedDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.acceptedDate">Accepted Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('paidDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.paidDate">Paid Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('status')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('month.month')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.month">Month</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('employee.person.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('programFinancial.company.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.programFinancial">Program Financial</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {employeeAnticipationList.map((employeeAnticipation, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>
                                            <Button tag={Link} to={`/entity/employee-anticipation/${employeeAnticipation.id}`} color="link" size="sm">
                                                {employeeAnticipation.id}
                                            </Button>
                                        </td>
                                        <td>
                                            {employeeAnticipation.amount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.requestedDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.acceptedDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.paidDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <Translate contentKey={`giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.${employeeAnticipation.status}`} />
                                        </td>
                                        <td>{employeeAnticipation.month ? <Link to={`employee-monthly-credit/${employeeAnticipation.month.id}`}>{moment(employeeAnticipation.month.month).format('MM/YYYY')}</Link> : ''}</td>
                                        <td>
                                            {employeeAnticipation.employee ? (
                                                <Link to={`company-employee/${employeeAnticipation.employee.id}`}>{employeeAnticipation.employee.person?.name ?? employeeAnticipation.employee.identificationNumber}</Link>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {employeeAnticipation.programFinancial ? (
                                                <Link to={`employee-program-financial/${employeeAnticipation.programFinancial.id}`}>{employeeAnticipation.programFinancial.company?.name ?? employeeAnticipation.programFinancial.id}</Link>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            <Checkbox
                                                color="primary"
                                                size="small"
                                                disabled={!employeeAnticipation.id || EmployeeAnticipationStatus.PENDING !== employeeAnticipation.status}
                                                checked={this.state.selected?.some(it => employeeAnticipation.id === it)}
                                                onChange={() => this.handleSelection(employeeAnticipation.id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.notFound">No Employee Anticipations found</Translate>
                        </div>
                    )}
                </div>
                <div className={employeeAnticipationList && employeeAnticipationList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ employeeAnticipation }: IRootState) => ({
    employeeAnticipationList: employeeAnticipation.entities,
    totalItems: employeeAnticipation.totalItems,
});

const mapDispatchToProps = {
    getPending,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAnticipationBatch);
