import modal, { ModalState } from 'app/components/modal/modal-reducer';
import bankAccount, { BankAccountState } from 'app/entities/bank-account/bank-account.reducer';
import address, { AddressState } from 'app/entities/code-base/address/address.reducer';
import bank, { BankState } from 'app/entities/code-base/bank/bank.reducer';
import city, { CityState } from 'app/entities/code-base/city/city.reducer';
import company, { CompanyState } from 'app/entities/code-base/company/company.reducer';
import emailAddress, { EmailAddressState } from 'app/entities/code-base/emailaddress/emailaddress.reducer';
import organization, { OrganizationState } from 'app/entities/code-base/organization/organization.reducer';
import person, { PersonState } from 'app/entities/code-base/person/person.reducer';
import phone, { PhoneState } from 'app/entities/code-base/phone/phone.reducer';
import companyEmployee, { CompanyEmployeeState } from 'app/entities/company-employee/company-employee.reducer';
import employeeAnticipation, { EmployeeAnticipationState } from 'app/entities/employee-anticipation/employee-anticipation.reducer';
import employeeMonthlyCredit, { EmployeeMonthlyCreditState } from 'app/entities/employee-monthly-credit/employee-monthly-credit.reducer';
import employeeProgramFinancial, { EmployeeProgramFinancialState } from 'app/entities/employee-program-financial/employee-program-financial.reducer';
// prettier-ignore
import employeeProgramTaxRange,{ EmployeeProgramTaxRangeState } from 'app/entities/employee-program-tax-range/employee-program-tax-range.reducer';
import employeeProgram, { EmployeeProgramState } from 'app/entities/employee-program/employee-program.reducer';
import employeeTimeEntry, { EmployeeTimeEntryState } from 'app/entities/employee-time-entry/employee-time-entry.reducer';
import state, { StateState } from 'app/entities/state/state.reducer';
import upload, { UploadState } from 'app/entities/upload/upload.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import invite,{ InviteState } from 'app/modules/account/invite/invite.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import applicationProfile, { ApplicationProfileState } from './application-profile';
import authentication, { AuthenticationState } from './authentication';
import locale, { LocaleState } from './locale';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
    readonly authentication: AuthenticationState;
    readonly locale: LocaleState;
    readonly applicationProfile: ApplicationProfileState;
    readonly administration: AdministrationState;
    readonly userManagement: UserManagementState;
    readonly register: RegisterState;
    readonly activate: ActivateState;
    readonly passwordReset: PasswordResetState;
    readonly password: PasswordState;
    readonly settings: SettingsState;
    readonly employeeMonthlyCredit: EmployeeMonthlyCreditState;
    readonly employeeAnticipation: EmployeeAnticipationState;
    readonly employeeProgram: EmployeeProgramState;
    readonly employeeTimeEntry: EmployeeTimeEntryState;
    readonly companyEmployee: CompanyEmployeeState;
    readonly bank: BankState;
    readonly person: PersonState;
    readonly employeeProgramFinancial: EmployeeProgramFinancialState;
    readonly bankAccount: BankAccountState;
    readonly company: CompanyState;
    readonly organization: OrganizationState;
    readonly state: StateState;
    readonly city: CityState;
    readonly address: AddressState;
    readonly phone: PhoneState;
    readonly modal: ModalState;
    readonly emailAddress: EmailAddressState;
    readonly upload: UploadState;
    readonly employeeProgramTaxRange: EmployeeProgramTaxRangeState;
    readonly invite: InviteState;
    /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
    readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
    authentication,
    locale,
    applicationProfile,
    administration,
    userManagement,
    register,
    activate,
    passwordReset,
    password,
    settings,
    employeeMonthlyCredit,
    employeeAnticipation,
    employeeProgram,
    employeeTimeEntry,
    companyEmployee,
    bank,
    person,
    employeeProgramFinancial,
    bankAccount,
    company,
    organization,
    state,
    city,
    address,
    phone,
    modal,
    emailAddress,
    upload,
    employeeProgramTaxRange,
    invite,
    /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
    loadingBar,
});

export default rootReducer;
