import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { deleteOrganization, getOrganization, resetEntity } from './organization.reducer';

export interface IOrganizationDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class OrganizationDeleteDialog extends React.Component<IOrganizationDeleteDialogProps> {
    componentDidMount() {
        this.props.getOrganization(this.props.match.params.id);
    }

    confirmDelete = event => {
        this.props.deleteOrganization(this.props.organization.id);
        this.handleClose(event);
    };

    handleClose = event => {
        event.stopPropagation();
        this.props.history.goBack();
    };

    componentWillUnmount() {
        this.props.resetEntity();
    }

    render() {
        const { organization } = this.props;
        return (
            <Modal isOpen toggle={this.handleClose}>
                <ModalHeader toggle={this.handleClose}>
                    <Translate contentKey="organizationDelete.title">Confirm delete operation</Translate>
                </ModalHeader>
                <ModalBody id="organizationDelete.question">
                    <Translate contentKey="organizationDelete.question" interpolate={{ param: organization.name }}>
                        Are you sure you want to delete this State?
                    </Translate>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>
                        <FontAwesomeIcon icon="ban" />
                        &nbsp;
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                    <Button id="novu-confirm-delete-state" color="danger" onClick={this.confirmDelete}>
                        <FontAwesomeIcon icon="trash" />
                        &nbsp;
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = ({ organization }: IRootState) => ({
    organization: organization.organization,
});

const mapDispatchToProps = { getOrganization, deleteOrganization, resetEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDeleteDialog);
