import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityCard } from 'app/components/card/entityCard/entity-card';
import { EditActionType } from 'app/entities/code-base/company/company-manager';
import { defaultValue, IEmployeeProgramTaxRange } from 'app/shared/model/employee-program-tax-range.model';
import { IRootState } from 'app/shared/reducers';
import React, { Component } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { TaxRangeCardDetail } from './tax-range-card-detail';
import { TaxRangeCardManager } from './tax-range-card-manager';

export interface IProgramTaxRangeManagerProps extends StateProps, DispatchProps {
    updateCallback?: (taxes: IEmployeeProgramTaxRange[]) => void;
    editCallback?: (action: EditActionType) => void;
}

export interface IProgramTaxRangeManagerState {
    taxes: IEmployeeProgramTaxRange[];
    editing: boolean;
}

export class ProgramTaxRangeManager extends Component<IProgramTaxRangeManagerProps, IProgramTaxRangeManagerState> {
    constructor(props: IProgramTaxRangeManagerProps) {
        super(props);
        this.state = {
            taxes: props.taxes ? props.taxes : [],
            editing: false,
        };
    }

    handleAdd = (_event: any) => {
        const { taxes } = this.state;
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
                taxes: [...taxes, defaultValue],
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleRemove = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                taxes: this.state.taxes.filter((_tax, i) => {
                    return i !== index;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.taxes);
                }
            }
        );
    };

    handleCancel = (index: number) => {
        if (this.state.taxes[index].isNew) {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                    taxes: this.state.taxes.filter((_tax, i) => {
                        return i !== index;
                    }),
                }),
                () => {
                    if (this.props.updateCallback) {
                        this.props.updateCallback(this.state.taxes);
                    }
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        } else {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                }),
                () => {
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        }
    };

    handleEdit = () => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleUpdate = (index: number, update: IEmployeeProgramTaxRange) => {
        this.setState(prevState => ({
            ...prevState,
            taxes: this.state.taxes.map((value: IEmployeeProgramTaxRange, i: number) => {
                return i === index ? update : value;
            }),
        }));
    };

    handleSave = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
                taxes: prevState.taxes.map((tax: IEmployeeProgramTaxRange, i: number) => {
                    return i === index ? { ...tax, isNew: false } : tax;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.taxes);
                }
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.FINISH);
                }
            }
        );
    };

    render() {
        const { taxes, editing } = this.state;
        return (
            <>
                <h3 id="company-taxes-heading">
                    <Translate contentKey="taxes.title">Phones</Translate>
                    <Button color="primary" className="float-right" type="button" disabled={editing} onClick={this.handleAdd}>
                        <FontAwesomeIcon icon="plus" />
                        <Translate contentKey="taxes.createLabel">Create a new tax</Translate>
                    </Button>
                </h3>
                <div className="taxes-manage">
                    {taxes && taxes.length > 0 ? (
                        taxes.map((tax, i) => (
                            <EntityCard key={i} entity={tax} editable={!editing} editCallback={this.handleEdit} removeCallback={() => this.handleRemove(i)} saveCallback={() => this.handleSave(i)} cancelCallback={() => this.handleCancel(i)}>
                                {{
                                    manager: <TaxRangeCardManager tax={tax} updateCallback={it => this.handleUpdate(i, it)} />,
                                    detail: <TaxRangeCardDetail tax={tax} />,
                                }}
                            </EntityCard>
                        ))
                    ) : (
                        <p>
                            <Translate contentKey="taxes.emptyList">No registered tax</Translate>
                        </p>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    taxes: storeState.employeeProgramTaxRange.entities,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProgramTaxRangeManager);
