import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { getCompanies } from 'app/entities/code-base/company/company.reducer';
import { getEntities as getEmployeePrograms } from 'app/entities/employee-program/employee-program.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './employee-program-financial.reducer';

export interface IEmployeeProgramFinancialUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEmployeeProgramFinancialUpdateState {
    isNew: boolean;
    companyId: string;
    programId: string;
}

export class EmployeeProgramFinancialUpdate extends React.Component<IEmployeeProgramFinancialUpdateProps, IEmployeeProgramFinancialUpdateState> {
    constructor(props) {
        super(props);
        this.state = {
            companyId: '0',
            programId: '0',
            isNew: !this.props.match.params || !this.props.match.params.id,
        };
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }

        this.props.getCompanies();
        this.props.getEmployeePrograms();
    }

    saveEntity = (event, errors, values) => {
        if (errors.length === 0) {
            const { employeeProgramFinancialEntity } = this.props;
            const entity = {
                ...employeeProgramFinancialEntity,
                ...values,
            };

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };

    handleClose = () => {
        this.props.history.push('/entity/employee-program-financial');
    };

    render() {
        const { employeeProgramFinancialEntity, companies, employeePrograms, loading, updating } = this.props;
        const { isNew } = this.state;

        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="giroEmployeeSalaryAdvanceApp.employeeProgramFinancial.home.createOrEditLabel">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramFinancial.home.createOrEditLabel">Create or edit a EmployeeProgramFinancial</Translate>
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        {loading ? (
                            <Loading />
                        ) : (
                            <AvForm model={isNew ? {} : employeeProgramFinancialEntity} onSubmit={this.saveEntity}>
                                {!isNew ? (
                                    <AvGroup>
                                        <Label for="employee-program-financial-id">
                                            <Translate contentKey="global.field.id">ID</Translate>
                                        </Label>
                                        <AvInput id="employee-program-financial-id" type="text" className="form-control" name="id" required readOnly />
                                    </AvGroup>
                                ) : null}
                                <AvGroup>
                                    <Label for="employee-program-financial-company">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramFinancial.company">Company</Translate>
                                    </Label>
                                    <AvInput id="employee-program-financial-company" type="select" className="form-control" name="company.id" value={isNew ? companies[0] && companies[0].id : employeeProgramFinancialEntity.company.id} required>
                                        {companies
                                            ? companies.map(otherEntity => (
                                                  <option value={otherEntity.id} key={otherEntity.id}>
                                                      {otherEntity.name}
                                                  </option>
                                              ))
                                            : null}
                                    </AvInput>
                                    <AvFeedback>
                                        <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                    </AvFeedback>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="employee-program-financial-program">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramFinancial.program">Program</Translate>
                                    </Label>
                                    <AvInput
                                        id="employee-program-financial-program"
                                        type="select"
                                        className="form-control"
                                        name="program.id"
                                        value={isNew ? employeePrograms[0] && employeePrograms[0].id : employeeProgramFinancialEntity.program.id}
                                        required>
                                        {employeePrograms
                                            ? employeePrograms.map(otherEntity => (
                                                  <option value={otherEntity.id} key={otherEntity.id}>
                                                      {otherEntity.name}
                                                  </option>
                                              ))
                                            : null}
                                    </AvInput>
                                    <AvFeedback>
                                        <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                    </AvFeedback>
                                </AvGroup>
                                <Button tag={Link} id="cancel-save" to="/entity/employee-program-financial" replace color="info">
                                    <FontAwesomeIcon icon="arrow-left" />
                                    &nbsp;
                                    <span className="d-none d-md-inline">
                                        <Translate contentKey="entity.action.back">Back</Translate>
                                    </span>
                                </Button>
                                &nbsp;
                                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                    <FontAwesomeIcon icon="save" />
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Save</Translate>
                                </Button>
                            </AvForm>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    companies: storeState.company.data,
    employeePrograms: storeState.employeeProgram.entities,
    employeeProgramFinancialEntity: storeState.employeeProgramFinancial.entity,
    loading: storeState.employeeProgramFinancial.loading,
    updating: storeState.employeeProgramFinancial.updating,
    updateSuccess: storeState.employeeProgramFinancial.updateSuccess,
});

const mapDispatchToProps = {
    getCompanies,
    getEmployeePrograms,
    getEntity,
    updateEntity,
    createEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgramFinancialUpdate);
