import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { InputPersonIdentification } from 'app/components/person/person-identification-input';
import { IAddress } from 'app/shared/model/address.model';
import { IBankAccount } from 'app/shared/model/bank-account.model';
import { ICompany } from 'app/shared/model/company.model';
import { IEmailAddress } from 'app/shared/model/email-address.model';
import { PersonType } from 'app/shared/model/enumerations/person-type.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { IPhone } from 'app/shared/model/phone.model';
import { IRootState } from 'app/shared/reducers';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { getBanks } from '../bank/bank.reducer';
import { searchCities } from '../city/city.reducer';
import { getOrganizations } from '../organization/organization.reducer';
import { CompanyAccountsManager } from './company-accounts-manager';
import { CompanyAddressesManager } from './company-addresses-manager';
import { CompanyEmailAddressesManager } from './company-emailaddresses-manager';
import { CompanyOrganizationManager } from './company-organizations-manager';
import { CompanyPhonesManager } from './company-phones-manager';
import { createCompany, getCompany, reset, updateCompany } from './company.reducer';
import './company.scss';

export interface ICompanyManagerProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICompanyManagerState {
    company: ICompany;
    editings: ReadonlyArray<ICompanyEdition>;
}

export enum EditActionType {
    START,
    FINISH,
    CANCEL,
}

enum CompanyEntity {
    ACCOUNTS,
    PHONES,
    EMAIL_ADDRESSES,
    ADDRESSES,
}

interface ICompanyEdition {
    entity: CompanyEntity;
    editing: boolean;
}

export class CompanyManager extends Component<ICompanyManagerProps, ICompanyManagerState> {
    constructor(props: ICompanyManagerProps) {
        super(props);
        this.state = {
            company: props.company,
            editings: [
                { entity: CompanyEntity.PHONES, editing: false },
                { entity: CompanyEntity.EMAIL_ADDRESSES, editing: false },
                { entity: CompanyEntity.ACCOUNTS, editing: false },
                { entity: CompanyEntity.ADDRESSES, editing: false },
            ],
        };
    }

    saveCompany = (_event: any, _values: any): void => {
        if (this.state.company && this.state.company.id) {
            this.props.updateCompany(this.state.company);
        } else {
            this.props.createCompany(this.state.company);
        }
    };

    handleNameChange = (event: OnChangeEvent, value: string): void => {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, name: value },
        }));
        event.preventDefault();
    };

    handleTypeChange = (event: OnChangeEvent, value: PersonType) => {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, type: value },
        }));
        event.preventDefault();
    };

    handleIdentificationChange = (event: OnChangeEvent, value: string) => {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, identification: value },
        }));
        event.preventDefault();
    };

    handleOrganizationsChange(update: ReadonlyArray<IOrganization>) {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, organizations: update },
        }));
    }

    handlePhonesChange = (update: IPhone[]) => {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, phones: update },
        }));
    };

    handleEmailAddressesChange = (update: ReadonlyArray<IEmailAddress>) => {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, emailAddresses: update },
        }));
    };

    handleAccountsChange = (update: IBankAccount[]) => {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, accounts: update },
        }));
    };

    handleAddressesChange = (update: IAddress[]) => {
        this.setState(prevState => ({
            ...prevState,
            company: { ...prevState.company, addresses: update },
        }));
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getCompany(this.props.match.params.id);
        }
    }

    componentWillReceiveProps(newProps: ICompanyManagerProps) {
        if (newProps.findItem) {
            this.setState({
                company: newProps.company,
            });
            this.props.reset();
        }

        if (newProps.updateSuccess) {
            this.props.reset();
            this.handleClose();
        }
    }

    handleClose = (): void => {
        this.props.history.push('/entity/company');
    };

    handleEditCallBack = (target: CompanyEntity, action: EditActionType) => {
        this.setState(prevState => ({
            ...prevState,
            editings: prevState.editings.map(it => {
                if (it.entity === target) {
                    it.editing = action === EditActionType.START;
                }
                return it;
            }),
        }));
    };

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const { company, editings } = this.state;
        const { loading, updating } = this.props;

        return (
            <>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h1>
                            <Translate contentKey="companyManager.createOrEditLabel">Create or edit a Organization</Translate>
                        </h1>
                        <div>
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <>
                                    <AvForm onValidSubmit={this.saveCompany} disabled={updating || editings.filter(it => it.editing === true).length > 0}>
                                        <Row>
                                            <Col>
                                                <AvGroup>
                                                    <Label for="name">
                                                        <Translate contentKey="company.name">Name</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: translate('companyManager.validate.name.required'),
                                                            },
                                                            minLength: {
                                                                value: 1,
                                                                errorMessage: translate('companyManager.validate.name.minlength'),
                                                            },
                                                            maxLength: {
                                                                value: 255,
                                                                errorMessage: translate('companyManager.validate.name.maxlength'),
                                                            },
                                                        }}
                                                        onChange={this.handleNameChange}
                                                        value={company.name || ''}
                                                    />
                                                </AvGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <AvGroup>
                                                    <Label for="type">
                                                        <Translate contentKey="company.type">Type</Translate>
                                                    </Label>
                                                    <AvField
                                                        type="select"
                                                        className="form-control"
                                                        name="type"
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: translate('companyManager.validate.type.required'),
                                                            },
                                                        }}
                                                        onChange={this.handleTypeChange}
                                                        value={company.type}>
                                                        <option value={PersonType.INDIVIDUAL}>{translate('companyType.INDIVIDUAL')}</option>
                                                        <option value={PersonType.CORPORATION}>{translate('companyType.CORPORATION')}</option>
                                                    </AvField>
                                                </AvGroup>
                                            </Col>
                                            <Col md="8">
                                                <InputPersonIdentification
                                                    identification={company.identification}
                                                    type={company.type}
                                                    label={{ INDIVIDUAL: 'company.identification.cpf', CORPORATION: 'company.identification.cnpj' }}
                                                    required={true}
                                                    requiredMessage={{
                                                        INDIVIDUAL: 'companyManager.validate.identification.cpf.required',
                                                        CORPORATION: 'companyManager.validate.identification.cnpj.required',
                                                    }}
                                                    invalidMessage={{
                                                        INDIVIDUAL: 'companyManager.validate.identification.cpf.invalid',
                                                        CORPORATION: 'companyManager.validate.identification.cnpj.invalid',
                                                    }}
                                                    changeCallback={this.handleIdentificationChange}
                                                />
                                            </Col>
                                        </Row>
                                        <CompanyOrganizationManager
                                            showModal={this.props.showModal}
                                            hideModal={this.props.hideModal}
                                            getOrganizations={this.props.getOrganizations}
                                            organizations={company.organizations}
                                            updateCallback={it => this.handleOrganizationsChange(it)}
                                        />
                                        <CompanyAccountsManager
                                            showModal={this.props.showModal}
                                            hideModal={this.props.hideModal}
                                            getBanks={this.props.getBanks}
                                            accounts={company.accounts}
                                            updateCallback={it => this.handleAccountsChange(it)}
                                            editCallback={it => this.handleEditCallBack(CompanyEntity.ACCOUNTS, it)}
                                        />
                                        <CompanyAddressesManager
                                            showModal={this.props.showModal}
                                            hideModal={this.props.hideModal}
                                            searchCities={this.props.searchCities}
                                            addresses={company.addresses}
                                            updateCallback={it => this.handleAddressesChange(it)}
                                            editCallback={it => this.handleEditCallBack(CompanyEntity.ADDRESSES, it)}
                                        />
                                        <CompanyPhonesManager phones={company.phones} updateCallback={it => this.handlePhonesChange(it)} editCallback={it => this.handleEditCallBack(CompanyEntity.PHONES, it)} />
                                        <CompanyEmailAddressesManager
                                            emailAddresses={company.emailAddresses}
                                            updateCallback={it => this.handleEmailAddressesChange(it)}
                                            editCallback={it => this.handleEditCallBack(CompanyEntity.EMAIL_ADDRESSES, it)}
                                        />
                                        <Button tag={Link} to="/entity/company" replace color="info">
                                            <FontAwesomeIcon icon="arrow-left" /> <Translate contentKey="entity.action.back">Back</Translate>
                                        </Button>{' '}
                                        <Button color="primary" type="submit" disabled={updating || editings.filter(it => it.editing === true).length > 0}>
                                            <FontAwesomeIcon icon="save" /> <Translate contentKey="entity.action.save">Save</Translate>
                                        </Button>
                                    </AvForm>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    company: storeState.company.company,
    loading: storeState.company.loading,
    updating: storeState.company.updating,
    findItem: storeState.company.findItem,
    updateSuccess: storeState.company.updateSuccess,
});

const mapDispatchToProps = {
    showModal,
    hideModal,
    getOrganizations,
    getBanks,
    getCompany,
    searchCities,
    updateCompany,
    createCompany,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyManager);
