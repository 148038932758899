import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { InviteStatus } from 'app/shared/model/enumerations/invite-status';
import { InviteToConfirmRequest } from 'app/shared/model/invite';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import * as H from 'history';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { confirmCompanyInvite, recoverInviteByToken, reset } from './invite.reducer';

export interface IAcceptInviteParams {
    token: string;
}

export interface IAcceptInvite extends StateProps, DispatchProps {}

const I18N_PREFIX = 'giroEmployeeSalaryAdvanceApp.invite';

export const AcceptInvite = (props: IAcceptInvite) => {
    const { token } = useParams<IAcceptInviteParams>();
    const history: H.History = useHistory();
    const [password, setPassword] = React.useState<string>('');
    const { loading, updating, invite, errorMessage, confirmationSuccessfull } = props.inviteState;

    React.useEffect(() => {
        return () => {
            props.reset();
        };
    }, [props.reset]);

    React.useEffect(() => {
        if (token) {
            props.recoverInviteByToken(token);
        }
    }, [token]);

    React.useEffect(() => {
        if (!updating && confirmationSuccessfull) {
            history.push('/');
        }
    }, [history, updating, confirmationSuccessfull]);

    if (loading) {
        return <></>;
    }

    const handleClose = () => {
        history.push('/');
    };

    if (errorMessage || !invite) {
        return (
            <>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="register-title">
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.error.title`}>Error</Translate>
                        </h2>

                        <h5>
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.error.generic`}>message</Translate>
                        </h5>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        <Button id="close" color="primary" onClick={handleClose}>
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.close`}>Close</Translate>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }

    if (InviteStatus.OPEN !== invite.status) {
        return (
            <>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="register-title">
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.error.title`}>Error</Translate>
                        </h2>
                        <h5>
                            {
                                ({
                                    CLOSED: <Translate contentKey={`${I18N_PREFIX}.accept-invite.error.wrong-status.CLOSED`}>message</Translate>,
                                    WAITING_CONFIRMATION: <Translate contentKey={`${I18N_PREFIX}.accept-invite.error.wrong-status.WAITING_CONFIRMATION`}>message</Translate>,
                                    CONFIRMED: <Translate contentKey={`${I18N_PREFIX}.accept-invite.error.wrong-status.CONFIRMED`}>message</Translate>,
                                    OPEN: <></>,
                                } as Record<InviteStatus, React.ReactNode>)[invite.status]
                            }
                        </h5>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        <Button id="close" color="primary" onClick={handleClose}>
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.close`}>Close</Translate>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }

    const handleValidSubmit = (event, values) => {
        const request: InviteToConfirmRequest = {
            id: invite.id,
            password: values.password,
            passwordMatch: values.passwordMatch,
            personName: values.personName,
        };
        props.confirmCompanyInvite(request);
        event.preventDefault();
    };

    return (
        <>
            <Row className="justify-content-center">
                <Col md="8">
                    <h2 id="register-title">
                        <Translate contentKey={`${I18N_PREFIX}.accept-invite.title`}>Invite</Translate>
                    </h2>

                    <h5>
                        {invite.companyName ? (
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.register`} interpolate={{ companyName: invite.companyName }}>
                                Register
                            </Translate>
                        ) : (
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.register-fallback`}>Register</Translate>
                        )}
                    </h5>
                </Col>
            </Row>
            <br />
            <Row className="justify-content-center">
                <Col md="8">
                    <AvForm id="register-form" onValidSubmit={handleValidSubmit}>
                        <AvField
                            name="personName"
                            autocomplete="username"
                            label={translate(`${I18N_PREFIX}.accept-invite.personName.label`)}
                            placeholder={translate(`${I18N_PREFIX}.accept-invite.personName.placeholder`)}
                            value={[invite.user?.firstName, invite.user?.lastName].filter(it => !!it).join(' ')}
                            validate={{
                                required: { value: true, errorMessage: translate(`${I18N_PREFIX}.accept-invite.personName.validation.required`) },
                                maxLength: { value: 100, errorMessage: translate(`${I18N_PREFIX}.accept-invite.personName.validation.maxLength`) },
                            }}
                        />
                        <AvField readOnly name="username" autocomplete="username" label={translate(`${I18N_PREFIX}.accept-invite.login.label`)} value={invite.user?.login ?? invite.email} />
                        <PasswordStrengthBar password={password} />
                        <AvField
                            name="password"
                            label={translate(`${I18N_PREFIX}.accept-invite.password.label`)}
                            placeholder={translate(`${I18N_PREFIX}.accept-invite.password.placeholder`)}
                            type="password"
                            autocomplete="new-password"
                            onChange={setPassword}
                            validate={{
                                required: { value: true, errorMessage: translate(`${I18N_PREFIX}.accept-invite.password.validation.required`) },
                                minLength: { value: 4, errorMessage: translate(`${I18N_PREFIX}.accept-invite.password.validation.minLength`) },
                                maxLength: { value: 50, errorMessage: translate(`${I18N_PREFIX}.accept-invite.personName.password.maxLength`) },
                            }}
                        />

                        <AvField
                            name="passwordMatch"
                            label={translate(`${I18N_PREFIX}.accept-invite.passwordMatch.label`)}
                            placeholder={translate(`${I18N_PREFIX}.accept-invite.passwordMatch.placeholder`)}
                            type="password"
                            autocomplete="new-password"
                            validate={{
                                required: { value: true, errorMessage: translate(`${I18N_PREFIX}.accept-invite.passwordMatch.validation.required`) },
                                minLength: { value: 4, errorMessage: translate(`${I18N_PREFIX}.accept-invite.passwordMatch.validation.minLength`) },
                                maxLength: { value: 50, errorMessage: translate(`${I18N_PREFIX}.accept-invite.passwordMatch.validation.maxLength`) },
                                match: { value: 'password', errorMessage: translate(`${I18N_PREFIX}.accept-invite.passwordMatch.validation.match`) },
                            }}
                        />
                        <Button id="register-submit" color="primary" type="submit" disabled={updating}>
                            <Translate contentKey={`${I18N_PREFIX}.accept-invite.submit`}>Register</Translate>
                        </Button>
                    </AvForm>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = ({ invite }: IRootState) => ({
    inviteState: invite,
});

const mapDispatchToProps = { recoverInviteByToken, confirmCompanyInvite, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvite);
