import { ICompanyEmployee } from 'app/shared/model/company-employee.model';
import { IEmployeeMonthlyCredit } from 'app/shared/model/employee-monthly-credit.model';
import { Moment } from 'moment';
import { IEmployeeProgramFinancial } from './employee-program-financial.model';
import { EmployeeAnticipationStatus } from './enumerations/employee-anticipation-status.model';
import { ISessionTerm } from './session-term.model';
export interface IEmployeeAnticipation {
    id?: number;
    externalUid?: string;
    amount?: number;
    taxAmount?: number;
    employeeAmount?: number;
    platformAmount?: number;
    requestedDate?: Moment;
    acceptedDate?: Moment;
    paidDate?: Moment;
    status?: EmployeeAnticipationStatus;
    month?: IEmployeeMonthlyCredit;
    employee?: ICompanyEmployee;
    assignmentTerm?: ISessionTerm;
    assignmentTermTemplate?: ISessionTerm;
    programFinancial?: IEmployeeProgramFinancial;
    ratePerMonth?: number;
}

export const defaultValue: Readonly<IEmployeeAnticipation> = {};
