import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPerson } from 'app/shared/model/person.model';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getPeople } from './person.reducer';

export interface IPersonProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IPersonState {
    people: ReadonlyArray<IPerson>;
    pagination: IPaginationBaseState;
}

export class Person extends React.Component<IPersonProps, IPersonState> {
    constructor(props) {
        super(props);

        this.state = {
            people: props.people,
            pagination: getSortState(this.props.location, ITEMS_PER_PAGE),
        };
    }

    sort = p => () => {
        this.setState(
            prevState => ({
                pagination: {
                    ...prevState.pagination,
                    order: this.state.pagination.order === 'asc' ? 'desc' : 'asc',
                    sort: p,
                },
            }),
            () => this.sortPeoples()
        );
    };

    componentDidMount() {
        this.getPeoples();
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            people: newProps.people,
        });
    }

    sortPeoples() {
        const {
            pagination: { activePage, sort, order },
        } = this.state;

        this.getPeoples();
        this.props.history.push(`${this.props.location.pathname}?page=${activePage}&sort=${sort},${order}`);
    }

    handlePagination = activePage =>
        this.setState(
            ps => ({ pagination: { ...ps.pagination, activePage } }),
            () => this.sortPeoples()
        );

    getPeoples = () => {
        const {
            pagination: { activePage, itemsPerPage, sort, order },
        } = this.state;
        this.props.getPeople('', activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    render() {
        return (
            <div>
                <h2 id="person-page-heading">
                    <Translate contentKey="people.title">Person</Translate>
                    <Link to={`${this.props.match.url}/new`} className="btn btn-primary float-right jh-create-entity">
                        <FontAwesomeIcon icon="plus" /> <Translate contentKey="people.createLabel">Create a new Person</Translate>
                    </Link>
                </h2>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="hand" onClick={this.sort('name')}>
                                <Translate contentKey="person.name">Name</Translate>
                                <FontAwesomeIcon icon="sort" />
                            </th>
                            <th className="hand" onClick={this.sort('user.email')}>
                                <Translate contentKey="person.email">Email</Translate>
                                <FontAwesomeIcon icon="sort" />
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.people.map((person, i) => (
                            <tr id={person.id} key={`user-${i}`}>
                                <td>{person.name}</td>
                                <td>{person.email}</td>
                                <td className="text-right">
                                    <div className="btn-group flex-btn-group-container">
                                        <Button tag={Link} to={`${this.props.match.url}/${person.id}`} color="info" size="sm">
                                            <FontAwesomeIcon icon="eye" />{' '}
                                            <span className="d-none d-md-inline">
                                                <Translate contentKey="entity.action.view">View</Translate>
                                            </span>
                                        </Button>
                                        <Button tag={Link} to={`${this.props.match.url}/${person.id}/edit`} color="primary" size="sm">
                                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                                            <span className="d-none d-md-inline">
                                                <Translate contentKey="entity.action.edit">Edit</Translate>
                                            </span>
                                        </Button>
                                        <Button tag={Link} to={`${this.props.match.url}/${person.id}/delete`} color="danger" size="sm">
                                            <FontAwesomeIcon icon="trash" />{' '}
                                            <span className="d-none d-md-inline">
                                                <Translate contentKey="entity.action.delete">Delete</Translate>
                                            </span>
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className={this.props.people && this.props.people.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.pagination.activePage} total={this.props.totalItems} itemsPerPage={this.state.pagination.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.pagination.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.pagination.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    people: storeState.person.data,
    totalItems: storeState.person.totalItems,
});

const mapDispatchToProps = { getPeople };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Person);
