import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Footer from 'app/shared/layout/footer/footer';
import Header from 'app/shared/layout/header/header';
import { IRootState } from 'app/shared/reducers';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
import { setLocale } from 'app/shared/reducers/locale';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from 'reactstrap';
import './app.scss';
import ModalRoot from './components/modal/ModalRoot';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
    useEffect(() => {
        props.getSession();
        props.getProfile();
    }, []);

    const paddingTop = '60px';
    return (
        <Router basename={baseHref}>
            <div className="app-container" style={{ paddingTop }}>
                <ModalRoot />
                <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
                <ErrorBoundary>
                    <Header
                        isAuthenticated={props.isAuthenticated}
                        isAdmin={props.isAdmin}
                        isCompany={props.isCompany}
                        currentLocale={props.currentLocale}
                        onLocaleChange={props.setLocale}
                        ribbonEnv={props.ribbonEnv}
                        isInProduction={props.isInProduction}
                        isSwaggerEnabled={props.isSwaggerEnabled}
                    />
                </ErrorBoundary>
                <div className="container-fluid view-container" id="app-view-container">
                    <Card className="jh-card">
                        <ErrorBoundary>
                            <AppRoutes />
                        </ErrorBoundary>
                    </Card>
                    <Footer />
                </div>
            </div>
        </Router>
    );
};

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
    currentLocale: locale.currentLocale,
    isAuthenticated: authentication.isAuthenticated,
    isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
    isCompany: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.COMPANY]),
    ribbonEnv: applicationProfile.ribbonEnv,
    isInProduction: applicationProfile.inProduction,
    isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
});

const mapDispatchToProps = { setLocale, getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
