import { IPageRequest } from 'app/shared/model/page.model';
import { IRootState } from 'app/shared/reducers';
import { OnChangeEvent } from 'app/util/types';
import { debounce } from 'lodash';
import * as React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal } from 'reactstrap';
import './select-option-modal.css';

export interface ISelectOptionModalState {
    data?: any;
    displayProperty: [string];
    separator?: [string];
    title: [string];
    placeholderInput?: string;
    action?: (search, page, size, sort) => void;
    cancelCallback: () => void;
    saveCallback: (data: any) => void;
    requestObject?: IPageRequest;
    infiniteScrolling?: boolean;
    search?: string;
    loadingMore?: boolean;
    hideInput?: boolean;
    waitToPerformRequest?: boolean;
}

export interface ISelectOptionModalProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
    config: {
        stateAction?: string;
    };
}

class SelectOptionModal extends React.Component<ISelectOptionModalProps, ISelectOptionModalState> {
    listContent: any = React.createRef();

    constructor(props) {
        super(props);

        const requestObject: IPageRequest = {
            page: 0,
            size: 10,
        };

        this.state = {
            data: props.config.data ? props.config.data : [],
            title: props.config.title,
            displayProperty: props.config.displayProperty,
            separator: props.config.separator,
            cancelCallback: props.config.cancelCallback,
            saveCallback: props.config.saveCallback,
            action: props.config.action,
            placeholderInput: props.config.placeholderInput,
            requestObject,
            infiniteScrolling: props.config.infiniteScrolling !== false,
            hideInput: props.config.hideInput,
            waitToPerformRequest: props.config.waitToPerformRequest,
        };

        // this.listContent = React.createRef();

        this.performAction = debounce(this.performAction, 500);
    }

    mapItemName = (item: {}) => {
        let name = '';
        const separator = this.state.separator ? this.state.separator : ' - ';
        this.state.displayProperty.forEach((param: any) => {
            if (name) {
                name = name + separator;
            }

            let object = item;
            param.forEach(it => {
                object = object[it] ? object[it] : '';
                if (typeof object === 'object') {
                    object = object['name'] ? object['name'] : '';
                }
            });

            name = name + object;
        });

        name = name.replace(/\s?-?\s?\s?-\s?$/, '');
        name = name.replace(/\s-\s\s?-\s/, ' - ');
        return name;
    };
    handleOnChangeEvent = (item: { name: string; checked: boolean }) => {
        const newData = this.state.data;

        newData.map((dataItem: any) => {
            if (dataItem === item) {
                const newObject = dataItem;
                newObject.checked = !dataItem.checked;
                return newObject;
            }
            return dataItem;
        });

        this.setState({ data: newData });
    };
    public handleOnChangeText = (event: OnChangeEvent) => {
        const text = event.target.value;
        this.setState(
            ps => ({
                ...ps,
                search: text,
                data: [],
                loadingMore: false,
            }),
            () => this.performAction(text)
        );
    };
    handleGetMoreItems = () => {
        const updateRequestObject = this.state.requestObject;
        updateRequestObject.page++;
        this.setState({
            requestObject: updateRequestObject,
            loadingMore: true,
        });

        this.state.action(this.state.search, this.state.requestObject.page, this.state.requestObject.size, 'name,asc');
    };
    performAction = (text: string) => {
        const updateRequestObject = this.state.requestObject;
        updateRequestObject.page = 0;
        this.setState({
            requestObject: updateRequestObject,
        });

        this.state.action(text, this.state.requestObject.page, this.state.requestObject.size, 'name,asc');
    };

    componentDidMount() {
        if (this.state.infiniteScrolling) {
            if (this.listContent == null) {
                this.listContent = React.createRef();
            }
            // this.listContent.addEventListener('scroll', () => {
            //     if (this.listContent?.scrollTop ?? 0 + this.listContent?.clientHeight ?? 0 >= this.listContent?.scrollHeight ?? 0) {
            //         this.handleGetMoreItems();
            //     }
            // });
        }
    }

    componentWillMount() {
        if (this.state.waitToPerformRequest) {
            return;
        }
        if (this.state.action) {
            this.state.action(undefined, this.state.requestObject.page, this.state.requestObject.size, 'name,asc');
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.data) {
            if (this.state.loadingMore) {
                const newData = [...this.state.data, ...newProps.data];
                this.setState({ data: newData, loadingMore: false });
            } else {
                this.setState({ data: newProps.data });
            }
            return;
        }
        if (newProps.page) {
            this.setState({
                data: newProps.data,
                loadingMore: false,
            });
            return;
        }
    }

    render() {
        return (
            <Modal isOpen className={'select-option-modal-card-style'}>
                <div className={'select-option-modal-content-container'}>
                    <div>
                        <label className={'select-option-modal-title-style'}>{this.state.title}</label>
                    </div>

                    {this.state.action && !this.state.hideInput && (
                        <div style={{ marginRight: '10px' }} className={'select-option-modal-search-input-container'}>
                            <input className="form-control" value={this.state.search ? this.state.search : ''} onChange={this.handleOnChangeText} maxLength={200} placeholder={this.state.placeholderInput ? this.state.placeholderInput : ''} />
                        </div>
                    )}

                    <div
                        className={'select-option-modal-div-style'}
                        ref={div => {
                            this.listContent = div;
                        }}>
                        {this.state.data.map((item: any, index: number) => (
                            <div className={'select-option-modal-item-container'} key={index}>
                                <label onClick={this.state.saveCallback.bind(this, item)} className={'select-option-modal-label'}>
                                    {this.mapItemName(item)}
                                </label>
                            </div>
                        ))}
                    </div>

                    <div className={'select-option-modal-buttons-container'}>
                        <Button color="info" type="button" onClick={this.state.cancelCallback}>
                            <Translate contentKey={'entity.action.back'}>Cancel</Translate>
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: any) => ({
    data: ownProps.config.stateAction ? storeState[ownProps.config.stateAction].data : null,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SelectOptionModal);
