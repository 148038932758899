import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import EmployeeAnticipation from './employee-anticipation';
import employeeAnticipationMonthlyReport from './employee-anticipation-report';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute path={`${match.url}/report`} component={employeeAnticipationMonthlyReport } />
            <ErrorBoundaryRoute path={match.url} component={EmployeeAnticipation} />
        </Switch>
    </>
);

export default Routes;
