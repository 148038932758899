import classnames from 'classnames';
import React from 'react';
import './button.css';

export interface IButtonProps {
    innerClassName?: string;
    outerClassName?: string;
    backgroundClassName?: string;
    buttonLabelClassName?: string;
    buttonDisabled?: boolean;
    title?: string;
    onClick?: () => void;
}

export class Button extends React.Component<IButtonProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div onClick={!!this.props.onClick && !this.props.buttonDisabled ? this.props.onClick : void 0} className={!this.props.outerClassName ? classnames('button-default-outer', this.props.outerClassName) : 'button-default-outer'}>
                <div className={!this.props.innerClassName ? classnames('button-default-inner', this.props.innerClassName) : 'button-default-inner'}>
                    <label className={!this.props.buttonLabelClassName ? classnames('button-label-default', this.props.buttonLabelClassName) : 'button-label-default'}>{this.props.title}</label>
                </div>
            </div>
        );
    }
}

export default Button;
