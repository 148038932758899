import {ICompany} from 'app/shared/model/company.model';
import {IEmployeeProgramTaxRange} from './employee-program-tax-range.model';
import {ProgramStatus} from './enumerations/program-status.model';

export interface IEmployeeProgram {
  id?: number;
  name?: string;
  estimatedPaymentDateInHours?: number;
  maxAnticipationAmount?: number;
  minAnticipationAmount?: number;
  monthlyAnticipationQuantityLimit?: number;
  maxAnticipationSalaryPercent?: number;
  monthlyDeadlineDay?: number;
  initialEmployeeBlockedMonths?: number;
  isDefault?: boolean;
  autoApplyForContractPeriod?: boolean;
  autoApplyContractPeriodInMonthsValue?: number;
  platformRemuneration?: number;
  assignmentTermTemplate?: string;
  status?: ProgramStatus;
  company?: ICompany;
  taxes?: IEmployeeProgramTaxRange[];
}

export const defaultValue: Readonly<IEmployeeProgram> = {
  assignmentTermTemplate: 'default'
};
