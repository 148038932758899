import { Invite, InviteToConfirmRequest } from 'app/shared/model/invite';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';

export const ACTION_TYPES = {
    RECOVER_INVITE: 'invite/RECOVER_INVITE',
    CONFIRM_INVITE: 'invite/CONFIRM_INVITE',
    RESET: 'invite/RESET',
};

export interface InviteState {
    loading: boolean;
    invite?: Invite;
    updating: boolean;
    confirmationSuccessfull: boolean;
    errorMessage?: string;
}

const initialState = {
    loading: true,
    invite: undefined,
    confirmationSuccessfull: false,
    updating: false,
    errorMessage: null,
};

// Reducer
export default (state: InviteState = initialState, action): InviteState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.RECOVER_INVITE):
            return {
                ...state,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CONFIRM_INVITE):
            return {
                ...state,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.RECOVER_INVITE):
            return {
                ...initialState,
                loading: false,
                errorMessage: action.payload.response.data.errorKey,
            };
        case FAILURE(ACTION_TYPES.CONFIRM_INVITE):
            return {
                ...state,
                updating: false,
                confirmationSuccessfull: false,
            };
        case SUCCESS(ACTION_TYPES.RECOVER_INVITE):
            return {
                ...initialState,
                loading: false,
                invite: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CONFIRM_INVITE):
            return {
                ...state,
                updating: false,
                confirmationSuccessfull: true,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

// Actions

export const recoverInviteByToken = (token: string) => ({
    type: ACTION_TYPES.RECOVER_INVITE,
    payload: axios.get(`api/invites/company/${token}`),
});

export const confirmCompanyInvite = (request: InviteToConfirmRequest) => ({
    type: ACTION_TYPES.CONFIRM_INVITE,
    payload: axios.put(`api/invites/company/${request.id}/confirm`, request),
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
