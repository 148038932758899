import { ICompany } from 'app/shared/model/company.model';

export interface IOrganization {
    id?: string;
    name?: string;
    organizationKey?: string;
    providerPanel?: boolean;
    clientPanel?: boolean;
    clientAccessConfirmation?: boolean;
    providerAccessConfirmation?: boolean;
    sendEmail?: boolean;
    adminEmail?: string;
    token?: string;
    companies?: ICompany[];
}

export const defaultValue: Readonly<IOrganization> = {
    providerPanel: false,
    clientPanel: false,
    clientAccessConfirmation: false,
    providerAccessConfirmation: false,
    sendEmail: false,
    id: undefined,
    name: undefined,
    organizationKey: undefined,
};
