import { IEmailAddress } from 'app/shared/model/email-address.model';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

export interface EmailAddressCardDetailProps {
    emailAddress: IEmailAddress;
}

export const EmailAddressCardDetail = (props: EmailAddressCardDetailProps): JSX.Element => {
    const { emailAddress: emailAddress } = props;
    return (
        <>
            <Row>
                <Col md="12">
                    <dl>
                        <dt>
                            <Translate contentKey="emailAddress.email">Email</Translate>
                        </dt>
                        <dd>{emailAddress.email}</dd>
                    </dl>
                </Col>
            </Row>
        </>
    );
};
