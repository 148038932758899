import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './employee-monthly-credit.reducer';

export interface IEmployeeMonthlyCreditDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class EmployeeMonthlyCreditDetail extends React.Component<IEmployeeMonthlyCreditDetailProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    render() {
        const { employeeMonthlyCreditEntity } = this.props;
        return (
            <Row>
                <Col md="8">
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.detail.title">EmployeeMonthlyCredit</Translate> [<b>{employeeMonthlyCreditEntity.id}</b>]
                    </h2>
                    <dl className="jh-entity-details">
                        <dt>
                            <span id="month">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.month">Month</Translate>
                            </span>
                        </dt>
                        <dd>
                            <TextFormat value={employeeMonthlyCreditEntity.month} type="date" format={APP_LOCAL_DATE_FORMAT} />
                        </dd>
                        <dt>
                            <span id="workedAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.workedAmount">Worked Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeMonthlyCreditEntity.workedAmount}</dd>
                        <dt>
                            <span id="availableAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.availableAmount">Available Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeMonthlyCreditEntity.availableAmount}</dd>
                        <dt>
                            <span id="workedDays">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.workedDays">Worked Days</Translate>
                            </span>
                        </dt>
                        <dd>{employeeMonthlyCreditEntity.workedDays}</dd>
                        <dt>
                            <span id="blockedAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.blockedAmount">Blocked Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeMonthlyCreditEntity.blockedAmount}</dd>
                        <dt>
                            <span id="anticipatedAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.anticipatedAmount">Anticipated Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeMonthlyCreditEntity.anticipatedAmount}</dd>
                        <dt>
                            <span id="monthlyDeadlineReached">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.monthlyDeadlineReached">Monthly Deadline Reached</Translate>
                            </span>
                        </dt>
                        <dd>{employeeMonthlyCreditEntity.monthlyDeadlineReached ? 'true' : 'false'}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.employee">Employee</Translate>
                        </dt>
                        <dd>{employeeMonthlyCreditEntity.employee ? employeeMonthlyCreditEntity.employee.id : ''}</dd>
                    </dl>
                    <Button tag={Link} to="/entity/employee-monthly-credit" replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.back">Back</Translate>
                        </span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/entity/employee-monthly-credit/${employeeMonthlyCreditEntity.id}/edit`} replace color="primary">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ employeeMonthlyCredit }: IRootState) => ({
    employeeMonthlyCreditEntity: employeeMonthlyCredit.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMonthlyCreditDetail);
