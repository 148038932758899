import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPerson } from 'app/shared/model/person.model';
import { IRootState } from 'app/shared/reducers';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createPerson, getPerson, reset, updatePerson } from './person.reducer';

export interface IPersonManagerProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IPersonManagerState {
    person: IPerson;
}

export class PersonManager extends React.Component<IPersonManagerProps, IPersonManagerState> {
    constructor(props) {
        super(props);

        this.state = {
            person: props.person,
        };
    }

    savePerson = (event, values) => {
        if (this.state.person && this.state.person.id) {
            this.props.updatePerson(this.state.person);
        } else {
            this.props.createPerson(this.state.person);
        }
    };

    handleClose = () => {
        this.props.history.push('/entity/person');
    };

    handleNameChange = (event: OnChangeEvent, value: string) => {
        this.setState(prevState => ({
            person: { ...prevState.person, name: value },
        }));
        event.preventDefault();
    };

    handleEmailChange = (event: OnChangeEvent, value: string) => {
        this.setState(prevState => ({
            person: { ...prevState.person, email: value },
        }));
        event.preventDefault();
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getPerson(this.props.match.params.id);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.findItem) {
            this.setState({
                person: newProps.person,
            });
        }

        if (newProps.updateSuccess) {
            this.props.reset();
            this.handleClose();
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h1>
                            <Translate contentKey="personManager.createOrEditLabel">Create or edit a Person</Translate>
                        </h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        {this.props.loading ? (
                            <p>Loading...</p>
                        ) : (
                            <AvForm onValidSubmit={this.savePerson}>
                                <AvGroup>
                                    <Label for="name">
                                        <Translate contentKey="person.name">Name</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        onChange={this.handleNameChange}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: translate('personManager.validate.name.required'),
                                            },
                                            minLength: {
                                                value: 1,
                                                errorMessage: translate('personManager.validate.name.minlength'),
                                            },
                                            maxLength: {
                                                value: 255,
                                                errorMessage: translate('personManager.validate.name.maxlength'),
                                            },
                                        }}
                                        value={this.state.person.name}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label for="email">
                                        <Translate contentKey="person.email">Email</Translate>
                                    </Label>
                                    <AvField
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        onChange={this.handleEmailChange}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: translate('personManager.validate.email.required'),
                                            },
                                            minLength: {
                                                value: 1,
                                                errorMessage: translate('personManager.validate.email.minlength'),
                                            },
                                            maxLength: {
                                                value: 255,
                                                errorMessage: translate('personManager.validate.email.maxlength'),
                                            },
                                            email: {
                                                value: true,
                                                errorMessage: translate('personManager.validate.email.valid'),
                                            },
                                        }}
                                        value={this.state.person.email}
                                    />
                                </AvGroup>
                                <Button tag={Link} to="/entity/person" replace color="info">
                                    <FontAwesomeIcon icon="arrow-left" />
                                    &nbsp;
                                    <span className="d-none d-md-inline">
                                        <Translate contentKey="entity.action.back">Back</Translate>
                                    </span>
                                </Button>
                                &nbsp;
                                <Button color="primary" type="submit">
                                    <FontAwesomeIcon icon="save" />
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Save</Translate>
                                </Button>
                            </AvForm>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    person: storeState.person.person,
    loading: storeState.person.loading,
    updating: storeState.person.updating,
    updateSuccess: storeState.person.updateSuccess,
    findItem: storeState.person.data,
});

const mapDispatchToProps = {
    getPerson,
    updatePerson,
    createPerson,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonManager);
