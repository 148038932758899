import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appConfig from 'app/config/constants';
import React from 'react';
import { Translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavbarBrand, NavItem, NavLink } from 'reactstrap';

export const BrandIcon = props => (
    <div {...props} className="brand-icon">
        <img src="content/images/logo-jhipster.png" alt="Logo" />
    </div>
);

export const Brand = props => (
    <NavbarBrand tag={Link} to="/" className="brand-logo">
        {/* <BrandIcon /> */}
        <span className="brand-title">
            <Translate contentKey="global.title">Giro Antecipa</Translate>
        </span>
        <span className="navbar-version">{appConfig.VERSION}</span>
    </NavbarBrand>
);

export const Home = props => (
    <NavItem>
        <NavLink tag={Link} to="/" className="d-flex align-items-center">
            <FontAwesomeIcon icon="home" />
            <span>
                <Translate contentKey="global.menu.home">Home</Translate>
            </span>
        </NavLink>
    </NavItem>
);
