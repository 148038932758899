import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import Company from './company';
import CompanyDeleteDialog from './company-delete-dialog';
import CompanyDetail from './company-detail';
import CompanyManager from './company-manager';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CompanyManager} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CompanyManager} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CompanyDetail} />
            <ErrorBoundaryRoute path={match.url} component={Company} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CompanyDeleteDialog} />
    </>
);

export default Routes;
