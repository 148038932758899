import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getEntities } from './employee-program.reducer';

export interface IEmployeeProgramProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IEmployeeProgramState = IPaginationBaseState;

export class EmployeeProgram extends React.Component<IEmployeeProgramProps, IEmployeeProgramState> {
    state: IEmployeeProgramState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    render() {
        const { employeeProgramList, match, totalItems } = this.props;
        return (
            <div>
                <h2 id="employee-program-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.home.title">Employee Programs</Translate>
                    <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.home.createLabel">Create new Employee Program</Translate>
                    </Link>
                </h2>
                <div className="table-responsive">
                    {employeeProgramList && employeeProgramList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('isDefault')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.isDefaultList">isDefault</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('status')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('company.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.company">Company</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {employeeProgramList.map((employeeProgram, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>
                                            <Button tag={Link} to={`${match.url}/${employeeProgram.id}`} color="link" size="sm">
                                                {employeeProgram.id}
                                            </Button>
                                        </td>
                                        <td>{employeeProgram.name}</td>
                                        <td>{employeeProgram.isDefault ? 'Sim' : 'Não'}</td>
                                        <td>
                                            <Translate contentKey={`giroEmployeeSalaryAdvanceApp.ProgramStatus.${employeeProgram.status}`} />
                                        </td>
                                        <td>{employeeProgram.company ? <Link to={`company/${employeeProgram.company.id}`}>{employeeProgram.company.name}</Link> : ''}</td>
                                        <td className="text-right">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button tag={Link} to={`${match.url}/${employeeProgram.id}`} color="info" size="sm">
                                                    <FontAwesomeIcon icon="eye" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.view">View</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employeeProgram.id}/edit`} color="primary" size="sm">
                                                    <FontAwesomeIcon icon="pencil-alt" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employeeProgram.id}/delete`} color="danger" size="sm">
                                                    <FontAwesomeIcon icon="trash" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.delete">Delete</Translate>
                                                    </span>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.home.notFound">No Employee Programs found</Translate>
                        </div>
                    )}
                </div>
                <div className={employeeProgramList && employeeProgramList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ employeeProgram }: IRootState) => ({
    employeeProgramList: employeeProgram.entities,
    totalItems: employeeProgram.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgram);
