import MenuItem from 'app/shared/layout/menus/menu-item';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
    <NavDropdown icon="th-list" name={translate('global.menu.entities.main')} id="entity-menu" style={{ maxHeight: '80vh', overflow: 'auto' }}>
        <MenuItem icon="asterisk" to="/entity/organization">
            <Translate contentKey="global.menu.entities.organization" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/company">
            <Translate contentKey="global.menu.entities.company" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/person">
            <Translate contentKey="global.menu.entities.person" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/employee-program">
            <Translate contentKey="global.menu.entities.employeeProgram" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/employee-program-financial">
            <Translate contentKey="global.menu.entities.employeeProgramFinancial" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/company-employee">
            <Translate contentKey="global.menu.entities.companyEmployee" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/employee-monthly-credit">
            <Translate contentKey="global.menu.entities.employeeMonthlyCredit" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/employee-time-entry">
            <Translate contentKey="global.menu.entities.employeeTimeEntry" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/employee-anticipation-batch">
            <Translate contentKey="global.menu.entities.employeeAnticipation" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/employee-anticipation">
            <Translate contentKey="global.menu.entities.employeeAnticipationHistory" />
        </MenuItem>
        {/* <MenuItem icon="asterisk" to="/entity/bank">
            <Translate contentKey="global.menu.entities.bank" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/entity/bank-account">
            <Translate contentKey="global.menu.entities.bankAccount" />
        </MenuItem> */}

        <MenuItem icon="asterisk" to="/entity/employee-program-tax-range">
            <Translate contentKey="global.menu.entities.employeeProgramTaxRange" />
        </MenuItem>
        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </NavDropdown>
);
