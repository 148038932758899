import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityCard } from 'app/components/card/entityCard/entity-card';
import { defaultValue, IEmailAddress } from 'app/shared/model/email-address.model';
import { IRootState } from 'app/shared/reducers';
import React, { Component } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { EmailAddressCardDetail } from '../emailaddress/emailaddress-card-detail';
import { EmailAddressCardManager } from '../emailaddress/emailaddress-card-manager';
import { EditActionType } from './company-manager';

export interface ICompanyEmailAddressesManagerProps extends StateProps, DispatchProps {
    updateCallback?: (phones: ReadonlyArray<IEmailAddress>) => void;
    editCallback?: (action: EditActionType) => void;
}

export interface ICompanyEmailAddressesManagerState {
    emailAddresses: ReadonlyArray<IEmailAddress>;
    editing: boolean;
}

export class CompanyEmailAddressesManager extends Component<ICompanyEmailAddressesManagerProps, ICompanyEmailAddressesManagerState> {
    constructor(props: ICompanyEmailAddressesManagerProps) {
        super(props);
        this.state = {
            emailAddresses: props.emailAddresses ? props.emailAddresses : ([] as ReadonlyArray<IEmailAddress>),
            editing: false,
        };
    }

    handleAdd = (_event: any) => {
        const { emailAddresses } = this.state;
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
                emailAddresses: [...emailAddresses, defaultValue],
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleRemove = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                emailAddresses: this.state.emailAddresses.filter((_phone, i) => {
                    return i !== index;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.emailAddresses);
                }
            }
        );
    };

    handleCancel = (index: number) => {
        if (this.state.emailAddresses[index].isNew) {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                    emailAddresses: this.state.emailAddresses.filter((_email, i) => {
                        return i !== index;
                    }),
                }),
                () => {
                    if (this.props.updateCallback) {
                        this.props.updateCallback(this.state.emailAddresses);
                    }
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        } else {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                }),
                () => {
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        }
    };

    handleUpdate = (index: number, update: IEmailAddress) => {
        this.setState(prevState => ({
            ...prevState,
            emailAddresses: this.state.emailAddresses.map((value: IEmailAddress, i: number) => {
                return i === index ? update : value;
            }),
        }));
    };

    handleSave = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
                emailAddresses: prevState.emailAddresses.map((email: IEmailAddress, i: number) => {
                    return i === index ? { ...email, isNew: false } : email;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.emailAddresses);
                }
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.FINISH);
                }
            }
        );
    };

    render() {
        const { emailAddresses, editing } = this.state;
        return (
            <>
                <h3 id="company-emailaddresses-heading">
                    <Translate contentKey="company.emailAddresses">Email Addresses</Translate>
                    <Button color="primary" className="float-right" type="button" disabled={editing} onClick={this.handleAdd}>
                        <FontAwesomeIcon icon="plus" /> <Translate contentKey="emailAddresses.createLabel">Create a new email address</Translate>
                    </Button>
                </h3>
                <div className="email-addresses-manage">
                    {emailAddresses && emailAddresses.length > 0 ? (
                        emailAddresses.map((emailAddress, i) => (
                            <EntityCard key={i} entity={emailAddress} editable={!emailAddress.id} removeCallback={() => this.handleRemove(i)} saveCallback={() => this.handleSave(i)} cancelCallback={() => this.handleCancel(i)}>
                                {{
                                    manager: <EmailAddressCardManager emailAddress={emailAddress} updateCallback={it => this.handleUpdate(i, it)} />,
                                    detail: <EmailAddressCardDetail emailAddress={emailAddress} />,
                                }}
                            </EntityCard>
                        ))
                    ) : (
                        <p>
                            <Translate contentKey="companyManager.empty.emails">No registered email address</Translate>
                        </p>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    emailAddresses: storeState.emailAddress.emailAddresses,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmailAddressesManager);
