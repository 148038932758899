import React, { useState } from 'react';
import { Storage, Translate } from 'react-jhipster';
import LoadingBar from 'react-redux-loading-bar';
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';
import { AccountMenu, AdminMenu, CompanyMenu, EntitiesMenu, LocaleMenu } from '../menus';
import { Brand, Home } from './header-components';
import './header.scss';

export interface IHeaderProps {
    isAuthenticated: boolean;
    isAdmin: boolean;
    isCompany: boolean;
    ribbonEnv: string;
    isInProduction: boolean;
    isSwaggerEnabled: boolean;
    currentLocale: string;
    onLocaleChange: Function;
}

const Header = (props: IHeaderProps) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLocaleChange = event => {
        const langKey = event.target.value;
        Storage.session.set('locale', langKey);
        props.onLocaleChange(langKey);
    };

    const renderDevRibbon = () =>
        props.isInProduction === false ? (
            <div className="ribbon dev">
                <a href="">
                    <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
                </a>
            </div>
        ) : null;

    const toggleMenu = () => setMenuOpen(!menuOpen);

    /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

    return (
        <div id="app-header">
            {renderDevRibbon()}
            <LoadingBar className="loading-bar" />
            <Navbar dark expand="sm" fixed="top" className="jh-navbar">
                <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
                <Brand />
                <Collapse isOpen={menuOpen} navbar>
                    <Nav id="header-tabs" className="ml-auto" navbar>
                        <Home />
                        {props.isAuthenticated && props.isAdmin && <EntitiesMenu />}
                        {props.isAuthenticated && props.isCompany && <CompanyMenu />}
                        {props.isAuthenticated && props.isAdmin && <AdminMenu showSwagger={props.isSwaggerEnabled} />}
                        <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                        <AccountMenu isAuthenticated={props.isAuthenticated} />
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
};

export default Header;
