import { defaultValue, IPerson } from 'app/shared/model/person.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster';

export const ACTION_TYPES = {
    FETCH_PEOPLE: 'person/FETCH_PEOPLE',
    FETCH_PERSON: 'person/FETCH_PERSON',
    CREATE_PERSON: 'person/CREATE_PERSON',
    UPDATE_PERSON: 'person/UPDATE_PERSON',
    DELETE_PERSON: 'person/DELETE_PERSON',
    RESET_ENTITY: 'person/RESET_ENTITY',
    RESET: 'person/RESET',
};

const initialState = {
    loading: false,
    findItem: false,
    errorMessage: null,
    data: [] as ReadonlyArray<IPerson>,
    person: defaultValue,
    updating: false,
    updateSuccess: false,
    totalItems: 0,
    page: null,
};

export type PersonState = Readonly<typeof initialState>;

export default (state: PersonState = initialState, action): PersonState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_PEOPLE):
        case REQUEST(ACTION_TYPES.FETCH_PERSON):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_PERSON):
        case REQUEST(ACTION_TYPES.UPDATE_PERSON):
        case REQUEST(ACTION_TYPES.DELETE_PERSON):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_PEOPLE):
        case FAILURE(ACTION_TYPES.FETCH_PERSON):
        case FAILURE(ACTION_TYPES.CREATE_PERSON):
        case FAILURE(ACTION_TYPES.UPDATE_PERSON):
        case FAILURE(ACTION_TYPES.DELETE_PERSON):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_PEOPLE):
            return {
                ...state,
                loading: false,
                data: action.payload.data.content,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_PERSON):
            return {
                ...state,
                loading: false,
                findItem: true,
                person: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_PERSON):
        case SUCCESS(ACTION_TYPES.UPDATE_PERSON):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                person: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_PERSON):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                person: defaultValue,
            };
        case ACTION_TYPES.RESET_ENTITY:
            return {
                ...state,
                person: defaultValue,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/people';

// Actions
export const getPeople: ICrudSearchAction<IPerson> = (search, page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${search ? `&search=${search}` : ''}`;
    return {
        type: ACTION_TYPES.FETCH_PEOPLE,
        payload: axios.get<IPerson>(requestUrl),
    };
};

export const getPerson: ICrudGetAction<IPerson> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_PERSON,
        payload: axios.get<IPerson>(requestUrl),
    };
};

export const createPerson: ICrudPutAction<IPerson> = person => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_PERSON,
        payload: axios.post(apiUrl, person),
    });
    dispatch(getPeople());
    return result;
};

export const updatePerson: ICrudPutAction<IPerson> = person => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_PERSON,
        payload: axios.put(apiUrl, person),
    });
    dispatch(getPeople());
    return result;
};

export const deletePerson: ICrudDeleteAction<IPerson> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_PERSON,
        payload: axios.delete(requestUrl),
    });
    dispatch(getPeople());
    return result;
};

export const resetEntity = () => ({
    type: ACTION_TYPES.RESET_ENTITY,
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
