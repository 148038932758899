import { defaultValue, IOrganization } from 'app/shared/model/organization.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, ICrudSearchAction } from 'react-jhipster';

export const ACTION_TYPES = {
    FETCH_ORGANIZATIONS: 'organization/FETCH_ORGANIZATIONS',
    FETCH_ORGANIZATION: 'organization/FETCH_ORGANIZATION',
    CREATE_ORGANIZATION: 'organization/CREATE_ORGANIZATION',
    UPDATE_ORGANIZATION: 'organization/UPDATE_ORGANIZATION',
    DELETE_ORGANIZATION: 'organization/DELETE_ORGANIZATION',
    RESET_ENTITY: 'organization/RESET_ENTITY',
    RESET: 'organization/RESET',
};

const initialState = {
    loading: false,
    findItem: false,
    errorMessage: null,
    data: [] as ReadonlyArray<IOrganization>,
    organization: defaultValue,
    updating: false,
    updateSuccess: false,
    totalItems: 0,
    page: null,
};

export type OrganizationState = Readonly<typeof initialState>;

// Reducer
export default (state: OrganizationState = initialState, action): OrganizationState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_ORGANIZATIONS):
        case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_ORGANIZATION):
        case REQUEST(ACTION_TYPES.UPDATE_ORGANIZATION):
        case REQUEST(ACTION_TYPES.DELETE_ORGANIZATION):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_ORGANIZATIONS):
        case FAILURE(ACTION_TYPES.FETCH_ORGANIZATION):
        case FAILURE(ACTION_TYPES.CREATE_ORGANIZATION):
        case FAILURE(ACTION_TYPES.UPDATE_ORGANIZATION):
        case FAILURE(ACTION_TYPES.DELETE_ORGANIZATION):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATIONS):
            return {
                ...state,
                loading: false,
                data: action.payload.data.content,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION):
            return {
                ...state,
                loading: false,
                findItem: true,
                organization: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_ORGANIZATION):
        case SUCCESS(ACTION_TYPES.UPDATE_ORGANIZATION):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                organization: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_ORGANIZATION):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                organization: defaultValue,
            };
        case ACTION_TYPES.RESET_ENTITY:
            return {
                ...state,
                organization: defaultValue,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/organizations';
// Actions
export const getOrganizations: ICrudSearchAction<IOrganization> = (search, page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${search ? `&search=${search}` : ''}`;
    return {
        type: ACTION_TYPES.FETCH_ORGANIZATIONS,
        payload: axios.get<IOrganization>(requestUrl),
    };
};

export const getOrganization: ICrudGetAction<IOrganization> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_ORGANIZATION,
        payload: axios.get<IOrganization>(requestUrl),
    };
};

export const createOrganization: ICrudPutAction<IOrganization> = user => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_ORGANIZATION,
        payload: axios.post(apiUrl, user),
    });
    dispatch(getOrganizations());
    return result;
};

export const updateOrganization: ICrudPutAction<IOrganization> = user => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_ORGANIZATION,
        payload: axios.put(apiUrl, user),
    });
    dispatch(getOrganizations());
    return result;
};

export const deleteOrganization: ICrudDeleteAction<IOrganization> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_ORGANIZATION,
        payload: axios.delete(requestUrl),
    });
    dispatch(getOrganizations());
    return result;
};

export const resetEntity = () => ({
    type: ACTION_TYPES.RESET_ENTITY,
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
