import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { activateCompanyEmployee, getEntity, inactivateCompanyEmployee } from 'app/entities/company-employee/company-employee.reducer';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface ICompanyEmployeeActivationDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

const I18N_PREFIX = 'giroEmployeeSalaryAdvanceApp.companyEmployee.activation';

export class CompanyEmployeeActivationDialog extends React.Component<ICompanyEmployeeActivationDialogProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    confirmDelete = event => {
        if (this.props.companyEmployeeEntity?.active === true) {
            this.props.inactivateCompanyEmployee(this.props.companyEmployeeEntity.id);
        } else {
            this.props.activateCompanyEmployee(this.props.companyEmployeeEntity.id);
        }
        this.handleClose(event);
    };

    handleClose = event => {
        event.stopPropagation();
        this.props.history.goBack();
    };

    render() {
        const { companyEmployeeEntity } = this.props;
        const current = companyEmployeeEntity.active === true ? 'active' : 'inactive';
        const active = companyEmployeeEntity.active === true;

        return (
            <Modal isOpen toggle={this.handleClose}>
                <ModalHeader toggle={this.handleClose}>
                    <Translate contentKey={`${I18N_PREFIX}.${current}.title`}>Activation title</Translate>
                </ModalHeader>
                <ModalBody id="giroEmployeeSalaryAdvanceApp.companyEmployee.delete.question">
                    <Translate contentKey={`${I18N_PREFIX}.${current}.question`} interpolate={{ name: companyEmployeeEntity?.person?.name, identification: companyEmployeeEntity?.identificationNumber }}>
                        Are you sure you want to activate this CompanyEmployee?
                    </Translate>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>
                        <FontAwesomeIcon icon="ban" />
                        &nbsp;
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                    <Button id="jhi-confirm-delete-companyEmployee" color={active ? 'danger' : 'success'} onClick={this.confirmDelete}>
                        <FontAwesomeIcon icon={active ? 'times' : 'check'} />
                        &nbsp;
                        <Translate contentKey={`${I18N_PREFIX}.${current}.action`}>Confirm</Translate>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = ({ companyEmployee }: IRootState) => ({
    companyEmployeeEntity: companyEmployee.entity,
});

const mapDispatchToProps = { getEntity, activateCompanyEmployee, inactivateCompanyEmployee };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmployeeActivationDialog);
