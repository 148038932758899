import { ICompany } from 'app/shared/model/company.model';
import { IEmployeeProgram } from 'app/shared/model/employee-program.model';

export interface IEmployeeProgramFinancial {
    id?: number;
    company?: ICompany;
    program?: IEmployeeProgram;
}

export const defaultValue: Readonly<IEmployeeProgramFinancial> = {};
