import React from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Alert, Container } from 'reactstrap';
import './home.scss';

export const HomeUnauthenticatedContainer = () => {
    return (
        <Container>
            <div>
                <Alert color="warning">
                    <Link to="/login" className="alert-link">
                        <Translate contentKey="global.messages.info.authenticated.link"> sign in</Translate>
                    </Link>
                </Alert>
            </div>
        </Container>
    );
};

export default HomeUnauthenticatedContainer;
