import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
// tslint:disable-next-line:no-unused-variable
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link,RouteComponentProps } from 'react-router-dom';
import { Button,Col,Row } from 'reactstrap';
import { getEntity } from './employee-program-tax-range.reducer';

export interface IEmployeeProgramTaxRangeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class EmployeeProgramTaxRangeDetail extends React.Component<IEmployeeProgramTaxRangeDetailProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    render() {
        const { employeeProgramTaxRangeEntity } = this.props;
        return (
            <Row>
                <Col md="8">
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.detail.title">EmployeeProgramTaxRange</Translate> [<b>{employeeProgramTaxRangeEntity.id}</b>]
                    </h2>
                    <dl className="jh-entity-details">
                        <dt>
                            <span id="name">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.name">Name</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramTaxRangeEntity.name}</dd>
                        <dt>
                            <span id="lowerAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.lowerAmount">Lower Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramTaxRangeEntity.lowerAmount}</dd>
                        <dt>
                            <span id="upperAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.upperAmount">Upper Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramTaxRangeEntity.upperAmount}</dd>
                        <dt>
                            <span id="taxAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.taxAmount">Tax Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramTaxRangeEntity.taxAmount}</dd>
                        <dt>
                            <span id="active">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.active">Active</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramTaxRangeEntity.active ? 'true' : 'false'}</dd>
                        <dt>
                            <span id="mode">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.mode">Mode</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramTaxRangeEntity.mode}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.program">Program</Translate>
                        </dt>
                        <dd>{employeeProgramTaxRangeEntity.program ? employeeProgramTaxRangeEntity.program.name : ''}</dd>
                    </dl>
                    <Button tag={Link} to="/entity/employee-program-tax-range" replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.back">Back</Translate>
                        </span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/entity/employee-program-tax-range/${employeeProgramTaxRangeEntity.id}/edit`} replace color="primary">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ employeeProgramTaxRange }: IRootState) => ({
    employeeProgramTaxRangeEntity: employeeProgramTaxRange.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgramTaxRangeDetail);
