import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityCard } from 'app/components/card/entityCard/entity-card';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import address from 'app/entities/address/address';
import { defaultValue, IAddress } from 'app/shared/model/address.model';
import { IRootState } from 'app/shared/reducers';
import { OnClickEvent } from 'app/util/types';
import React, { Component } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { AddressCardDetail } from '../address/address-card-detail';
import { AddressCardManager } from '../address/address-card-manager';
import { searchCities } from '../city/city.reducer';
import { EditActionType } from './company-manager';

export interface ICompanyAddressesManagerProps extends StateProps, DispatchProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    isSingle?: boolean;
    searchCities: typeof searchCities;
    updateCallback?: (addresses: IAddress[]) => void;
    editCallback?: (action: EditActionType) => void;
}

export interface ICompanyAddressesManagerState {
    addresses: IAddress[];
    editing: boolean;
}

export class CompanyAddressesManager extends Component<ICompanyAddressesManagerProps, ICompanyAddressesManagerState> {
    constructor(props: Readonly<ICompanyAddressesManagerProps>) {
        super(props);
        this.state = {
            addresses: props.addresses ? props.addresses : [],
            editing: false,
        };
    }

    handleAdd = (event: OnClickEvent): void => {
        const { addresses } = this.state;
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
                addresses: [...addresses, defaultValue],
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
        event.preventDefault();
    };

    handleRemove = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                addresses: this.state.addresses.filter((_address, i) => {
                    return i !== index;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.addresses);
                }
            }
        );
    };

    handleCancel = (index: number) => {
        if (this.state.addresses[index].isNew) {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                    addresses: this.state.addresses.filter((_address, i) => {
                        return i !== index;
                    }),
                }),
                () => {
                    if (this.props.updateCallback) {
                        this.props.updateCallback(this.state.addresses);
                    }
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        } else {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                }),
                () => {
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        }
    };

    handleEdit = () => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleUpdate = (index: number, update: IAddress): void => {
        const { addresses } = this.state;
        this.setState(prevState => ({
            ...prevState,
            addresses: addresses.map((value: IAddress, i: number) => {
                return i === index ? update : value;
            }),
        }));
    };

    handleSave = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
                addresses: prevState.addresses.map((account: IAddress, i: number) => {
                    return i === index ? { ...account, isNew: false } : account;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.addresses);
                }
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.FINISH);
                }
            }
        );
    };

    render() {
        const { addresses, editing } = this.state;
        return (
            <>
                <h3 id="company-phones-heading">
                    <Translate contentKey="company.addresses">Accounts</Translate>
                    {!this.props.isSingle || (this.props.isSingle && addresses.length === 0) && (<Button color="primary" className="float-right" type="button" disabled={editing} onClick={this.handleAdd}>
                        <FontAwesomeIcon icon="plus" />
                        <Translate contentKey="addresses.createLabel">Create a new address</Translate>
                    </Button>)}
                </h3>
                <div className="addresses-manager">
                    {addresses && addresses.length > 0 ? (
                        addresses.map((a, i) => (
                            <EntityCard
                                key={i}
                                entity={a}
                                editable={!editing}
                                editCallback={() => this.handleEdit()}
                                removeCallback={() => this.handleRemove(i)}
                                saveCallback={() => this.handleSave(i)}
                                cancelCallback={() => this.handleCancel(i)}>
                                {{
                                    manager: <AddressCardManager address={a} updateCallback={it => this.handleUpdate(i, it)} showModal={this.props.showModal} hideModal={this.props.hideModal} searchCities={this.props.searchCities} />,
                                    detail: <AddressCardDetail address={a} />,
                                }}
                            </EntityCard>
                        ))
                    ) : (
                        <p>
                            <Translate contentKey="companyManager.empty.addresses">No registered bank account</Translate>
                        </p>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    addresses: storeState.address.data,
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAddressesManager);
