import { HttpRequestStatus } from 'app/shared/model/enumerations/http-request-status';

const initialState = {
    file: {},
    status: HttpRequestStatus.NOOP,
};

export type UploadState = Readonly<typeof initialState>;

export default (state: UploadState = initialState, action): UploadState => {
    return state;
};
