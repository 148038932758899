import { defaultValue, ICity } from 'app/shared/model/city.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudSearchAction } from 'react-jhipster';

export const ACTION_TYPES = {
    FETCH_CITIES: 'cities/FETCH_CITIES',
};

const initialState = {
    loading: false,
    findItem: false,
    errorMessage: null,
    data: [] as ReadonlyArray<ICity>,
    entity: defaultValue,
    updating: false,
    updateSuccess: false,
    totalItems: 0,
    page: null,
};

export type CityState = Readonly<typeof initialState>;

// Reducer
export default (state: CityState = initialState, action): CityState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_CITIES):
            return {
                ...state,
                findItem: false,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_CITIES):
            return {
                ...state,
                findItem: false,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_CITIES):
            return {
                ...state,
                loading: false,
                data: action.payload.data.content,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        default:
            return state;
    }
};

const apiUrl = 'api/cities';

// Actions

export const searchCities: ICrudSearchAction<ICity> = (search, page, size, sort) => {
    const requestUrl = `${apiUrl}/simple${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${search ? `&search=${search}` : ''}`;
    return {
        type: ACTION_TYPES.FETCH_CITIES,
        payload: axios.get<ICity>(requestUrl),
    };
};
