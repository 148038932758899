import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import CompanyEmployee from './company-employee';
import CompanyEmployeeActivationDialog from './company-employee-activation-dialog';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute path={match.url} component={CompanyEmployee} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/activation`} component={CompanyEmployeeActivationDialog} />
    </>
);

export default Routes;
