import { defaultValue, IEmployeeAnticipation } from 'app/shared/model/employee-anticipation.model';
import { EmployeeAnticipationStatus } from 'app/shared/model/enumerations/employee-anticipation-status.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, IPayload, IPayloadResult } from 'react-jhipster';
import { EmployeeAnticipationModalFilterParameters } from './components/employee-anticipation-modal-filter/employee-anticipation-modal-filter';

export const ACTION_TYPES = {
    FETCH_EMPLOYEEANTICIPATION_LIST: 'employeeAnticipation/FETCH_EMPLOYEEANTICIPATION_LIST',
    FETCH_EMPLOYEEANTICIPATION: 'employeeAnticipation/FETCH_EMPLOYEEANTICIPATION',
    CREATE_EMPLOYEEANTICIPATION: 'employeeAnticipation/CREATE_EMPLOYEEANTICIPATION',
    ACCEPT_EMPLOYEEANTICIPATION: 'employeeAnticipation/ACCEPT_EMPLOYEEANTICIPATION',
    DECLINE_EMPLOYEEANTICIPATION: 'employeeAnticipation/DECLINE_EMPLOYEEANTICIPATION',
    EXPIRE_EMPLOYEEANTICIPATION: 'employeeAnticipation/EXPIRE_EMPLOYEEANTICIPATION',
    UPDATE_EMPLOYEEANTICIPATION: 'employeeAnticipation/UPDATE_EMPLOYEEANTICIPATION',
    SEND_ACTION: 'employeeAnticipation/SEND_ACTION',
    DELETE_EMPLOYEEANTICIPATION: 'employeeAnticipation/DELETE_EMPLOYEEANTICIPATION',
    RESET: 'employeeAnticipation/RESET',
    REGENERATE_EMPLOYEEANTICIPATION_TERM: 'employeeAnticipation/REGENERATE_EMPLOYEEANTICIPATION_TERM',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IEmployeeAnticipation>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type EmployeeAnticipationState = Readonly<typeof initialState>;

// Reducer

export default (state: EmployeeAnticipationState = initialState, action): EmployeeAnticipationState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION_LIST):
        case REQUEST(ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.ACCEPT_EMPLOYEEANTICIPATION):
        case REQUEST(ACTION_TYPES.SEND_ACTION):
        case REQUEST(ACTION_TYPES.DECLINE_EMPLOYEEANTICIPATION):
        case REQUEST(ACTION_TYPES.EXPIRE_EMPLOYEEANTICIPATION):
        case REQUEST(ACTION_TYPES.CREATE_EMPLOYEEANTICIPATION):
        case REQUEST(ACTION_TYPES.UPDATE_EMPLOYEEANTICIPATION):
        case REQUEST(ACTION_TYPES.DELETE_EMPLOYEEANTICIPATION):
        case REQUEST(ACTION_TYPES.REGENERATE_EMPLOYEEANTICIPATION_TERM):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION_LIST):
        case FAILURE(ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION):
        case FAILURE(ACTION_TYPES.ACCEPT_EMPLOYEEANTICIPATION):
        case FAILURE(ACTION_TYPES.EXPIRE_EMPLOYEEANTICIPATION):
        case FAILURE(ACTION_TYPES.DECLINE_EMPLOYEEANTICIPATION):
        case FAILURE(ACTION_TYPES.CREATE_EMPLOYEEANTICIPATION):
        case FAILURE(ACTION_TYPES.SEND_ACTION):
        case FAILURE(ACTION_TYPES.UPDATE_EMPLOYEEANTICIPATION):
        case FAILURE(ACTION_TYPES.DELETE_EMPLOYEEANTICIPATION):
        case FAILURE(ACTION_TYPES.REGENERATE_EMPLOYEEANTICIPATION_TERM):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data.content,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.ACCEPT_EMPLOYEEANTICIPATION):
        case SUCCESS(ACTION_TYPES.EXPIRE_EMPLOYEEANTICIPATION):
        case SUCCESS(ACTION_TYPES.SEND_ACTION):
        case SUCCESS(ACTION_TYPES.DECLINE_EMPLOYEEANTICIPATION):
        case SUCCESS(ACTION_TYPES.CREATE_EMPLOYEEANTICIPATION):
        case SUCCESS(ACTION_TYPES.UPDATE_EMPLOYEEANTICIPATION):
        case SUCCESS(ACTION_TYPES.REGENERATE_EMPLOYEEANTICIPATION_TERM):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_EMPLOYEEANTICIPATION):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/employee-anticipations';

// Actions

export const getEntities = (page?: number, size?: number, sort?: string, filterParameters?: EmployeeAnticipationModalFilterParameters): IPayload<IEmployeeAnticipation> => {
    const requestUrl = `${apiUrl}/filter${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${
        filterParameters
            ? filterParameters.id
                ? `&id=${filterParameters.id}`
                : '' || filterParameters.employee
                ? `&personName=${filterParameters.employee}`
                : '' || filterParameters.requestedDate
                ? `&requestedDate=${filterParameters.requestedDate}`
                : '' || filterParameters.status
                ? `&status=${filterParameters.status}`
                : ''
            : ''
    }`;

    return {
        type: ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION_LIST,
        payload: axios.get<IEmployeeAnticipation>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
    };
};

export const getPending: ICrudGetAllAction<IEmployeeAnticipation> = (page, size, sort) => {
    const params = [`status=contains(${EmployeeAnticipationStatus.PENDING})`, page && `page=${page}`, size && `size=${size}`, sort && `sort=${sort}`, `cacheBuster=${new Date().getTime()}`].filter(it => !!it).join('&');

    return {
        type: ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION_LIST,
        payload: axios.get<IEmployeeAnticipation>(`${apiUrl}?${params}`),
    };
};

export const getEntity: ICrudGetAction<IEmployeeAnticipation> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_EMPLOYEEANTICIPATION,
        payload: axios.get<IEmployeeAnticipation>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IEmployeeAnticipation> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_EMPLOYEEANTICIPATION,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const acceptAnticipation: any = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.ACCEPT_EMPLOYEEANTICIPATION,
        payload: axios.post(`${apiUrl}/${id}/accept`),
    });
    return result;
};

export const expireAnticipation: any = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.EXPIRE_EMPLOYEEANTICIPATION,
        payload: axios.patch(`${apiUrl}/${id}/expire`),
    });
    return result;
};

export const declineAnticipation: any = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.DECLINE_EMPLOYEEANTICIPATION,
        payload: axios.post(`${apiUrl}/${id}/decline`),
    });
    return result;
};

export const sendAnticipationAssignmentSignatureEmail: any = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.SEND_ACTION,
        payload: axios.post(`${apiUrl}/${id}/assignments/email`),
    });
    return result;
};

export const sendAnticipationAssignmentSignatureSms: any = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.SEND_ACTION,
        payload: axios.post(`${apiUrl}/${id}/assignments/sms`),
    });
    return result;
};

export const sendEmployeeAssignmentSignatureSms: any = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.SEND_ACTION,
        payload: axios.post(`${apiUrl}/${id}/assignments/employees/sms`),
    });
    return result;
};

export const sendEmployeeAssignmentSignatureEmail: any = id => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.SEND_ACTION,
        payload: axios.post(`${apiUrl}/${id}/assignments/employees/email`),
    });
    return result;
};

export const updateEntity: ICrudPutAction<IEmployeeAnticipation> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EMPLOYEEANTICIPATION,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const deleteEntity: ICrudDeleteAction<IEmployeeAnticipation> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_EMPLOYEEANTICIPATION,
        payload: axios.delete(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const regenerateAssignmentTerm: (id: number) => IPayload<IEmployeeAnticipation> | IPayloadResult<IEmployeeAnticipation> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}/session-term/regenerate`;
    const result = await dispatch({
        type: ACTION_TYPES.REGENERATE_EMPLOYEEANTICIPATION_TERM,
        payload: axios.post(requestUrl),
    });
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
