import { IEmployeeProgramTaxRange, TaxRangeStrategy } from 'app/shared/model/employee-program-tax-range.model';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { Col, Label, Row } from 'reactstrap';

export interface TaxRangeCardManagerProps {
    tax: IEmployeeProgramTaxRange;
    updateCallback?: (tax: IEmployeeProgramTaxRange) => void;
}

export interface TaxRangeCardManagerState {
    tax: IEmployeeProgramTaxRange;
}

export class TaxRangeCardManager extends React.Component<TaxRangeCardManagerProps, TaxRangeCardManagerState> {
    constructor(props: Readonly<TaxRangeCardManagerProps>) {
        super(props);
        this.state = {
            tax: props.tax,
        };
    }

    nameChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            prevState => ({
                tax: { ...prevState.tax, name: value },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.tax);
                }
            }
        );
    };

    taxAmountChanged = (event: OnChangeEvent, value: string) => {
        this.setState(
            prevState => ({
                tax: { ...prevState.tax, taxAmount: Number(value) },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.tax);
                }
            }
        );
    };

    lowerAmountChanged = (event: OnChangeEvent, value: string) => {
        this.setState(
            prevState => ({
                tax: { ...prevState.tax, lowerAmount: Number(value) },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.tax);
                }
            }
        );
    };

    upperAmountChanged = (event: OnChangeEvent, value: string) => {
        this.setState(
            prevState => ({
                tax: { ...prevState.tax, upperAmount: Number(value) },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.tax);
                }
            }
        );
    };

    typeChange = (event: OnChangeEvent, value: TaxRangeStrategy) => {
        this.setState(
            prevState => ({
                tax: { ...prevState.tax, mode: value },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.tax);
                }
            }
        );
    };

    handleActiveChange = (event: OnChangeEvent, value: boolean) => {
        this.setState(
            prevState => ({
                tax: { ...prevState.tax, active: value },
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.tax);
                }
            }
        );
    };

    render() {
        const { tax } = this.state;
        return (
            <>
                <Row>
                    <Col md="4">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="taxes.mode">Type</Translate>
                            </Label>
                            <AvField
                                type="select"
                                className="form-control"
                                name="type"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'required',
                                    },
                                }}
                                onChange={this.typeChange}
                                value={tax.mode}>
                                <option value="" selected>{translate('global.select.placeholder')}</option>
                                <option value={TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_EXCLUSIVE}>{translate('TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_EXCLUSIVE')}</option>
                                <option value={TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_INCLUSIVE}>{translate('TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_INCLUSIVE')}</option>
                                <option value={TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_EXCLUSIVE}>{translate('TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_EXCLUSIVE')}</option>
                                <option value={TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_INCLUSIVE}>{translate('TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_INCLUSIVE')}</option>
                            </AvField>
                        </AvGroup>
                    </Col>
                    <Col md="8">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="taxes.name">name</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="name"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'required',
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: 'max length',
                                    },
                                }}
                                onChange={this.nameChange}
                                value={tax.name}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="taxes.taxAmount">lowerAmount</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="taxAmount"
                                validate={{
                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                    min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                    max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                    number: { value: true, errorMessage: translate('entity.validation.number') },
                                }}
                                onChange={this.taxAmountChanged}
                                value={tax.taxAmount}
                            />
                        </AvGroup>
                        <AvGroup>
                            <Label>
                                <Translate contentKey="taxes.lowerAmount">lowerAmount</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="lowerAmount"
                                validate={{
                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                    min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                    max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 100000 }) },
                                    number: { value: true, errorMessage: translate('entity.validation.number') },
                                }}
                                onChange={this.lowerAmountChanged}
                                value={tax.lowerAmount}
                            />
                        </AvGroup>
                        <AvGroup>
                            <Label>
                                <Translate contentKey="taxes.upperAmount">upperAmount</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="upperAmount"
                                validate={{
                                    required: { value: true, errorMessage: translate('entity.validation.required') },
                                    min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                    max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 100000 }) },
                                    number: { value: true, errorMessage: translate('entity.validation.number') },
                                }}
                                onChange={this.upperAmountChanged}
                                value={tax.upperAmount}
                            />
                        </AvGroup>
                        <AvGroup check>
                            <Label check>
                                <AvInput type="checkbox" name="active" onChange={this.handleActiveChange} value={tax.active} checked={tax.active === true} /> <Translate contentKey="taxes.active">Active</Translate>
                            </Label>
                        </AvGroup>
                    </Col>
                </Row>
            </>
        );
    }
}
