import { Authority } from 'app/shared/model/enumerations/authorities';

const config = {
    VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export type AUTHORITIES_ENUM = typeof Authority;

export const AUTHORITIES: AUTHORITIES_ENUM = Authority;

export const messages = {
    DATA_ERROR_ALERT: 'Internal Error',
};

export const EMAIL_REGEX: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PHONE_REGEX: RegExp = /^[1-9]{2}9?\d{8}$/;

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
