import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { getEntities as getEmployeePrograms } from 'app/entities/employee-program/employee-program.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './employee-program-tax-range.reducer';

export interface IEmployeeProgramTaxRangeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEmployeeProgramTaxRangeUpdateState {
    isNew: boolean;
    programId: string;
}

export class EmployeeProgramTaxRangeUpdate extends React.Component<IEmployeeProgramTaxRangeUpdateProps, IEmployeeProgramTaxRangeUpdateState> {
    constructor(props) {
        super(props);
        this.state = {
            programId: '0',
            isNew: !this.props.match.params || !this.props.match.params.id,
        };
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }

        this.props.getEmployeePrograms();
    }

    saveEntity = (event, errors, values) => {
        if (errors.length === 0) {
            const { employeeProgramTaxRangeEntity } = this.props;
            const entity = {
                ...employeeProgramTaxRangeEntity,
                ...values,
            };

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };

    handleClose = () => {
        this.props.history.push('/entity/employee-program-tax-range');
    };

    render() {
        const { employeeProgramTaxRangeEntity, employeePrograms, loading, updating } = this.props;
        const { isNew } = this.state;

        if (employeeProgramTaxRangeEntity == null || employeeProgramTaxRangeEntity.id == null) {
            return <Loading />;
        }
        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.home.createOrEditLabel">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.home.createOrEditLabel">Create or edit a EmployeeProgramTaxRange</Translate>
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        {loading ? (
                            <Loading />
                        ) : (
                            <AvForm model={isNew ? {} : employeeProgramTaxRangeEntity} onSubmit={this.saveEntity}>
                                {!isNew ? (
                                    <AvGroup>
                                        <Label for="employee-program-tax-range-id">
                                            <Translate contentKey="global.field.id">ID</Translate>
                                        </Label>
                                        <AvInput id="employee-program-tax-range-id" type="text" className="form-control" name="id" required readOnly />
                                    </AvGroup>
                                ) : null}
                                <AvGroup>
                                    <Label id="nameLabel" for="employee-program-tax-range-name">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.name">Name</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-program-tax-range-name"
                                        type="text"
                                        name="name"
                                        validate={{
                                            minLength: { value: 0, errorMessage: translate('entity.validation.minlength', { min: 0 }) },
                                            maxLength: { value: 100, errorMessage: translate('entity.validation.maxlength', { max: 100 }) },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="lowerAmountLabel" for="employee-program-tax-range-lowerAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.lowerAmount">Lower Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-program-tax-range-lowerAmount"
                                        type="text"
                                        name="lowerAmount"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 100, errorMessage: translate('entity.validation.max', { max: 100 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="upperAmountLabel" for="employee-program-tax-range-upperAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.upperAmount">Upper Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-program-tax-range-upperAmount"
                                        type="text"
                                        name="upperAmount"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 100, errorMessage: translate('entity.validation.max', { max: 100 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="taxAmountLabel" for="employee-program-tax-range-taxAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.taxAmount">Tax Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-program-tax-range-taxAmount"
                                        type="text"
                                        name="taxAmount"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 100, errorMessage: translate('entity.validation.max', { max: 100 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="activeLabel" check>
                                        <AvInput id="employee-program-tax-range-active" type="checkbox" className="form-control" name="active" />
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.active">Active</Translate>
                                    </Label>
                                </AvGroup>
                                <AvGroup>
                                    <Label id="modeLabel" for="employee-program-tax-range-mode">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.mode">Mode</Translate>
                                    </Label>
                                    <AvInput id="employee-program-tax-range-mode" type="select" className="form-control" name="mode" value={(!isNew && employeeProgramTaxRangeEntity.mode) || 'LOWER_INCLUSIVE_UPPER_INCLUSIVE'}>
                                        <option value="LOWER_INCLUSIVE_UPPER_INCLUSIVE">{translate('giroEmployeeSalaryAdvanceApp.TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_INCLUSIVE')}</option>
                                        <option value="LOWER_EXCLUSIVE_UPPER_INCLUSIVE">{translate('giroEmployeeSalaryAdvanceApp.TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_INCLUSIVE')}</option>
                                        <option value="LOWER_INCLUSIVE_UPPER_EXCLUSIVE">{translate('giroEmployeeSalaryAdvanceApp.TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_EXCLUSIVE')}</option>
                                        <option value="LOWER_EXCLUSIVE_UPPER_EXCLUSIVE">{translate('giroEmployeeSalaryAdvanceApp.TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_EXCLUSIVE')}</option>
                                    </AvInput>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="employee-program-tax-range-program">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgramTaxRange.program">Program</Translate>
                                    </Label>
                                    <AvInput
                                        id="employee-program-tax-range-program"
                                        type="select"
                                        className="form-control"
                                        name="program.id"
                                        value={isNew ? employeePrograms[0] && employeePrograms[0].id : employeeProgramTaxRangeEntity.program.id}
                                        required>
                                        {employeePrograms
                                            ? employeePrograms.map(otherEntity => (
                                                  <option value={otherEntity.id} key={otherEntity.id}>
                                                      {otherEntity.name}
                                                  </option>
                                              ))
                                            : null}
                                    </AvInput>
                                    <AvFeedback>
                                        <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                    </AvFeedback>
                                </AvGroup>
                                <Button tag={Link} id="cancel-save" to="/entity/employee-program-tax-range" replace color="info">
                                    <FontAwesomeIcon icon="arrow-left" />
                                    &nbsp;
                                    <span className="d-none d-md-inline">
                                        <Translate contentKey="entity.action.back">Back</Translate>
                                    </span>
                                </Button>
                                &nbsp;
                                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                    <FontAwesomeIcon icon="save" />
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Save</Translate>
                                </Button>
                            </AvForm>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    employeePrograms: storeState.employeeProgram.entities,
    employeeProgramTaxRangeEntity: storeState.employeeProgramTaxRange.entity,
    loading: storeState.employeeProgramTaxRange.loading,
    updating: storeState.employeeProgramTaxRange.updating,
    updateSuccess: storeState.employeeProgramTaxRange.updateSuccess,
});

const mapDispatchToProps = {
    getEmployeePrograms,
    getEntity,
    updateEntity,
    createEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgramTaxRangeUpdate);
