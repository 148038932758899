import { Card } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import './home.scss';

export type IHomeLoggedContainerProps = StateProps;

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export const HomeLoggedContainer = (props: IHomeLoggedContainerProps) => {
    const { account } = props;

    return (
        <Container>
            <Alert color="success">
                <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                    You are logged in as user {account.login}.
                </Translate>
            </Alert>

            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <Card>
                        <List>
                            <ListItemLink href="/entity/company-employee/upload">
                                <ListItemText primary={translate(`giroEmployeeSalaryAdvanceApp.companyEmployee.home.uploadLabel`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/employee-time-entry/upload">
                                <ListItemText primary={translate(`giroEmployeeSalaryAdvanceApp.employeeTimeEntry.home.uploadLabel`)} />
                            </ListItemLink>
                            <Divider />

                            <ListItemLink href="/entity/organization">
                                <ListItemText primary={translate(`global.menu.entities.organization`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/company">
                                <ListItemText primary={translate(`global.menu.entities.company`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/person">
                                <ListItemText primary={translate(`global.menu.entities.person`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/employee-program">
                                <ListItemText primary={translate(`global.menu.entities.employeeProgram`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/employee-program-financial">
                                <ListItemText primary={translate(`global.menu.entities.employeeProgramFinancial`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/company-employee">
                                <ListItemText primary={translate(`global.menu.entities.companyEmployee`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/employee-monthly-credit">
                                <ListItemText primary={translate(`global.menu.entities.employeeMonthlyCredit`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/employee-time-entry">
                                <ListItemText primary={translate(`global.menu.entities.employeeTimeEntry`)} />
                            </ListItemLink>
                            <ListItemLink href="/entity/employee-anticipation">
                                <ListItemText primary={translate(`global.menu.entities.employeeAnticipation`)} />
                            </ListItemLink>
                        </List>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = storeState => ({
    account: storeState.authentication.account,
    isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(HomeLoggedContainer);
