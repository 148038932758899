import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { HttpRequestStatus } from 'app/shared/model/enumerations/http-request-status';
import { ImporterSeparator, SEPARATOR_TO_CHAR_RECORD } from 'app/shared/model/enumerations/importer-separator';
import { IOrganization } from 'app/shared/model/organization.model';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Axios from 'axios';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Label, Row } from 'reactstrap';
import { getOrganizations } from '../code-base/organization/organization.reducer';
import { getEntity } from './employee-time-entry.reducer';

export interface IEmployeeTimeEntryUploadProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}
export interface IEmployeeTimeEntryUploadState {
    organization?: IOrganization;
    file?: any;
    status: HttpRequestStatus;
    entity: any;
}

export class EmployeeTimeEntryUpload extends React.Component<IEmployeeTimeEntryUploadProps, IEmployeeTimeEntryUploadState> {
    constructor(props) {
        super(props);
        this.state = {
            status: HttpRequestStatus.NOOP,
            entity: {},
        };
    }

    componentDidMount() {
        this.props.getOrganizations();
    }

    handleGoBack = () => {
        this.props.history.replace('/entity/employee-time-entry');
    };

    onUploadFile = (event, errors, values) => {
        if (values.organizationId == null || values.organizationId === '') {
            toast(translate('error.upload.organization'));
            return;
        }

        if (this.state.file == null) {
            toast(translate('error.upload.file'));
            return;
        }

        const formData = new FormData();
        formData.append('file', this.state.file, this.state.file?.name);

        this.setState({
            status: HttpRequestStatus.ONGOING,
        });

        const separator_char = SEPARATOR_TO_CHAR_RECORD[values.separator ?? ImporterSeparator.SEMICOLON];

        Axios.post(`/api/employee-time-entry-import/${values.organizationId}/upload?separator=${separator_char}`, formData)
            .then(result => {
                alert('Importação finalizada!');
                this.setState({
                    status: HttpRequestStatus.SUCCESS,
                    file: null,
                    entity: {},
                });
            })
            .catch(error => {
                alert('Erro ao importar');
                this.setState({
                    ...this.state,
                    status: HttpRequestStatus.ERROR,
                });
            });
    };

    fileChangedHandler = event => {
        this.setState({ file: event.target.files[0] });
    };

    render() {
        const { organizations } = this.props;
        const { status, entity } = this.state;

        if (status === HttpRequestStatus.ONGOING) {
            return (
                <Row style={{ marginTop: '100px' }}>
                    <Col>
                        <Loading />
                    </Col>
                </Row>
            );
        }

        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.upload.title">upload</Translate>
                    </h2>
                    <div style={{ borderBottom: 'solid 1px #e3e3e3' }}>
                        <AvForm model={entity} onSubmit={this.onUploadFile}>
                            <AvGroup>
                                <AvInput type="file" name="file" onChange={this.fileChangedHandler} required accept="text/csv" />
                                <AvFeedback>
                                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                </AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="company-employee-company">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.organization">Company</Translate>
                                </Label>
                                <AvInput id="company-employee-company" type="select" className="form-control" name="organizationId" required>
                                    <option value="" disabled key="0">
                                        {translate('error.upload.organization')}
                                    </option>
                                    {organizations
                                        ? organizations.map(otherEntity => (
                                              <option value={otherEntity.id} key={otherEntity.id}>
                                                  {otherEntity.name}
                                              </option>
                                          ))
                                        : null}
                                </AvInput>
                                <AvFeedback>
                                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                </AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label id="company-employee-separator" for="companyEmployee.separator">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.upload.separator.label">Separator</Translate>
                                </Label>
                                <AvInput id="companyEmployee.separator" type="select" className="form-control" name="separator">
                                    <option value={ImporterSeparator.SEMICOLON} selected>
                                        {translate(`giroEmployeeSalaryAdvanceApp.employeeTimeEntry.upload.separator.${ImporterSeparator.SEMICOLON}`)}
                                    </option>
                                    <option value={ImporterSeparator.COMMA}>{translate(`giroEmployeeSalaryAdvanceApp.employeeTimeEntry.upload.separator.${ImporterSeparator.COMMA}`)}</option>
                                </AvInput>
                            </AvGroup>
                            <Button tag={Link} id="home" to="/" replace color="info">
                                <span className="d-none d-md-inline">
                                    <Translate contentKey="global.menu.home">Home</Translate>
                                </span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button tag={Link} id="cancel-save" to="/entity/employee-time-entry" replace color="info">
                                <FontAwesomeIcon icon="arrow-left" />
                                &nbsp;
                                <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.back">Back</Translate>
                                </span>
                            </Button>
                            &nbsp;
                            <Button color="primary" id="save-entity" type="submit">
                                <FontAwesomeIcon icon="save" />
                                &nbsp;
                                <Translate contentKey="entity.action.save">Save</Translate>
                            </Button>
                        </AvForm>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ upload, organization }: IRootState) => ({
    file: upload.file,
    status: upload.status,
    organizations: organization.data,
});

const mapDispatchToProps = { getEntity, getOrganizations, showModal, hideModal };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimeEntryUpload);
