import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityCard } from 'app/components/card/entityCard/entity-card';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { defaultValue, IBankAccount } from 'app/shared/model/bank-account.model';
import { IRootState } from 'app/shared/reducers';
import React, { Component } from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { getBanks } from '../bank/bank.reducer';
import { BankAccountCardDetail } from '../bankaccount/bankaccount-card-detail';
import { BankAccountCardManager } from '../bankaccount/bankaccount-card-manager';
import { EditActionType } from './company-manager';
export interface ICompanyAccountsManagerProps extends StateProps, DispatchProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    isSingle?: boolean;
    getBanks: typeof getBanks;
    updateCallback?: (accounts: IBankAccount[]) => void;
    editCallback?: (action: EditActionType) => void;
}

export interface ICompanyAccountsManagerState {
    accounts: IBankAccount[];
    editing: boolean;
    hasActiveAccount: boolean;
}

type OnClickEvent = React.MouseEvent<any, MouseEvent>;

export class CompanyAccountsManager extends Component<ICompanyAccountsManagerProps, ICompanyAccountsManagerState> {
    constructor(props: Readonly<ICompanyAccountsManagerProps>) {
        super(props);
        this.state = {
            accounts: props.accounts ? props.accounts : [],
            editing: false,
            hasActiveAccount: false,
        };
    }

    handleAdd = (event: OnClickEvent): void => {
        const { accounts } = this.state;
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
                accounts: [...accounts, defaultValue],
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleRemove = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                accounts: this.state.accounts.filter((_account, i) => {
                    return i !== index;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.accounts);
                }
            }
        );
    };

    handleCancel = (index: number) => {
        if (this.state.accounts[index].isNew) {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                    accounts: this.state.accounts.filter((_account, i) => {
                        return i !== index;
                    }),
                }),
                () => {
                    if (this.props.updateCallback) {
                        this.props.updateCallback(this.state.accounts);
                    }
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        } else {
            this.setState(
                prevState => ({
                    ...prevState,
                    editing: false,
                }),
                () => {
                    if (this.props.editCallback) {
                        this.props.editCallback(EditActionType.CANCEL);
                    }
                }
            );
        }
    };

    handleEdit = () => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.START);
                }
            }
        );
    };

    handleUpdate = (index: number, update: IBankAccount): void => {
        const { accounts } = this.state;
        this.setState(prevState => ({
            ...prevState,
            accounts: accounts.map((value: IBankAccount, i: number) => {
                return i === index ? update : value;
            }),
        }));
    };

    handleSave = (index: number) => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
                accounts: prevState.accounts.map((account: IBankAccount, i: number) => {
                    return i === index ? { ...account, isNew: false } : account;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.accounts);
                }
                if (this.props.editCallback) {
                    this.props.editCallback(EditActionType.FINISH);
                }
            }
        );
    };

    render() {
        const { accounts, editing } = this.state;

        return (
            <>
                <h3 id="company-phones-heading">
                    <Translate contentKey="company.accounts">Accounts</Translate>
                    {!this.props.isSingle || (this.props.isSingle && accounts.length === 0) ? (
                        <Button color="primary" className="float-right" type="button" disabled={editing} onClick={this.handleAdd}>
                            <FontAwesomeIcon icon="plus" />
                            <Translate contentKey="bankAccounts.createLabel">Create a new bank account</Translate>
                        </Button>
                    ) : (
                        ''
                    )}
                </h3>
                <div className="accounts-manager">
                    {accounts && accounts.length > 0 ? (
                        accounts.map((account, i) => (
                            <EntityCard
                                key={i}
                                entity={account}
                                editable={!editing}
                                editCallback={() => this.handleEdit()}
                                removeCallback={() => this.handleRemove(i)}
                                saveCallback={() => this.handleSave(i)}
                                cancelCallback={() => this.handleCancel(i)}>
                                {{
                                    manager: <BankAccountCardManager account={account} updateCallback={it => this.handleUpdate(i, it)} showModal={this.props.showModal} hideModal={this.props.hideModal} getBanks={this.props.getBanks} />,
                                    detail: <BankAccountCardDetail account={account} />,
                                }}
                            </EntityCard>
                        ))
                    ) : (
                        <p>
                            <Translate contentKey="companyManager.empty.accounts">No registered bank account</Translate>
                        </p>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    accounts: storeState.bankAccount.entities,
});

const mapDispatchToProps = {
    getBanks,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAccountsManager);
