import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { Button, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import './employee-anticipation-modal-filter.scss';

export interface EmployeeAnticipationModalFilterParameters {
    id: string;
    requestedDate: string;
    employee: string;
    status: string;
}

export const defaultEmployeeAnticipationModalFilterParameters: Readonly<EmployeeAnticipationModalFilterParameters> = {
    id: '',
    requestedDate: '',
    employee: '',
    status: '',
};

interface EmployeeAnticipationModalFilterProps {
    showModal: boolean;
    handleClose: () => void;
    filterParameters: EmployeeAnticipationModalFilterParameters;
    handleSetFilterParameters: (value: EmployeeAnticipationModalFilterParameters) => void;
    getEntities: () => void;
}

export class EmployeeAnticipationModalFilter extends React.Component<EmployeeAnticipationModalFilterProps> {
    saveEntity = (event, errors, values) => {
        this.props.handleSetFilterParameters(values);
        this.props.handleClose();
        this.props.getEntities();
    };

    render() {
        const { showModal, handleClose, filterParameters } = this.props;

        return (
            <Modal isOpen={showModal} toggle={handleClose} className="modal-filter">
                <ModalHeader toggle={handleClose}>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.filterLabel">Filter Company Employee</Translate>
                </ModalHeader>
                <ModalBody>
                    <AvForm model={filterParameters} onSubmit={this.saveEntity}>
                        <AvGroup>
                            <Label for="employee-anticipation-id">
                                <Translate contentKey="global.field.id">ID</Translate>
                            </Label>
                            <AvInput id="employee-anticipation-id" type="text" className="form-control" name="id" />
                        </AvGroup>
                        <AvGroup>
                            <Label for="employee-anticipation-requestedDate">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.requestedDate"> Requested Date </Translate>
                            </Label>
                            <AvInput id="employee-anticipation-requestedDate" type="date" className="form-control" name="requestedDate" />
                        </AvGroup>
                        <AvGroup>
                            <Label for="employee-anticipation-employee">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.employee"> Employee </Translate>
                            </Label>
                            <AvInput id="employee-anticipation-employee" type="text" className="form-control" name="employee" />
                        </AvGroup>
                        <AvGroup>
                            <Label for="employee-anticipation-status">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.status"> Status </Translate>
                            </Label>
                            <AvInput id="employee-anticipation-status" type="select" className="form-control" name="status">
                                <option value={undefined}></option>
                                <option value="NEW">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.NEW')}</option>
                                <option value="PENDING">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.PENDING')}</option>
                                <option value="REJECTED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.REJECTED')}</option>
                                <option value="PROCESSED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.PROCESSED')}</option>
                                <option value="ERROR">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.ERROR')}</option>
                                <option value="EXPIRED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.EXPIRED')}</option>
                            </AvInput>
                        </AvGroup>
                        <div className="modal-filter__button">
                            <Button color="primary" id="search-entity" type="submit">
                                <FontAwesomeIcon icon="search" />
                                &nbsp;
                                <Translate contentKey="entity.action.filter">Filter</Translate>
                            </Button>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
        );
    }
}
