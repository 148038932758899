import { IFile } from './file.model';

export interface ISessionTerm {
    id?: number;
    file?: IFile;
    status?: SessionTermStatus;
}

export enum SessionTermStatus {
    GENERATING = 'GENERATING',
    CREATED = 'CREATED',
    WAITING_SUBSCRIPTION = 'WAITING_SUBSCRIPTION',
    SIGNED = 'SIGNED',
    ERROR = 'ERROR',
    CLOSED = 'CLOSED',
}
export const defaultValue: Readonly<ISessionTerm> = {};
