import { defaultValue, IEmployeeMonthlyCredit } from 'app/shared/model/employee-monthly-credit.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
    FETCH_EMPLOYEEMONTHLYCREDIT_LIST: 'employeeMonthlyCredit/FETCH_EMPLOYEEMONTHLYCREDIT_LIST',
    FETCH_EMPLOYEEMONTHLYCREDIT: 'employeeMonthlyCredit/FETCH_EMPLOYEEMONTHLYCREDIT',
    CREATE_EMPLOYEEMONTHLYCREDIT: 'employeeMonthlyCredit/CREATE_EMPLOYEEMONTHLYCREDIT',
    UPDATE_EMPLOYEEMONTHLYCREDIT: 'employeeMonthlyCredit/UPDATE_EMPLOYEEMONTHLYCREDIT',
    DELETE_EMPLOYEEMONTHLYCREDIT: 'employeeMonthlyCredit/DELETE_EMPLOYEEMONTHLYCREDIT',
    RESET: 'employeeMonthlyCredit/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IEmployeeMonthlyCredit>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type EmployeeMonthlyCreditState = Readonly<typeof initialState>;

// Reducer

export default (state: EmployeeMonthlyCreditState = initialState, action): EmployeeMonthlyCreditState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT_LIST):
        case REQUEST(ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_EMPLOYEEMONTHLYCREDIT):
        case REQUEST(ACTION_TYPES.UPDATE_EMPLOYEEMONTHLYCREDIT):
        case REQUEST(ACTION_TYPES.DELETE_EMPLOYEEMONTHLYCREDIT):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT_LIST):
        case FAILURE(ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT):
        case FAILURE(ACTION_TYPES.CREATE_EMPLOYEEMONTHLYCREDIT):
        case FAILURE(ACTION_TYPES.UPDATE_EMPLOYEEMONTHLYCREDIT):
        case FAILURE(ACTION_TYPES.DELETE_EMPLOYEEMONTHLYCREDIT):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_EMPLOYEEMONTHLYCREDIT):
        case SUCCESS(ACTION_TYPES.UPDATE_EMPLOYEEMONTHLYCREDIT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_EMPLOYEEMONTHLYCREDIT):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/employee-monthly-credits';

// Actions

export const getEntities: ICrudGetAllAction<IEmployeeMonthlyCredit> = (page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    return {
        type: ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT_LIST,
        payload: axios.get<IEmployeeMonthlyCredit>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
    };
};

export const getEntity: ICrudGetAction<IEmployeeMonthlyCredit> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_EMPLOYEEMONTHLYCREDIT,
        payload: axios.get<IEmployeeMonthlyCredit>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IEmployeeMonthlyCredit> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_EMPLOYEEMONTHLYCREDIT,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const updateEntity: ICrudPutAction<IEmployeeMonthlyCredit> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EMPLOYEEMONTHLYCREDIT,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const deleteEntity: ICrudDeleteAction<IEmployeeMonthlyCredit> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_EMPLOYEEMONTHLYCREDIT,
        payload: axios.delete(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
