import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { deleteEntity, getEntity } from './employee-anticipation.reducer';

export interface IEmployeeAnticipationDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class EmployeeAnticipationDeleteDialog extends React.Component<IEmployeeAnticipationDeleteDialogProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    confirmDelete = event => {
        // this.props.deleteEntity(this.props.employeeAnticipationEntity.id);
        this.handleClose(event);
    };

    handleClose = event => {
        event.stopPropagation();
        this.props.history.goBack();
    };

    render() {
        const { employeeAnticipationEntity } = this.props;
        return (
            <Modal isOpen toggle={this.handleClose}>
                <ModalHeader toggle={this.handleClose}>
                    <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
                </ModalHeader>
                <ModalBody id="giroEmployeeSalaryAdvanceApp.employeeAnticipation.delete.question">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.delete.question" interpolate={{ id: employeeAnticipationEntity.id }}>
                        Are you sure you want to delete this EmployeeAnticipation?
                    </Translate>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>
                        <FontAwesomeIcon icon="ban" />
                        &nbsp;
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                    <Button id="jhi-confirm-delete-employeeAnticipation" color="danger" onClick={this.confirmDelete}>
                        <FontAwesomeIcon icon="trash" />
                        &nbsp;
                        <Translate contentKey="entity.action.ok">OK</Translate>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = ({ employeeAnticipation }: IRootState) => ({
    employeeAnticipationEntity: employeeAnticipation.entity,
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAnticipationDeleteDialog);
