import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICardEdition } from 'app/shared/model/card-edition';
import { OnClickEvent } from 'app/util/types';
import { AvForm } from 'availity-reactstrap-validation';
import React, { Component, ReactNode } from 'react';
import { Translate } from 'react-jhipster';
import { Button, Card, CardBody } from 'reactstrap';
import './entity-card.scss';

library.add(fas);

export interface IEntityCardProps<T> {
    entity: T;
    editable?: boolean;
    removeCallback?: () => void;
    editCallback?: () => void;
    saveCallback?: () => void;
    cancelCallback?: () => void;
    isRemoveable?: boolean;
    children: {
        manager: ReactNode;
        detail: ReactNode;
    };
}

export interface IEntityCardState<T> {
    editing: boolean;
    entity: T;
}

export class EntityCard<T extends ICardEdition> extends Component<IEntityCardProps<T>, IEntityCardState<T>> {
    constructor(props: Readonly<IEntityCardProps<T>>) {
        super(props);
        this.state = {
            editing: props.entity.isNew,
            entity: props.entity,
        };
    }

    handleEdit = (event: OnClickEvent): void => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: true,
            }),
            () => {
                if (this.props.editCallback) {
                    this.props.editCallback();
                }
            }
        );
        event.preventDefault();
    };

    handleRemove = (event: OnClickEvent): void => {
        if (this.props.removeCallback) {
            this.props.removeCallback();
        }
        event.preventDefault();
    };

    handleSave = (event: OnClickEvent): void => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
            }),
            () => {
                if (this.props.saveCallback) {
                    this.props.saveCallback();
                }
            }
        );
        event.preventDefault();
    };

    handleCancel = (event: OnClickEvent): void => {
        this.setState(
            prevState => ({
                ...prevState,
                editing: false,
            }),
            () => {
                if (this.props.cancelCallback) {
                    this.props.cancelCallback();
                }
            }
        );
        event.preventDefault();
    };

    render() {
        const { editing } = this.state;
        const { editable, isRemoveable = true } = this.props;
        const { manager, detail } = this.props.children;

        return (
            <>
                <Card className="entity-card" border="secondary">
                    {editing ? (
                        <AvForm onValidSubmit={this.handleSave}>
                            <CardBody>
                                {manager ? <>{manager}</> : null}
                                <div className="edit-options">
                                    <Button color="primary" type="submit" size="sm">
                                        <FontAwesomeIcon icon="save" /> <Translate contentKey="entity.action.save">Save</Translate>
                                    </Button>{' '}
                                    <Button color="info" type="button" size="sm" onClick={this.handleCancel}>
                                        <FontAwesomeIcon icon={['fas', 'times']} /> <Translate contentKey="entity.action.cancel">Cancel</Translate>
                                    </Button>
                                </div>
                            </CardBody>
                        </AvForm>
                    ) : (
                        <CardBody>
                            <div className="float-right">
                                <Button color="info" size="sm" disabled={!editable} onClick={this.handleEdit}>
                                    <FontAwesomeIcon icon="pencil-alt" />
                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                </Button>{' '}
                                {isRemoveable && (
                                    <Button color="danger" size="sm" onClick={this.handleRemove}>
                                        <FontAwesomeIcon icon="trash" />
                                        <Translate contentKey="entity.action.delete">Delete</Translate>
                                    </Button>
                                )}
                            </div>
                            {detail ? <>{detail}</> : null}
                        </CardBody>
                    )}
                </Card>
            </>
        );
    }
}
