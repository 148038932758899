import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getEntity, inviteEmployee } from './company-employee.reducer';

export interface ICompanyEmployeeInviteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CompanyEmployeeInviteDialog extends React.Component<ICompanyEmployeeInviteDialogProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    confirmInvite = async event => {
        const result = await this.props.inviteEmployee(this.props.companyEmployeeEntity.id.toString());
        if ((await result.payload).status === 200) {
            alert('Convite enviado');
        } else {
            alert('Erro ao enviar convite');
        }
        this.handleClose(event);
    };

    handleClose = event => {
        event.stopPropagation();
        this.props.history.goBack();
    };

    render() {
        const { companyEmployeeEntity } = this.props;
        return (
            <Modal isOpen toggle={this.handleClose}>
                <ModalHeader toggle={this.handleClose}>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.invite.title">Confirm delete operation</Translate>
                </ModalHeader>
                <ModalBody id="giroEmployeeSalaryAdvanceApp.companyEmployee.invite.question">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.invite.question" interpolate={{ id: companyEmployeeEntity.id }}>
                        Send invite e-mail
                    </Translate>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>
                        <FontAwesomeIcon icon="ban" />
                        &nbsp;
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                    <Button id="jhi-confirm-delete-companyEmployee" color="primary" onClick={this.confirmInvite}>
                        <FontAwesomeIcon icon="save" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.invite.send">Invite</Translate>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = ({ companyEmployee }: IRootState) => ({
    companyEmployeeEntity: companyEmployee.entity,
});

const mapDispatchToProps = { getEntity, inviteEmployee };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmployeeInviteDialog);
