import { IBankAccount } from 'app/shared/model/bank-account.model';
import { ICompany } from 'app/shared/model/company.model';
import { IOrganization } from 'app/shared/model/organization.model';
import { IPerson } from 'app/shared/model/person.model';
import moment, { Moment } from 'moment';
import { IAddress } from './address.model';
import { ICardEdition } from './card-edition';
import { IEmployeeProgram } from './employee-program.model';
import { DayOfWeek } from './enumerations/day-of-week';
import { IPhone } from './phone.model';
import { IPix } from './pix.model';
import { ISessionTerm } from './session-term.model';

export interface ICompanyEmployee extends ICardEdition {
    id?: number;
    active?: boolean;
    dailyWorkload?: string;
    monthlyWorkload?: string;
    hourlyRate?: number;
    salary?: number;
    identificationNumber?: string;
    canAnticipate?: boolean;
    contractStartDate?: Moment;
    contractEndDate?: Moment;
    workdays?: DayOfWeek[];
    organization?: IOrganization;
    person?: IPerson;
    assignmentTerm?: ISessionTerm;
    assignmentTermTemplate?: ISessionTerm;
    info?: ICompanyEmployeePersonalInfo;
    company?: ICompany;
    bankAccount?: IBankAccount;
    program?: IEmployeeProgram;
    pixList?: IPix[];
}

export interface ICompanyEmployeePersonalInfo {
    maritalStatus?: MaritalStatus;
    address?: IAddress;
    mainPhoneNumber?: IPhone;
    nationality?: string;
    profession?: string;
}

export enum MaritalStatus {
    SINGLE = 'SINGLE',
    MARRIED = 'MARRIED',
    SEPARATED = 'SEPARATED',
    DIVORCED = 'DIVORCED',
    WIDOWED = 'WIDOWED',
}

export const defaultValue: ICompanyEmployee = {
    active: false,
    isNew: true,
    canAnticipate: false,
    info: {},
};

export const writeToISODuration = (employee: ICompanyEmployee): void => {
    if (employee.dailyWorkload != null) {
        employee.dailyWorkload = moment(employee.dailyWorkload, 'HH:mm').format('[PT]H[H]m[M]');
    }
    if (employee.monthlyWorkload != null && employee.monthlyWorkload.includes(':')) {
        const hours = employee.monthlyWorkload.split(':');
        employee.monthlyWorkload = `PT${hours[0]}H${hours.length > 1 ? hours[1] + 'M' : ''}`;
    }
};

export const readDailyDurationFromISODuration = (entry: ICompanyEmployee): string => {
    if (entry.dailyWorkload != null) {
        return moment(entry.dailyWorkload, '[PT]H[H]m[M]').format('HH:mm');
    }
    return entry.dailyWorkload;
};

export const readMonthlyDurationFromISODuration = (entry: ICompanyEmployee): string => {
    if (entry.dailyWorkload != null) {
        return entry.monthlyWorkload.replace('PT', '').replace('H', ':');
    }
    return entry.monthlyWorkload;
};

export const readFromISODuration = (employee: ICompanyEmployee): void => {
    if (employee.dailyWorkload != null) {
        employee.dailyWorkload = moment(employee.dailyWorkload).format('HH:mm');
    }
    if (employee.monthlyWorkload != null) {
        employee.monthlyWorkload = moment(employee.monthlyWorkload).format('HH:mm');
    }
};
