import { defaultValue, IAddress } from 'app/shared/model/address.model';

const initialState = {
    data: [] as IAddress[],
    address: defaultValue,
};

export type AddressState = Readonly<typeof initialState>;

// Reducer
export default (state: AddressState = initialState, action): AddressState => {
    return state;
};
