import { IBankAccount } from 'app/shared/model/bank-account.model';
import { BankAccountType } from 'app/shared/model/enumerations/bank-account-type.model';
import StringUtils from 'app/util/StringUtils';
import React from 'react';
import { Translate } from 'react-jhipster';

export interface IBankAccountDetailProps {
    account: IBankAccount;
}

const FILTER_NON_BLANK = (it: string) => it !== null && it !== undefined && it?.trim() !== '';

export const BankAccountDetail = (props: IBankAccountDetailProps): JSX.Element => {
    const { account: bankAccount } = props;
    return (
        <>
            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.bank">Bank</Translate>
                </dt>
                <dd>{[bankAccount.bank?.number, bankAccount.bank?.name].filter(FILTER_NON_BLANK).join('-')}</dd>
            </dl>

            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.agency">Agency</Translate>
                </dt>
                <dd>{[bankAccount.agencyNumber, bankAccount.agencyDigit].filter(FILTER_NON_BLANK).join('-')}</dd>
            </dl>

            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.account">Account</Translate>
                </dt>
                <dd>{[bankAccount.accountNumber, bankAccount.accountDigit].filter(FILTER_NON_BLANK).join('-')}</dd>
            </dl>

            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.type.label">Type</Translate>
                </dt>
                <dd>
                    {bankAccount.type &&
                        {
                            CHECKING_ACCOUNT: <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.type.CHECKING_ACCOUNT" />,
                            SAVINGS_ACCOUNT: <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.type.SAVINGS_ACCOUNT" />,
                        }[bankAccount.type]}
                </dd>
            </dl>

            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.active">Active ?</Translate>
                </dt>
                <dd>{bankAccount.active ? <Translate contentKey="global.yes">Yes</Translate> : <Translate contentKey="global.no">No</Translate>}</dd>
            </dl>

            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.company-itself">Favored is the company itself?</Translate>
                </dt>
                <dd>{bankAccount.favoredCompanyItself ? <Translate contentKey="global.yes">Yes</Translate> : <Translate contentKey="global.no">No</Translate>}</dd>
            </dl>

            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.name">Favored name</Translate>
                </dt>
                <dd>{bankAccount.favoredName}</dd>
            </dl>

            <dl>
                <dt>
                    {bankAccount.favoredType ? (
                        {
                            INDIVIDUAL: <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.identification.INDIVIDUAL" />,
                            CORPORATION: <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.identification.CORPORATION" />,
                        }[bankAccount.favoredType]
                    ) : (
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.identification.label" />
                    )}
                </dt>
                <dd>
                    {bankAccount.favoredType
                        ? {
                              INDIVIDUAL: StringUtils.addCpfMask(bankAccount.favoredIdentification),
                              CORPORATION: StringUtils.addCnpjMask(bankAccount.favoredIdentification),
                          }[bankAccount.favoredType]
                        : bankAccount.favoredIdentification}
                </dd>
            </dl>

            <dl>
                <dt>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.type.label">Favored type</Translate>
                </dt>
                <dd>
                    {bankAccount.favoredType &&
                        {
                            INDIVIDUAL: <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.type.INDIVIDUAL" />,
                            CORPORATION: <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.favored.type.CORPORATION" />,
                        }[bankAccount.favoredType]}
                </dd>
            </dl>

            {bankAccount.observation && (
                <dl>
                    <dt>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.observation">Observation</Translate>
                    </dt>
                    <dd>{bankAccount.observation}</dd>
                </dl>
            )}
            {bankAccount.type === BankAccountType.SAVINGS_ACCOUNT && bankAccount.variation && (
                <dl>
                    <dt>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.variation">Variation</Translate>
                    </dt>
                    <dd>{bankAccount.variation}</dd>
                </dl>
            )}
        </>
    );
};

export default BankAccountDetail;
