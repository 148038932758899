export enum Authority {
    ADMIN = 'ROLE_ADMIN',
    USER = 'ROLE_USER',
    ANONYMOUS = 'ROLE_ANONYMOUS',
    FINANCIAL = 'ROLE_FINANCIAL',
    PROVIDER = 'ROLE_PROVIDER',
    CLIENT = 'ROLE_CLIENT',
    EMPLOYEE = 'ROLE_EMPLOYEE',
    COMPANY = 'ROLE_COMPANY',
}
