import { IPix } from 'app/shared/model/pix.model';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

export interface IPixCardDetailProps {
    pix: IPix;
}

export const PixCardDetail = (props: IPixCardDetailProps): JSX.Element => {
    const { pix } = props;
    return (
        <>
            <Row>
                <Col md="12">
                    <dl>
                        <dt>
                            <Translate contentKey="pix.key"> Pix Key </Translate>
                        </dt>
                        <dd>{pix.key ? pix.key : ''}</dd>
                    </dl>
                </Col>
                <Col md="12">
                    <dl>
                        <dt>
                            <Translate contentKey="pix.keyType"> Key Type </Translate>
                        </dt>
                        <dd>{pix.keyType ? translate(`pixKeyType.${pix.keyType}`) : ''}</dd>
                    </dl>
                </Col>
            </Row>
        </>
    );
};
