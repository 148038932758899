import { IPhone } from 'app/shared/model/phone.model';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

export interface PhoneCardDetailProps {
    phone: IPhone;
}

export const PhoneCardDetail = (props: PhoneCardDetailProps): JSX.Element => {
    const { phone } = props;
    return (
        <>
            <Row>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="phone.type">Type</Translate>
                        </dt>
                        <dd>
                            {phone.type &&
                                {
                                    HOME: translate('phoneType.HOME'),
                                    PROFESSIONAL: translate('phoneType.PROFESSIONAL'),
                                    CELLULAR: translate('phoneType.CELLULAR'),
                                    OTHER: translate('phoneType.OTHER'),
                                }[phone.type]}
                        </dd>
                    </dl>
                </Col>
                <Col md="8">
                    <dl>
                        <dt>
                            <Translate contentKey="phone.number">Number</Translate>
                        </dt>
                        <dd>{phone.number}</dd>
                    </dl>
                </Col>
            </Row>
            {phone.observation && (
                <Row>
                    <Col md="12">
                        <dl>
                            <dt>
                                <Translate contentKey="phone.observation">Observation</Translate>
                            </dt>
                            <dd>{phone.observation}</dd>
                        </dl>
                    </Col>
                </Row>
            )}
        </>
    );
};
