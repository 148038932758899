import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getEntity } from './employee-program.reducer';

export interface IEmployeeProgramDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class EmployeeProgramDetail extends React.Component<IEmployeeProgramDetailProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    render() {
        const { employeeProgramEntity } = this.props;
        return (
            <Row>
                <Col md="8">
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.detail.title">EmployeeProgram</Translate> [<b>{employeeProgramEntity.id}</b>]
                    </h2>
                    <dl className="jh-entity-details">
                        <dt>
                            <span id="name">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.name">Name</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.name}</dd>
                        <dt>
                            <span id="maxAnticipationSalaryPercent">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.maxAnticipationSalaryPercent">Max Salary Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.maxAnticipationSalaryPercent}</dd>
                        <dt>
                            <span id="maxAnticipationAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.maxAnticipationAmount">Max Salary </Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.maxAnticipationAmount}</dd>
                        <dt>
                            <span id="maxAnticipationSalaryPercent">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.maxAnticipationSalaryPercent">Max Anticipation Quantity</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.maxAnticipationSalaryPercent}</dd>
                        <dt>
                            <span id="minAnticipationAmount">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.minAnticipationAmount">Min Anticipation Amount</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.minAnticipationAmount}</dd>
                        <dt>
                            <span id="monthlyDeadlineDay">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.monthlyDeadlineDay">Monthly Deadline Day</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.monthlyDeadlineDay}</dd>
                        <dt>
                            <span id="initialEmployeeBlockedMonths">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.initialEmployeeBlockedMonths">Initial Employee Blocked Months</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.initialEmployeeBlockedMonths}</dd>
                        <dt>
                            <span id="companyRemuneration">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.companyRemuneration">Company Remuneration</Translate>
                            </span>
                        </dt>
                        <dt>
                            <span id="platformRemuneration">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.platformRemuneration">Platform Remuneration</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.platformRemuneration}</dd>
                      <dt>
                            <span id="assignmentTermTemplate">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.assignmentTermTemplate">Assignment Term Template</Translate>
                            </span>
                      </dt>
                      <dd>{employeeProgramEntity.assignmentTermTemplate}</dd>
                        <dt>
                            <span id="status">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.status">Status</Translate>
                            </span>
                        </dt>
                        <dd>{employeeProgramEntity.status}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.company">Company</Translate>
                        </dt>
                        <dd>{employeeProgramEntity.company ? employeeProgramEntity.company.id : ''}</dd>
                    </dl>
                    <Button tag={Link} to="/entity/employee-program" replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.back">Back</Translate>
                        </span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/entity/employee-program/${employeeProgramEntity.id}/edit`} replace color="primary">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ employeeProgram }: IRootState) => ({
    employeeProgramEntity: employeeProgram.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgramDetail);
