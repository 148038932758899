import { ICardEdition } from './card-edition';

export interface IEmailAddress extends ICardEdition {
    id?: string;
    email?: string;
}

export const defaultValue: Readonly<IEmailAddress> = {
    id: undefined,
    email: undefined,
    isNew: true,
};
