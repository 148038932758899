import { defaultValue, IEmployeeProgram } from 'app/shared/model/employee-program.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
    FETCH_EMPLOYEEPROGRAM_LIST: 'employeeProgram/FETCH_EMPLOYEEPROGRAM_LIST',
    FETCH_EMPLOYEEPROGRAM: 'employeeProgram/FETCH_EMPLOYEEPROGRAM',
    CREATE_EMPLOYEEPROGRAM: 'employeeProgram/CREATE_EMPLOYEEPROGRAM',
    UPDATE_EMPLOYEEPROGRAM: 'employeeProgram/UPDATE_EMPLOYEEPROGRAM',
    DELETE_EMPLOYEEPROGRAM: 'employeeProgram/DELETE_EMPLOYEEPROGRAM',
    RESET: 'employeeProgram/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<IEmployeeProgram>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type EmployeeProgramState = Readonly<typeof initialState>;

// Reducer

export default (state: EmployeeProgramState = initialState, action): EmployeeProgramState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_EMPLOYEEPROGRAM_LIST):
        case REQUEST(ACTION_TYPES.FETCH_EMPLOYEEPROGRAM):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_EMPLOYEEPROGRAM):
        case REQUEST(ACTION_TYPES.UPDATE_EMPLOYEEPROGRAM):
        case REQUEST(ACTION_TYPES.DELETE_EMPLOYEEPROGRAM):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_EMPLOYEEPROGRAM_LIST):
        case FAILURE(ACTION_TYPES.FETCH_EMPLOYEEPROGRAM):
        case FAILURE(ACTION_TYPES.CREATE_EMPLOYEEPROGRAM):
        case FAILURE(ACTION_TYPES.UPDATE_EMPLOYEEPROGRAM):
        case FAILURE(ACTION_TYPES.DELETE_EMPLOYEEPROGRAM):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEEPROGRAM_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_EMPLOYEEPROGRAM):
            return {
                ...state,
                loading: false,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_EMPLOYEEPROGRAM):
        case SUCCESS(ACTION_TYPES.UPDATE_EMPLOYEEPROGRAM):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_EMPLOYEEPROGRAM):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/employee-programs';

// Actions

export const getEntities: ICrudGetAllAction<IEmployeeProgram> = (page, size, sort) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    return {
        type: ACTION_TYPES.FETCH_EMPLOYEEPROGRAM_LIST,
        payload: axios.get<IEmployeeProgram>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
    };
};

export const getEntity: ICrudGetAction<IEmployeeProgram> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_EMPLOYEEPROGRAM,
        payload: axios.get<IEmployeeProgram>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<IEmployeeProgram> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_EMPLOYEEPROGRAM,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const updateEntity: ICrudPutAction<IEmployeeProgram> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_EMPLOYEEPROGRAM,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const deleteEntity: ICrudDeleteAction<IEmployeeProgram> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_EMPLOYEEPROGRAM,
        payload: axios.delete(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
