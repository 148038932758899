import { SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';

export const ACTION_TYPES = {
    GET_PROFILE: 'applicationProfile/GET_PROFILE',
    HIDE_HEADER: 'applicationProfile/HIDE_HEADER',
    SHOW_HEADER: 'applicationProfile/SHOW_HEADER',
};

const initialState = {
    ribbonEnv: '',
    inProduction: true,
    isSwaggerEnabled: false,
    hideHeader: false,
};

export type ApplicationProfileState = Readonly<typeof initialState>;

export default (state: ApplicationProfileState = initialState, action): ApplicationProfileState => {
    switch (action.type) {
        case SUCCESS(ACTION_TYPES.GET_PROFILE): {
            const { data } = action.payload;
            return {
                ...state,
                ribbonEnv: data['display-ribbon-on-profiles'],
                inProduction: data.activeProfiles.includes('prod'),
                isSwaggerEnabled: data.activeProfiles.includes('swagger'),
            };
        }
        case ACTION_TYPES.HIDE_HEADER:
            return {
                ...state,
                hideHeader: true,
            };
        case ACTION_TYPES.SHOW_HEADER:
            return {
                ...state,
                hideHeader: false,
            };
        default:
            return state;
    }
};

export const getProfile = () => ({
    type: ACTION_TYPES.GET_PROFILE,
    payload: axios.get('management/info'),
});

export const hideHeader = () => ({
    type: ACTION_TYPES.HIDE_HEADER,
});

export const showHeader = () => ({
    type: ACTION_TYPES.SHOW_HEADER,
});
