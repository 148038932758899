import { defaultValue, ICompanyEmployee } from 'app/shared/model/company-employee.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayload, IPayloadResult } from 'react-jhipster';
import { CompanyEmployeeModalFilterParameters } from './components/company-employee-modal-filter/company-employee-modal-filter';

export const ACTION_TYPES = {
    FETCH_COMPANYEMPLOYEE_LIST: 'companyEmployee/FETCH_COMPANYEMPLOYEE_LIST',
    FETCH_COMPANYEMPLOYEE: 'companyEmployee/FETCH_COMPANYEMPLOYEE',
    CREATE_COMPANYEMPLOYEE: 'companyEmployee/CREATE_COMPANYEMPLOYEE',
    UPDATE_COMPANYEMPLOYEE: 'companyEmployee/UPDATE_COMPANYEMPLOYEE',
    DELETE_COMPANYEMPLOYEE: 'companyEmployee/DELETE_COMPANYEMPLOYEE',
    ACTIVATE_COMPANYEMPOYEE: 'companyEmployee/ACTIVATE_COMPANYEMPOYEE',
    INACTIVATE_COMPANYEMPOYEE: 'companyEmployee/INACTIVATE_COMPANYEMPOYEE',
    RESET: 'companyEmployee/RESET',
};

const initialState = {
    loading: false,
    fetching: false,
    errorMessage: null,
    entities: [] as ReadonlyArray<ICompanyEmployee>,
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

export type CompanyEmployeeState = Readonly<typeof initialState>;

// Reducer

export default (state: CompanyEmployeeState = initialState, action): CompanyEmployeeState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_COMPANYEMPLOYEE_LIST):
        case REQUEST(ACTION_TYPES.FETCH_COMPANYEMPLOYEE):
            return {
                ...state,
                errorMessage: null,
                fetching: false,
                updateSuccess: false,
                loading: true,
            };
        case REQUEST(ACTION_TYPES.CREATE_COMPANYEMPLOYEE):
        case REQUEST(ACTION_TYPES.UPDATE_COMPANYEMPLOYEE):
        case REQUEST(ACTION_TYPES.ACTIVATE_COMPANYEMPOYEE):
        case REQUEST(ACTION_TYPES.INACTIVATE_COMPANYEMPOYEE):
        case REQUEST(ACTION_TYPES.DELETE_COMPANYEMPLOYEE):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                updating: true,
                fetching: false,
            };
        case FAILURE(ACTION_TYPES.FETCH_COMPANYEMPLOYEE_LIST):
        case FAILURE(ACTION_TYPES.FETCH_COMPANYEMPLOYEE):
        case FAILURE(ACTION_TYPES.CREATE_COMPANYEMPLOYEE):
        case FAILURE(ACTION_TYPES.UPDATE_COMPANYEMPLOYEE):
        case FAILURE(ACTION_TYPES.ACTIVATE_COMPANYEMPOYEE):
        case FAILURE(ACTION_TYPES.INACTIVATE_COMPANYEMPOYEE):
        case FAILURE(ACTION_TYPES.DELETE_COMPANYEMPLOYEE):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                fetching: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_COMPANYEMPLOYEE_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data.content,
                fetching: false,
                totalItems: parseInt(action.payload.headers['x-total-count'], 10),
            };
        case SUCCESS(ACTION_TYPES.FETCH_COMPANYEMPLOYEE):
            return {
                ...state,
                loading: false,
                fetching: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.CREATE_COMPANYEMPLOYEE):
        case SUCCESS(ACTION_TYPES.UPDATE_COMPANYEMPLOYEE):
        case SUCCESS(ACTION_TYPES.ACTIVATE_COMPANYEMPOYEE):
        case SUCCESS(ACTION_TYPES.INACTIVATE_COMPANYEMPOYEE):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.DELETE_COMPANYEMPLOYEE):
            return {
                ...state,
                updating: false,
                updateSuccess: true,
                entity: {},
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = 'api/company-employees';
const apiUrlInvites = 'api/invites';

// Actions
export const getEntities = (page?: number, size?: number, sort?: string, filterParameters?: CompanyEmployeeModalFilterParameters): IPayload<ICompanyEmployee> => {
    const requestUrl = `${apiUrl}/filter${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}${
        filterParameters
            ? filterParameters.id
                ? `&id=${filterParameters.id}`
                : '' || filterParameters.identificationNumber
                ? `&identificationNumber=${filterParameters.identificationNumber}`
                : '' || filterParameters.person
                ? `&personName=${filterParameters.person}`
                : ''
            : ''
    }`;
    return {
        type: ACTION_TYPES.FETCH_COMPANYEMPLOYEE_LIST,
        payload: axios.get<ICompanyEmployee>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
    };
};

export const getEntity: ICrudGetAction<ICompanyEmployee> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_COMPANYEMPLOYEE,
        payload: axios.get<ICompanyEmployee>(requestUrl),
    };
};

export const inviteEmployee = (id: string) => {
    const requestUrl = `${apiUrlInvites}/company-employees/${id}`;
    return {
        type: ACTION_TYPES.FETCH_COMPANYEMPLOYEE,
        payload: axios.post<ICompanyEmployee>(requestUrl),
    };
};

export const createEntity: ICrudPutAction<ICompanyEmployee> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.CREATE_COMPANYEMPLOYEE,
        payload: axios.post(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const updateEntity: ICrudPutAction<ICompanyEmployee> = entity => async dispatch => {
    const result = await dispatch({
        type: ACTION_TYPES.UPDATE_COMPANYEMPLOYEE,
        payload: axios.put(apiUrl, cleanEntity(entity)),
    });
    dispatch(getEntities());
    return result;
};

export const activateCompanyEmployee: (id: number) => IPayload<ICompanyEmployee> | IPayloadResult<ICompanyEmployee> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}/activate`;
    const result = await dispatch({
        type: ACTION_TYPES.ACTIVATE_COMPANYEMPOYEE,
        payload: axios.patch(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const inactivateCompanyEmployee: (id: number) => IPayload<ICompanyEmployee> | IPayloadResult<ICompanyEmployee> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}/inactivate`;
    const result = await dispatch({
        type: ACTION_TYPES.INACTIVATE_COMPANYEMPOYEE,
        payload: axios.patch(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const deleteEntity: ICrudDeleteAction<ICompanyEmployee> = id => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await dispatch({
        type: ACTION_TYPES.DELETE_COMPANYEMPLOYEE,
        payload: axios.delete(requestUrl),
    });
    dispatch(getEntities());
    return result;
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
