import _ from 'lodash';

class StringUtils {
    isStringInvalid(text) {
        return _.isNil(text) || text === '';
    }

    isOnlyNumber(text: string) {
        const regex = /^[0-9]*$/;
        return regex.test(text);
    }

    convertToPercentString(n: number | string) {
        let result = '0%';

        if (n == null || n === 0 || n === '') {
            return result;
        }

        switch (typeof n) {
            case 'string':
                result = `${n}%`;
                break;
            case 'number':
                result = `${Number(n).toFixed(2)}%`;
                break;
            default:
        }
        return result;
    }

    isEmailValid(text: string) {
        if (this.isStringInvalid(text)) {
            return false;
        }

        const re = /\S+@\S+\.\S+/;
        return re.test(text);
    }

    addCnpjMask(cnpj: string): string {
        return cnpj ? cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') : cnpj;
    }

    addCpfMask(cpf: string): string {
        return cpf ? cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4') : cpf;
    }
}

export default new StringUtils();
