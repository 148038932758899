import { IBankAccount } from 'app/shared/model/bank-account.model';
import { BankAccountType } from 'app/shared/model/enumerations/bank-account-type.model';
import StringUtils from 'app/util/StringUtils';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

export interface IBankAccountCardDetailProps {
    account: IBankAccount;
}

export const BankAccountCardDetail = (props: IBankAccountCardDetailProps): JSX.Element => {
    const { account: bankAccount } = props;
    return (
        <>
            <Row>
                <Col md="12">
                    <dl>
                        <dt>
                            <Translate contentKey="bankAccount.bank">Bank</Translate>
                        </dt>
                        <dd>{bankAccount.bank ? bankAccount.bank.name : ''}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <dl>
                        <dt>
                            <Translate contentKey="bankAccount.agency">Agency</Translate>
                        </dt>
                        <dd>
                            {bankAccount.agencyNumber}-{bankAccount.agencyDigit}
                        </dd>
                    </dl>
                </Col>
                <Col md="6">
                    <dl>
                        <dt>
                            <Translate contentKey="bankAccount.account">Account</Translate>
                        </dt>
                        <dd>
                            {bankAccount.accountNumber}-{bankAccount.accountDigit}
                        </dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="bankAccount.type">Type</Translate>
                        </dt>
                        <dd>
                            {bankAccount.type &&
                                {
                                    CHECKING_ACCOUNT: <Translate contentKey="bankAccountType.CHECKING_ACCOUNT" />,
                                    SAVINGS_ACCOUNT: <Translate contentKey="bankAccountType.SAVINGS_ACCOUNT" />,
                                }[bankAccount.type]}
                        </dd>
                    </dl>
                </Col>
                <Col md="3">
                    <dl>
                        <dt>
                            <Translate contentKey="bankAccount.active">Active</Translate>?
                        </dt>
                        <dd>{bankAccount.active ? <Translate contentKey="global.yes">Yes</Translate> : <Translate contentKey="global.no">No</Translate>}</dd>
                    </dl>
                </Col>
                <Col md="5">
                    <dl>
                        <dt>
                            {' '}
                            <Translate contentKey="bankAccount.favoredCompanyItself">Favored is the company itself</Translate>?
                        </dt>
                        <dd>{bankAccount.favoredCompanyItself ? <Translate contentKey="global.yes">Yes</Translate> : <Translate contentKey="global.no">No</Translate>}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="bankAccount.favoredName">Favored name</Translate>
                        </dt>
                        <dd>{bankAccount.favoredName ?? '-'}</dd>
                    </dl>
                </Col>
                <Col md="4">
                    <dl>
                        <dt>
                            {bankAccount.favoredType ? (
                                {
                                    INDIVIDUAL: <Translate contentKey="bankAccount.favoredIdentification.cpf" />,
                                    CORPORATION: <Translate contentKey="bankAccount.favoredIdentification.cnpj" />,
                                }[bankAccount.favoredType]
                            ) : (
                                <Translate contentKey="bankAccount.favoredIdentification.label" />
                            )}
                        </dt>
                        <dd>
                            {bankAccount.favoredType
                                ? {
                                      INDIVIDUAL: StringUtils.addCpfMask(bankAccount.favoredIdentification),
                                      CORPORATION: StringUtils.addCnpjMask(bankAccount.favoredIdentification),
                                  }[bankAccount.favoredType]
                                : bankAccount.favoredIdentification}
                        </dd>
                    </dl>
                </Col>
                <Col md="4">
                    <dl>
                        <dt>
                            <Translate contentKey="bankAccount.favoredType">Favored type</Translate>
                        </dt>
                        <dd>
                            {bankAccount.favoredType &&
                                {
                                    INDIVIDUAL: <Translate contentKey="favoredType.INDIVIDUAL" />,
                                    CORPORATION: <Translate contentKey="favoredType.CORPORATION" />,
                                }[bankAccount.favoredType]}
                        </dd>
                    </dl>
                </Col>
            </Row>
            {bankAccount.observation && (
                <Row>
                    <Col md="12">
                        <dl>
                            <dt>
                                <Translate contentKey="bankAccount.observation">Observation</Translate>
                            </dt>
                            <dd>{bankAccount.observation}</dd>
                        </dl>
                    </Col>
                </Row>
            )}
            {bankAccount.type === BankAccountType.SAVINGS_ACCOUNT && bankAccount.variation && (
                <Row>
                    <Col>
                        <dl>
                            <dt>
                                <Translate contentKey="bankAccount.variation">Variation</Translate>
                            </dt>
                            <dd>{bankAccount.variation}</dd>
                        </dl>
                    </Col>
                </Row>
            )}
        </>
    );
};
