import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { getCompanies } from 'app/entities/code-base/company/company.reducer';
import { getEntities as getEmployeePrograms } from 'app/entities/employee-program/employee-program.reducer';
import { HttpRequestStatus } from 'app/shared/model/enumerations/http-request-status';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import React from 'react';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';

export interface IEmployeeAnticipationReportProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export interface IEmployeeAnticipationReportState {
    status: HttpRequestStatus;
    entity: any;
}

const MONTH_NAMES = moment.localeData('pt-br').months();
const CURRENT_YEAR = moment().year();
const CURRENT_MONTH = moment().month();

export class EmployeeAnticipationReport extends React.Component<IEmployeeAnticipationReportProps, IEmployeeAnticipationReportState> {
    constructor(props) {
        super(props);
        this.state = {
            status: HttpRequestStatus.NOOP,
            entity: {},
        };
    }

    componentDidMount() {
        this.props.getCompanies();
        this.props.getEmployeePrograms();
    }

    handleGoBack = () => {
        this.props.history.replace('/entity/employee-anticipation');
    };

    onReportDownloadRequest = async (_event, _errors, values) => {
        const MONTH_INDEX_OFFSET = 1;
        const _month: number = values.month && !isNaN(values.month) && values.month > 0 ? Number(values.month) : 0;
        const _normalizedMonth = ((Number(_month.toFixed(0)) % MONTH_NAMES.length) + MONTH_INDEX_OFFSET).toString().padStart(2, '0');
        const _monthYear = `${values.year}-${_normalizedMonth}`;
        const _program_id = values.program.id;
        const _company_id = values.company.id;

        this.setState({
            status: HttpRequestStatus.ONGOING,
        });

        const response: AxiosResponse<Uint8Array> = await Axios.get(`/api/employee-anticipations/${_monthYear}/xlsx?employee.program.id=${_program_id}&employee.company.id=${_company_id}`, { responseType: 'arraybuffer' });

        try {
            const _file_name = response.headers['content-disposition']?.split('filename=')[1] ?? `antecipacoes-${_monthYear}.xlsx`;
            const _type = response.headers['content-type'] ?? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
            const _blob = new Blob([response.data], { type: _type });
            const _url = window.URL.createObjectURL(_blob);
            const _link: HTMLAnchorElement = document.createElement('a');
            _link.href = _url;
            _link.setAttribute('download', _file_name);
            document.body.appendChild(_link);
            _link.click();
            _link.remove();

            this.setState({
                status: HttpRequestStatus.SUCCESS,
                entity: {},
            });
        } catch (error) {
            alert('Erro ao importar');
            console.debug('error', error);
            this.setState({
                ...this.state,
                status: HttpRequestStatus.ERROR,
            });
        }
    };

    render() {
        const { companies, employeePrograms } = this.props;

        const { status, entity } = this.state;

        if (status === HttpRequestStatus.ONGOING) {
            return (
                <Row style={{ marginTop: '100px' }}>
                    <Col>
                        <Loading />
                    </Col>
                </Row>
            );
        }

        return (
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.report.title">Download</Translate>
                    </h2>
                    <div style={{ borderBottom: 'solid 1px #e3e3e3' }}>
                        <AvForm model={entity} onSubmit={this.onReportDownloadRequest}>
                            <AvGroup>
                                <Label for="report-month">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.report.month">Month</Translate>
                                </Label>
                                <AvInput id="report-month" type="select" className="form-control" name="month" value={CURRENT_MONTH} style={{ textTransform: 'capitalize' }}>
                                    {MONTH_NAMES.map((month, index) => (
                                        <option value={index} key={month}>
                                            {month}
                                        </option>
                                    ))}
                                </AvInput>
                            </AvGroup>
                            <AvGroup>
                                <Label for="report-year">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.report.year">Year</Translate>
                                </Label>
                                <AvField id="report-year" type="number" name="year" value={CURRENT_YEAR} />
                            </AvGroup>
                            <AvGroup>
                                <Label for="report-company">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.report.company">Company</Translate>
                                </Label>
                                <AvInput id="report-company" type="select" className="form-control" name="company.id" value={companies[0] && companies[0].id} required>
                                    {companies
                                        ? companies.map(otherEntity => (
                                              <option value={otherEntity.id} key={otherEntity.id}>
                                                  {otherEntity.name}
                                              </option>
                                          ))
                                        : null}
                                </AvInput>
                                <AvFeedback>
                                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                </AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <Label for="report-program">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.report.program">Program</Translate>
                                </Label>
                                <AvInput id="report-program" type="select" className="form-control" name="program.id" value={employeePrograms[0] && employeePrograms[0].id} required>
                                    {employeePrograms
                                        ? employeePrograms.map(otherEntity => (
                                              <option value={otherEntity.id} key={otherEntity.id}>
                                                  {otherEntity.name}
                                              </option>
                                          ))
                                        : null}
                                </AvInput>
                                <AvFeedback>
                                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                </AvFeedback>
                            </AvGroup>
                            <Button tag={Link} id="home" to="/" replace color="info">
                                <span className="d-none d-md-inline">
                                    <Translate contentKey="global.menu.home">Home</Translate>
                                </span>
                            </Button>
                            &nbsp;
                            <Button tag={Link} id="cancel-save" to="/entity/employee-anticipation" replace color="info">
                                <FontAwesomeIcon icon="arrow-left" />
                                &nbsp;
                                <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.back">Back</Translate>
                                </span>
                            </Button>
                            &nbsp; &nbsp;
                            <Button color="primary" id="save-entity" type="submit">
                                <FontAwesomeIcon icon="download" />
                                &nbsp;
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.report.download">Download</Translate>
                            </Button>
                        </AvForm>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ upload, company, employeeProgram }: IRootState) => ({
    file: upload.file,
    status: upload.status,
    companies: company.data,
    employeePrograms: employeeProgram.entities,
});

const mapDispatchToProps = { showModal, hideModal, getCompanies, getEmployeePrograms };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAnticipationReport);
