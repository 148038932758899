import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import CompanyEmployee from './company-employee';
import CompanyEmployeeActivationDialog from './company-employee-activation-dialog';
import CompanyEmployeeDeleteDialog from './company-employee-delete-dialog';
import CompanyEmployeeDetail from './company-employee-detail';
import CompanyEmployeeInviteDialog from './company-employee-invite-dialog';
import CompanyEmployeeUpdate from './company-employee-update';
import CompanyEmployeeUpload from './company-employee-upload';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CompanyEmployeeUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/upload`} component={CompanyEmployeeUpload} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CompanyEmployeeUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/invite`} component={CompanyEmployeeInviteDialog} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CompanyEmployeeDetail} />
            <ErrorBoundaryRoute path={match.url} component={CompanyEmployee} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CompanyEmployeeDeleteDialog} />
        <ErrorBoundaryRoute path={`${match.url}/:id/activation`} component={CompanyEmployeeActivationDialog} />
    </>
);

export default Routes;
