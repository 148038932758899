import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import StringUtils from 'app/util/StringUtils';
import { OnChangeEvent, OnClickEvent } from 'app/util/types';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { getCompanies } from './company.reducer';

export interface ICompanyProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export const Company = (props: ICompanyProps) => {
    const [search, setSearch] = useState<string>(undefined);
    const [pagination, setPagination] = useState(getSortState(props.location, ITEMS_PER_PAGE));

    const getAllCompanies = (query: string) => props.getCompanies(query, pagination.activePage - 1, pagination.itemsPerPage, `${pagination.sort},${pagination.order}`);

    useEffect(() => {
        getAllCompanies(search);
    }, []);

    const sortOrganizations = () => getAllCompanies(search);

    useEffect(() => {
        sortOrganizations();
    }, [pagination.activePage, pagination.order, pagination.sort]);

    const sort = p => () => {
        setPagination({
            ...pagination,
            order: pagination.order === 'asc' ? 'desc' : 'asc',
            sort: p,
        });
        props.history.push(`${props.location.pathname}?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`);
    };

    const handlePagination = currentPage =>
        setPagination({
            ...pagination,
            activePage: currentPage,
        });

    const onSearchChange = (event: OnChangeEvent) => {
        setSearch(event.target.value);
        event.preventDefault();
    };

    const handleSearch = (event: OnClickEvent) => {
        getAllCompanies(search);
        event.preventDefault();
    };

    const { companies, match, totalItems } = props;

    return (
        <>
            <h2 id="company-page-heading">
                <Translate contentKey="companies.title">Companies</Translate>
                <div className="float-right">
                    <Row style={{ flexWrap: 'nowrap', marginRight: '30px' }}>
                        <Col md="5" style={{ minWidth: '300px', paddingRight: '0px' }}>
                            <Input type="text" onChange={onSearchChange} value={search} style={{ marginTop: '10px' }} />
                        </Col>
                        <Col md="3" style={{ paddingLeft: '0px' }}>
                            <Button type="button" color="success" onClick={handleSearch}>
                                <FontAwesomeIcon icon="search" /> <Translate contentKey="entity.action.search">Search</Translate>
                            </Button>
                        </Col>
                        <Col md="4">
                            <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity">
                                <FontAwesomeIcon icon="plus" /> <Translate contentKey="companies.createLabel">Create new company</Translate>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </h2>
            <Table responsive striped>
                <thead>
                    <tr>
                        <th className="hand" onClick={sort('name')}>
                            <Translate contentKey="company.name">Name</Translate>
                            <FontAwesomeIcon icon="sort" />
                        </th>
                        <th className="hand" onClick={sort('type')}>
                            <Translate contentKey="company.type">Type</Translate>
                            <FontAwesomeIcon icon="sort" />
                        </th>
                        <th className="hand" onClick={sort('identification')}>
                            <Translate contentKey="company.identification.label">Identification</Translate>
                            <FontAwesomeIcon icon="sort" />
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {companies.map((company, i) => (
                        <tr id={company.id} key={company.id}>
                            <td>{company.name}</td>
                            <td>
                                {company.type &&
                                    {
                                        INDIVIDUAL: translate('companyType.INDIVIDUAL'),
                                        CORPORATION: translate('companyType.CORPORATION'),
                                    }[company.type]}
                            </td>
                            <td>
                                {company.type
                                    ? {
                                          INDIVIDUAL: StringUtils.addCpfMask(company.identification),
                                          CORPORATION: StringUtils.addCnpjMask(company.identification),
                                      }[company.type]
                                    : company.identification}
                            </td>
                            <td className="text-right">
                                <div className="btn-group flex-btn-group-container">
                                    <Button tag={Link} to={`${match.url}/${company.id}`} color="info" size="sm">
                                        <FontAwesomeIcon icon="eye" />{' '}
                                        <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.view">View</Translate>
                                        </span>
                                    </Button>
                                    <Button tag={Link} to={`${match.url}/${company.id}/edit`} color="primary" size="sm">
                                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                                        <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.edit">Edit</Translate>
                                        </span>
                                    </Button>
                                    <Button tag={Link} to={`${match.url}/${company.id}/delete`} color="danger" size="sm">
                                        <FontAwesomeIcon icon="trash" />{' '}
                                        <span className="d-none d-md-inline">
                                            <Translate contentKey="entity.action.delete">Delete</Translate>
                                        </span>
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className={companies && companies.length > 0 ? '' : 'd-none'}>
                <Row className="justify-content-center">
                    <JhiItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} i18nEnabled />
                </Row>
                <Row className="justify-content-center">
                    <JhiPagination activePage={pagination.activePage} onSelect={handlePagination} maxButtons={5} itemsPerPage={pagination.itemsPerPage} totalItems={props.totalItems} />
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    companies: storeState.company.data,
    totalItems: storeState.company.totalItems,
});

const mapDispatchToProps = { getCompanies };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Company);
