import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { readDailyDurationFromISODuration } from 'app/shared/model/company-employee.model';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getEntities } from './company-employee.reducer';
import { CompanyEmployeeModalFilter, CompanyEmployeeModalFilterParameters, defaultCompanyEmployeeModalFilterParameters } from './components/company-employee-modal-filter/company-employee-modal-filter';

export interface ICompanyEmployeeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

type ICompanyEmployeeModalFilterState = {
    showModal: boolean;
    filterParameters: CompanyEmployeeModalFilterParameters;
};

export type ICompanyEmployeeState = IPaginationBaseState | ICompanyEmployeeModalFilterState;

export class CompanyEmployee extends React.Component<ICompanyEmployeeProps, ICompanyEmployeeState> {
    state = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        showModal: false,
        filterParameters: defaultCompanyEmployeeModalFilterParameters,
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order, filterParameters } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, filterParameters);
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleOpenModal = () => {
        this.setState({ showModal: true });
    };

    handleSetFilterParameters = (value: CompanyEmployeeModalFilterParameters) => {
        this.setState({ filterParameters: { ...value } });
    };

    render() {
        const { companyEmployeeList, match, totalItems } = this.props;
        const { showModal, filterParameters } = this.state;
        return (
            <div>
                <h2 id="company-employee-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.title">Company Employees</Translate>
                    <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.createLabel">Create new Company Employee</Translate>
                    </Link>
                    <Link to={`${match.url}/upload`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.uploadLabel">Create new Employee Time Entry</Translate>
                    </Link>
                    <div className="btn btn-primary float-right jh-create-entity" id="jh-create-entity" onClick={this.handleOpenModal}>
                        <FontAwesomeIcon icon="search" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.filterLabel">Filter Company Employee</Translate>
                    </div>
                </h2>
                <CompanyEmployeeModalFilter getEntities={this.getEntities} handleSetFilterParameters={this.handleSetFilterParameters} showModal={showModal} handleClose={this.handleCloseModal} filterParameters={filterParameters} />
                <div className="table-responsive">
                    {companyEmployeeList && companyEmployeeList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('active')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.active">Active</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('dailyWorkload')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.dailyWorkload">Daily Workload</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('identificationNumber')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.identificationNumber">Identification Number</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('canAnticipate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.canAnticipate">Can Anticipate</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('organization.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.organization">Organization</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('person.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.person">Person</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('company.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.company">Company</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {companyEmployeeList.map((employee, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>
                                            <Button tag={Link} to={`${match.url}/${employee.id}`} color="link" size="sm">
                                                {employee.id}
                                            </Button>
                                        </td>
                                        <td>{employee.active ? 'Sim' : 'Não'}</td>
                                        <td>{readDailyDurationFromISODuration(employee)}</td>
                                        <td>{employee.identificationNumber}</td>
                                        <td>{employee.canAnticipate ? 'Sim' : 'Não'}</td>
                                        <td>{employee.organization ? <Link to={`organization/${employee.organization.id}`}>{employee.organization.name}</Link> : ''}</td>
                                        <td>{employee.person ? <Link to={`person/${employee.person.id}`}>{employee.person.name}</Link> : ''}</td>
                                        <td>{employee.company ? <Link to={`company/${employee.company.id}`}>{employee.company.name}</Link> : ''}</td>
                                        <td className="text-right">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button tag={Link} to={`${match.url}/${employee.id}/invite`} color="info" size="sm">
                                                    <FontAwesomeIcon icon="eye" />
                                                    &nbsp;
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.invite.button">invite</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employee.id}`} color="info" size="sm">
                                                    <FontAwesomeIcon icon="eye" />
                                                    &nbsp;
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.view">View</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employee.id}/edit`} color="primary" size="sm">
                                                    <FontAwesomeIcon icon="pencil-alt" />
                                                    &nbsp;
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${employee.id}/activation`} color={employee.active ? 'danger' : 'success'} size="sm">
                                                    <FontAwesomeIcon icon={employee.active ? 'times' : 'check'} />
                                                    &nbsp;
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey={`entity.action.${employee.active ? 'inactivate' : 'activate'}`}>Inactivate</Translate>
                                                    </span>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.home.notFound">No Company Employees found</Translate>
                        </div>
                    )}
                </div>
                <div className={companyEmployeeList && companyEmployeeList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ companyEmployee }: IRootState) => ({
    companyEmployeeList: companyEmployee.entities,
    totalItems: companyEmployee.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmployee);
