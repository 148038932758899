import { ICity } from 'app/shared/model/city.model';
import { ICardEdition } from './card-edition';

export interface IAddress extends ICardEdition {
    id?: string;
    name?: string;
    street?: string;
    number?: string;
    complement?: string;
    zipCode?: string;
    neighborhood?: string;
    city?: ICity;
    type?: AddressType;
    hash?: string;
}

export enum AddressType {
    HOME,
    PROFESSIONAL,
    OTHER,
}

export const defaultValue: Readonly<IAddress> = { isNew: true };
 