import MenuItem from 'app/shared/layout/menus/menu-item';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const CompanyMenu = props => (
    <NavDropdown icon="th-list" name={translate('global.menu.company.main')} id="entity-menu" style={{ maxHeight: '80vh', overflow: 'auto' }}>
        <MenuItem icon="asterisk" to="/company/company-employee">
            <Translate contentKey="global.menu.company.companyEmployee" />
        </MenuItem>
        <MenuItem icon="asterisk" to="/company/employee-anticipation">
            <Translate contentKey="global.menu.company.employeeAnticipation" />
        </MenuItem>
    </NavDropdown>
);
