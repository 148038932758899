import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import EmployeeAnticipation from './employee-anticipation';
import EmployeeAnticipationDeleteDialog from './employee-anticipation-delete-dialog';
import EmployeeAnticipationDetail from './employee-anticipation-detail';
import employeeAnticipationReport from './employee-anticipation-report';
import EmployeeAnticipationUpdate from './employee-anticipation-update';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EmployeeAnticipationUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/report`} component={employeeAnticipationReport} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EmployeeAnticipationUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EmployeeAnticipationDetail} />

            <ErrorBoundaryRoute path={match.url} component={EmployeeAnticipation} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={EmployeeAnticipationDeleteDialog} />
    </>
);

export default Routes;
