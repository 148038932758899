import { defaultValue, IPhone } from 'app/shared/model/phone.model';

const initialState = {
    phones: [] as IPhone[],
    phone: defaultValue,
};

export type PhoneState = Readonly<typeof initialState>;

// Reducer
export default (state: PhoneState = initialState, action): PhoneState => {
    return state;
};
