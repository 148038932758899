import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import {getCompanies} from 'app/entities/code-base/company/company.reducer';
import {IEmployeeProgramTaxRange} from 'app/shared/model/employee-program-tax-range.model';
import {defaultValue, IEmployeeProgram} from 'app/shared/model/employee-program.model';
import {IRootState} from 'app/shared/reducers';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import React from 'react';
import {Translate, translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {EditActionType} from '../code-base/company/company-manager';
import {ProgramTaxRangeManager} from '../employee-program-tax-range/manager/employee-program-tax-range-manager';
import {createEntity, getEntity, reset, updateEntity} from './employee-program.reducer';

export interface IEmployeeProgramUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface IEmployeeProgramUpdateState {
  isNew: boolean;
  taxes: IEmployeeProgramTaxRange[];
  editings: ReadonlyArray<ICompanyEdition>;
}

enum ProgramEditField {
  TAXES,
}

interface ICompanyEdition {
  field: ProgramEditField;
  editing: boolean;
}

export class EmployeeProgramUpdate extends React.Component<IEmployeeProgramUpdateProps, IEmployeeProgramUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      taxes: [],
      editings: [{field: ProgramEditField.TAXES, editing: false}],
      isNew: !this.props.match.params || !this.props.match.params.id,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentWillReceiveProps(props) {
    if ((props.employeeProgramEntity != null && this.state.taxes == null) || this.state.taxes.length === 0) {
      this.setState({
        taxes: props.employeeProgramEntity.taxes,
      });
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getCompanies();
  }

  saveEntity = (event, values) => {
    const {employeeProgramEntity} = this.props;
    const entity: IEmployeeProgram = {
      ...employeeProgramEntity,
      ...values,
      taxes: this.state.taxes,
    };

    if (this.state.isNew) {
      this.props.createEntity(entity);
    } else {
      this.props.updateEntity(entity);
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/employee-program');
  };

  handleTaxesChange = (taxes: IEmployeeProgramTaxRange[]) => {
    this.setState(prevState => ({
      ...prevState,
      taxes,
    }));
  };

  handleEditCallBack = (target: ProgramEditField, action: EditActionType) => {
    this.setState(prevState => ({
      ...prevState,
      editings: prevState.editings.map(it => {
        if (it.field === target) {
          it.editing = action === EditActionType.START;
        }
        return it;
      }),
    }));
  };

  render() {
    const {employeeProgramEntity, companies, loading, updating} = this.props;
    const {isNew, editings} = this.state;

    if (companies == null || companies.length === 0) {
      return <div/>;
    }

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="6" offset="2">
            <h2 id="giroEmployeeSalaryAdvanceApp.employeeProgram.home.createOrEditLabel">
              <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.home.createOrEditLabel">Create or edit a EmployeeProgram</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6" offset="2">
            {loading || employeeProgramEntity == null || (!isNew && employeeProgramEntity.company == null) ? (
              <Loading/>
            ) : (
              <AvForm model={isNew ? defaultValue : employeeProgramEntity} onValidSubmit={this.saveEntity} disabled={updating || editings.filter(it => it.editing === true).length > 0}>
                <AvGroup>
                  <Label id="nameLabel" for="employee-program-name">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.name">Name</Translate>
                  </Label>
                  <AvField
                    id="employee-program-name"
                    type="text"
                    name="name"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      minLength: {value: 0, errorMessage: translate('entity.validation.minlength', {min: 0})},
                      maxLength: {value: 255, errorMessage: translate('entity.validation.maxlength', {max: 255})},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="maxAnticipationSalaryPercentLabel" for="employee-program-maxAnticipationSalaryPercent">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.maxAnticipationSalaryPercent">Max Salary Percent</Translate>
                  </Label>
                  <AvField
                    id="employee-program-maxAnticipationSalaryPercent"
                    type="text"
                    name="maxAnticipationSalaryPercent"
                    validate={{
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 100, errorMessage: translate('entity.validation.max', {max: 100})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="estimatedPaymentDateInHoursLabel" for="employee-program-estimatedPaymentDateInHoursPercent">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.estimatedPaymentDateInHours">estimatedPaymentDateInHours</Translate>
                  </Label>
                  <AvField
                    id="employee-program-estimatedPaymentDateInHours"
                    type="text"
                    name="estimatedPaymentDateInHours"
                    validate={{
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 360, errorMessage: translate('entity.validation.max', {max: 360})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="monthlyAnticipationQuantityLimitLabel" for="employee-program-monthlyAnticipationQuantityLimit">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.monthlyAnticipationQuantityLimit">Max Anticipation Quantity</Translate>
                  </Label>
                  <AvField
                    id="employee-program-monthlyAnticipationQuantityLimit"
                    type="text"
                    className="form-control"
                    name="monthlyAnticipationQuantityLimit"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 100, errorMessage: translate('entity.validation.max', {max: 100})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="monthlyTaxBaseDayLabel" for="employee-program-monthlyTaxBaseDay">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.monthlyTaxBaseDay">Max Anticipation Quantity</Translate>
                  </Label>
                  <AvField
                    id="employee-program-monthlyTaxBaseDay"
                    type="text"
                    className="form-control"
                    name="monthlyTaxBaseDay"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 31, errorMessage: translate('entity.validation.max', {max: 31})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="minAnticipationAmountLabel" for="employee-program-minAnticipationAmount">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.minAnticipationAmount">Min Amount</Translate>
                  </Label>
                  <AvField
                    id="employee-program-minAnticipationAmount"
                    type="text"
                    name="minAnticipationAmount"
                    validate={{
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 10000, errorMessage: translate('entity.validation.max', {max: 10000})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="maxAnticipationAmountLabel" for="employee-program-maxAnticipationAmount">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.maxAnticipationAmount">Max Amount</Translate>
                  </Label>
                  <AvField
                    id="employee-program-maxAnticipationAmount"
                    type="text"
                    name="maxAnticipationAmount"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 10000, errorMessage: translate('entity.validation.max', {max: 10000})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="monthlyDeadlineDayLabel" for="employee-program-monthlyDeadlineDay">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.monthlyDeadlineDay">Monthly Deadline Day</Translate>
                  </Label>
                  <AvField
                    id="employee-program-monthlyDeadlineDay"
                    type="text"
                    className="form-control"
                    name="monthlyDeadlineDay"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      min: {value: 1, errorMessage: translate('entity.validation.min', {min: 1})},
                      max: {value: 31, errorMessage: translate('entity.validation.max', {max: 31})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="initialEmployeeBlockedMonthsLabel" for="employee-program-initialEmployeeBlockedMonths">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.initialEmployeeBlockedMonths">Initial Employee Blocked Months</Translate>
                  </Label>
                  <AvField
                    id="employee-program-initialEmployeeBlockedMonths"
                    type="string"
                    className="form-control"
                    name="initialEmployeeBlockedMonths"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 24, errorMessage: translate('entity.validation.max', {max: 24})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="autoApplyForContractPeriodLabel" for="employee-program-autoApplyForContractPeriod">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.autoApplyForContractPeriod">autoApplyForContractPeriod</Translate>
                    <AvInput id="company-employee-autoApplyForContractPeriod" type="checkbox" className="form-control" name="autoApplyForContractPeriod"/>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="autoApplyContractPeriodInMonthsValueLabel" for="employee-program-autoApplyContractPeriodInMonthsValue">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.autoApplyContractPeriodInMonthsValue">autoApplyContractPeriodInMonthsValue</Translate>
                  </Label>
                  <AvField
                    id="employee-program-autoApplyContractPeriodInMonthsValue"
                    type="string"
                    className="form-control"
                    name="autoApplyContractPeriodInMonthsValue"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 24, errorMessage: translate('entity.validation.max', {max: 480})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="platformRemunerationLabel" for="employee-program-platformRemuneration">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.platformRemuneration">Platform Remuneration</Translate>
                  </Label>
                  <AvField
                    id="employee-program-platformRemuneration"
                    type="text"
                    name="platformRemuneration"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      min: {value: 0, errorMessage: translate('entity.validation.min', {min: 0})},
                      max: {value: 100, errorMessage: translate('entity.validation.max', {max: 100})},
                      number: {value: true, errorMessage: translate('entity.validation.number')},
                    }}
                  />
                </AvGroup>

                <AvGroup>
                  <Label id="assignmentTermTemplate" for="employee-program-assignmentTermTemplate">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.assignmentTermTemplate">Assignment Term Template</Translate>
                  </Label>
                  <AvField
                    id="employee-program-assignmentTermTemplate"
                    type="text"
                    name="assignmentTermTemplate"
                    validate={{
                      required: {value: true, errorMessage: translate('entity.validation.required')},
                      maxLength: {value: 255, errorMessage: translate('entity.validation.maxlength', {max: 255})},
                    }}
                  />
                </AvGroup>

                <AvGroup>
                  <Label id="statusLabel" for="employee-program-status">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.status">Status</Translate>
                  </Label>
                  <AvInput id="employee-program-status" type="select" className="form-control" name="status" value={(!isNew && employeeProgramEntity.status) || 'ACTIVE'}>
                    <option value="ACTIVE">{translate('giroEmployeeSalaryAdvanceApp.ProgramStatus.ACTIVE')}</option>
                    <option value="PAUSED">{translate('giroEmployeeSalaryAdvanceApp.ProgramStatus.PAUSED')}</option>
                    <option value="FINISHED">{translate('giroEmployeeSalaryAdvanceApp.ProgramStatus.FINISHED')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="isDefaultLabel" check>
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.isDefault">isCompanyMainProgram</Translate>
                    <AvInput id="company-employee-isCompanyMainProgram" type="checkbox" className="form-control" name="isDefault"/>
                  </Label>
                </AvGroup>
                <br/>
                <br/>
                <hr/>
                <br/>
                <ProgramTaxRangeManager taxes={this.state.taxes} updateCallback={it => this.handleTaxesChange(it)} editCallback={it => this.handleEditCallBack(ProgramEditField.TAXES, it)}/>
                <AvGroup>
                  <Label for="employee-program-company">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeProgram.company">Company</Translate>
                  </Label>
                  <AvInput id="employee-program-company" type="select" className="form-control" name="company.id" value={isNew ? companies[0] && companies[0].id : employeeProgramEntity.company.id} required>
                    {companies
                      ? companies.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/employee-program" replace color="info">
                  <FontAwesomeIcon icon="arrow-left"/>
                  &nbsp;
                  <span className="d-none d-md-inline">
                                        <Translate contentKey="entity.action.back">Back</Translate>
                                    </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save"/>
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  companies: storeState.company.data,
  employeeProgramEntity: storeState.employeeProgram.entity,
  loading: storeState.employeeProgram.loading,
  updating: storeState.employeeProgram.updating,
  updateSuccess: storeState.employeeProgram.updateSuccess,
});

const mapDispatchToProps = {
  getCompanies,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProgramUpdate);
