import { ICompanyEmployee } from 'app/shared/model/company-employee.model';
import { IEmployeeMonthlyCredit } from 'app/shared/model/employee-monthly-credit.model';
import parseISO from 'date-fns/parseISO';
import moment, { Moment } from 'moment';
import { EmployeeAnticipationStatus } from './enumerations/employee-anticipation-status.model';

export interface IEmployeeTimeEntry {
    id?: number;
    hourlyRate?: number;
    duration?: string;
    totalDuration?: string;
    employeeIdentificationNumber?: string;
    date?: Moment;
    creditAmount?: number;
    status?: EmployeeAnticipationStatus;
    employee?: ICompanyEmployee;
    month?: IEmployeeMonthlyCredit;
}

export const defaultValue: Readonly<IEmployeeTimeEntry> = {};

export const writeToISODuration = (entry: IEmployeeTimeEntry): void => {
    if (entry.duration != null) {
        entry.duration = moment(entry.duration, 'HH:mm').format('[PT]H[H]m[M]');
    }
};

export const readDurationFromISODuration = (entry: IEmployeeTimeEntry): string => {
    if (entry.duration != null) {
        return moment(entry.duration, '[PT]H[H]m[M]').format('HH:mm');
    }
    return entry.duration;
};
