import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { getEntities as getCompanyEmployees } from 'app/entities/company-employee/company-employee.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './employee-monthly-credit.reducer';

export interface IEmployeeMonthlyCreditUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEmployeeMonthlyCreditUpdateState {
    isNew: boolean;
    employeeId: string;
}

export class EmployeeMonthlyCreditUpdate extends React.Component<IEmployeeMonthlyCreditUpdateProps, IEmployeeMonthlyCreditUpdateState> {
    constructor(props) {
        super(props);
        this.state = {
            employeeId: '0',
            isNew: !this.props.match.params || !this.props.match.params.id,
        };
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }

        this.props.getCompanyEmployees();
    }

    saveEntity = (event, errors, values) => {
        if (errors.length === 0) {
            const { employeeMonthlyCreditEntity } = this.props;
            const entity = {
                ...employeeMonthlyCreditEntity,
                ...values,
            };

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };

    handleClose = () => {
        this.props.history.push('/entity/employee-monthly-credit');
    };

    render() {
        const { employeeMonthlyCreditEntity, companyEmployees, loading, updating } = this.props;
        const { isNew } = this.state;

        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.home.createOrEditLabel">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.home.createOrEditLabel">Create or edit a EmployeeMonthlyCredit</Translate>
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        {loading ? (
                            <Loading />
                        ) : (
                            <AvForm model={isNew ? {} : employeeMonthlyCreditEntity} onSubmit={this.saveEntity}>
                                {!isNew ? (
                                    <AvGroup>
                                        <Label for="employee-monthly-credit-id">
                                            <Translate contentKey="global.field.id">ID</Translate>
                                        </Label>
                                        <AvInput id="employee-monthly-credit-id" type="text" className="form-control" name="id" required readOnly />
                                    </AvGroup>
                                ) : null}
                                <AvGroup>
                                    <Label id="monthLabel" for="employee-monthly-credit-month">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.month">Month</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-monthly-credit-month"
                                        type="date"
                                        className="form-control"
                                        name="month"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="workedAmountLabel" for="employee-monthly-credit-workedAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.workedAmount">Worked Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-monthly-credit-workedAmount"
                                        type="text"
                                        name="workedAmount"
                                        validate={{
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 800, errorMessage: translate('entity.validation.max', { max: 800 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="availableAmountLabel" for="employee-monthly-credit-availableAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.availableAmount">Available Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-monthly-credit-availableAmount"
                                        type="text"
                                        name="availableAmount"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="workedDaysLabel" for="employee-monthly-credit-workedDays">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.workedDays">Worked Days</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-monthly-credit-workedDays"
                                        type="string"
                                        className="form-control"
                                        name="workedDays"
                                        validate={{
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 31, errorMessage: translate('entity.validation.max', { max: 31 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="blockedAmountLabel" for="employee-monthly-credit-blockedAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.blockedAmount">Blocked Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-monthly-credit-blockedAmount"
                                        type="text"
                                        name="blockedAmount"
                                        validate={{
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="anticipatedAmountLabel" for="employee-monthly-credit-anticipatedAmount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.anticipatedAmount">Anticipated Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-monthly-credit-anticipatedAmount"
                                        type="text"
                                        name="anticipatedAmount"
                                        validate={{
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="monthlyDeadlineReachedLabel" check>
                                        <AvInput id="employee-monthly-credit-monthlyDeadlineReached" type="checkbox" className="form-control" name="monthlyDeadlineReached" />
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.monthlyDeadlineReached">Monthly Deadline Reached</Translate>
                                    </Label>
                                </AvGroup>
                                <AvGroup>
                                    <Label for="employee-monthly-credit-employee">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeMonthlyCredit.employee">Employee</Translate>
                                    </Label>
                                    <AvInput
                                        id="employee-monthly-credit-employee"
                                        type="select"
                                        className="form-control"
                                        name="employee.id"
                                        value={isNew ? companyEmployees[0] && companyEmployees[0].id : employeeMonthlyCreditEntity.employee.id}
                                        required>
                                        {companyEmployees
                                            ? companyEmployees.map(otherEntity => (
                                                  <option value={otherEntity.id} key={otherEntity.id}>
                                                      {otherEntity.id}
                                                  </option>
                                              ))
                                            : null}
                                    </AvInput>
                                    <AvFeedback>
                                        <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                    </AvFeedback>
                                </AvGroup>
                                <Button tag={Link} id="cancel-save" to="/entity/employee-monthly-credit" replace color="info">
                                    <FontAwesomeIcon icon="arrow-left" />
                                    &nbsp;
                                    <span className="d-none d-md-inline">
                                        <Translate contentKey="entity.action.back">Back</Translate>
                                    </span>
                                </Button>
                                &nbsp;
                                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                    <FontAwesomeIcon icon="save" />
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Save</Translate>
                                </Button>
                            </AvForm>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    companyEmployees: storeState.companyEmployee.entities,
    employeeMonthlyCreditEntity: storeState.employeeMonthlyCredit.entity,
    loading: storeState.employeeMonthlyCredit.loading,
    updating: storeState.employeeMonthlyCredit.updating,
    updateSuccess: storeState.employeeMonthlyCredit.updateSuccess,
});

const mapDispatchToProps = {
    getCompanyEmployees,
    getEntity,
    updateEntity,
    createEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMonthlyCreditUpdate);
