import { ICardEdition } from './card-edition';

export enum PhoneType {
    HOME = 'HOME',
    PROFESSIONAL = 'PROFESSIONAL',
    CELLULAR = 'CELLULAR',
    OTHER = 'OTHER',
}

export interface IPhone extends ICardEdition {
    id?: string;
    number?: string;
    observation?: string;
    type?: PhoneType;
}

export const defaultValue: IPhone = {
    id: undefined,
    number: undefined,
    observation: undefined,
    type: PhoneType.OTHER,
    isNew: true,
};
