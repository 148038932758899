import { IEmployeeProgram } from 'app/shared/model/employee-program.model';
import { ICardEdition } from './card-edition';

export const enum TaxRangeStrategy {
    LOWER_INCLUSIVE_UPPER_INCLUSIVE = 'LOWER_INCLUSIVE_UPPER_INCLUSIVE',
    LOWER_EXCLUSIVE_UPPER_INCLUSIVE = 'LOWER_EXCLUSIVE_UPPER_INCLUSIVE',
    LOWER_INCLUSIVE_UPPER_EXCLUSIVE = 'LOWER_INCLUSIVE_UPPER_EXCLUSIVE',
    LOWER_EXCLUSIVE_UPPER_EXCLUSIVE = 'LOWER_EXCLUSIVE_UPPER_EXCLUSIVE',
}

export interface IEmployeeProgramTaxRange extends ICardEdition {
    id?: number;
    name?: string;
    lowerAmount?: number;
    upperAmount?: number;
    taxAmount?: number;
    active?: boolean;
    mode?: TaxRangeStrategy;
    program?: IEmployeeProgram;
}

export const defaultValue: Readonly<IEmployeeProgramTaxRange> = {
    active: false,
    isNew: true,
};
