import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import EmployeeProgramFinancial from './employee-program-financial';
import EmployeeProgramFinancialDeleteDialog from './employee-program-financial-delete-dialog';
import EmployeeProgramFinancialDetail from './employee-program-financial-detail';
import EmployeeProgramFinancialUpdate from './employee-program-financial-update';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EmployeeProgramFinancialUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EmployeeProgramFinancialUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EmployeeProgramFinancialDetail} />
            <ErrorBoundaryRoute path={match.url} component={EmployeeProgramFinancial} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={EmployeeProgramFinancialDeleteDialog} />
    </>
);

export default Routes;
