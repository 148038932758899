import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import moment from 'moment';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { defaultEmployeeAnticipationModalFilterParameters, EmployeeAnticipationModalFilter, EmployeeAnticipationModalFilterParameters } from './components/employee-anticipation-modal-filter/employee-anticipation-modal-filter';
import { getEntities } from './employee-anticipation.reducer';

export interface IEmployeeAnticipationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

type IEmployeeAnticipationModalFilterState = {
    showModal: boolean;
    filterParameters: EmployeeAnticipationModalFilterParameters;
};

export type IEmployeeAnticipationState = IPaginationBaseState | IEmployeeAnticipationModalFilterState;

export class EmployeeAnticipation extends React.Component<IEmployeeAnticipationProps, IEmployeeAnticipationState> {
    state = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
        showModal: false,
        filterParameters: defaultEmployeeAnticipationModalFilterParameters,
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order, filterParameters } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, filterParameters);
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleOpenModal = () => {
        this.setState({ showModal: true });
    };

    handleSetFilterParameters = (value: EmployeeAnticipationModalFilterParameters) => {
        this.setState({ filterParameters: { ...value } });
    };

    render() {
        const { employeeAnticipationList, match, totalItems } = this.props;
        return (
            <div>
                <h2 id="employee-anticipation-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.title">Employee Anticipations</Translate>
                    <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.createLabel">Create new Employee Anticipation</Translate>
                    </Link>
                    <Link to={`${match.url}/report`} className="btn btn-primary float-right jh-create-entity" id="anticipation-report">
                        <FontAwesomeIcon icon="book" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.report">Anticipation Report</Translate>
                    </Link>
                    <div className="btn btn-primary float-right jh-create-entity" id="jh-create-entity" onClick={this.handleOpenModal}>
                        <FontAwesomeIcon icon="search" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.filterLabel">Filter Employee Anticipation</Translate>
                    </div>
                </h2>
                <EmployeeAnticipationModalFilter getEntities={this.getEntities} handleSetFilterParameters={this.handleSetFilterParameters} showModal={this.state.showModal} handleClose={this.handleCloseModal} filterParameters={this.state.filterParameters} />
                <div className="table-responsive">
                    {employeeAnticipationList && employeeAnticipationList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('amount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('requestedDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.requestedDate">Requested Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('acceptedDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.acceptedDate">Accepted Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('paidDate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.paidDate">Paid Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('status')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('month.month')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.month">Month</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('employee.person.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('programFinancial.company.name')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.programFinancial">Program Financial</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {employeeAnticipationList.map((employeeAnticipation, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>
                                            <Button tag={Link} to={`${match.url}/${employeeAnticipation.id}`} color="link" size="sm">
                                                {employeeAnticipation.id}
                                            </Button>
                                        </td>
                                        <td>
                                            {employeeAnticipation.amount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.requestedDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.acceptedDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <TextFormat type="date" value={employeeAnticipation.paidDate} format={APP_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            <Translate contentKey={`giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.${employeeAnticipation.status}`} />
                                        </td>
                                        <td>{employeeAnticipation.month ? <Link to={`employee-monthly-credit/${employeeAnticipation.month.id}`}>{moment(employeeAnticipation.month.month).format('MM/YYYY')}</Link> : ''}</td>
                                        <td>
                                            {employeeAnticipation.employee ? (
                                                <Link to={`company-employee/${employeeAnticipation.employee.id}`}>{employeeAnticipation.employee.person?.name ?? employeeAnticipation.employee.identificationNumber}</Link>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {employeeAnticipation.programFinancial ? (
                                                <Link to={`employee-program-financial/${employeeAnticipation.programFinancial.id}`}>{employeeAnticipation.programFinancial.company?.name ?? employeeAnticipation.programFinancial.id}</Link>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td className="text-right">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button tag={Link} to={`${match.url}/${employeeAnticipation.id}`} color="info" size="sm">
                                                    <FontAwesomeIcon icon="eye" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.view">View</Translate>
                                                    </span>
                                                </Button>
                                                {/* <Button tag={Link} to={`${match.url}/${employeeAnticipation.id}/edit`} color="primary" size="sm">
                                                    <FontAwesomeIcon icon="pencil-alt" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                                    </span>
                                                </Button> */}
                                                {/* {employeeAnticipation.status === EmployeeAnticipationStatus.PENDING && (
                                                    <Button tag={Link} to={`${match.url}/${employeeAnticipation.id}/delete`} color="danger" size="sm">
                                                        <FontAwesomeIcon icon="trash" />{' '}
                                                        <span className="d-none d-md-inline">
                                                            <Translate contentKey="entity.action.delete">Delete</Translate>
                                                        </span>
                                                    </Button>
                                                )} */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.notFound">No Employee Anticipations found</Translate>
                        </div>
                    )}
                </div>
                <div className={employeeAnticipationList && employeeAnticipationList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ employeeAnticipation }: IRootState) => ({
    employeeAnticipationList: employeeAnticipation.entities,
    totalItems: employeeAnticipation.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAnticipation);
