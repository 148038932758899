import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { getOrganizations } from 'app/entities/code-base/organization/organization.reducer';
import { IOrganization } from 'app/shared/model/organization.model';
import { OnClickEvent } from 'app/util/types';
import React, { Component } from 'react';
import { Translate, translate } from 'react-jhipster';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

export interface ICompanyOrganizationsManagerProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    getOrganizations: typeof getOrganizations;
    organizations: ReadonlyArray<IOrganization>;
    updateCallback?: (organizations: ReadonlyArray<IOrganization>) => void;
}

export interface ICompanyOrganizationsManagerState {
    organizations: ReadonlyArray<IOrganization>;
}

export class CompanyOrganizationManager extends Component<ICompanyOrganizationsManagerProps, ICompanyOrganizationsManagerState> {
    constructor(props: ICompanyOrganizationsManagerProps) {
        super(props);
        this.state = {
            organizations: props.organizations ? props.organizations : ([] as ReadonlyArray<IOrganization>),
        };
    }

    handleEdit = (event: OnClickEvent, index: number): void => {
        this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
            displayProperty: [['name']],
            title: translate('companyManager.organization.search'),
            action: this.props.getOrganizations,
            placeholderInput: translate('companyMananger.organization.placeholder'),
            stateAction: 'organization',
            cancelCallback: () => this.props.hideModal(),
            saveCallback: (selected: IOrganization) => this.handleOrganizationSelected(selected, index),
        });
        event.stopPropagation();
        event.preventDefault();
    };

    handleOrganizationSelected = (selected: IOrganization, index: number): void => {
        this.setState(
            prevState => ({
                organizations: prevState.organizations.map((organization: IOrganization, i: number) => {
                    return i === index ? selected : organization;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.organizations);
                }
            }
        );
        this.props.hideModal();
    };

    handleAdd = (event: OnClickEvent): void => {
        this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
            displayProperty: [['name']],
            title: translate('companyManager.organization.search'),
            action: this.props.getOrganizations,
            placeholderInput: translate('companyManager.organization.placeholder'),
            stateAction: 'organization',
            cancelCallback: () => this.props.hideModal(),
            saveCallback: (selected: IOrganization) => this.addOrganization(selected),
        });
        event.preventDefault();
    };

    addOrganization = (selected: IOrganization): void => {
        this.setState(
            prevState => ({
                organizations: [...prevState.organizations, selected],
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.organizations);
                }
            }
        );
        this.props.hideModal();
    };

    handleRemove = (event: OnClickEvent, index: number): void => {
        this.setState(
            prevState => ({
                organizations: prevState.organizations.filter((organization: IOrganization, i: number) => {
                    return i !== index;
                }),
            }),
            () => {
                if (this.props.updateCallback) {
                    this.props.updateCallback(this.state.organizations);
                }
            }
        );
        event.preventDefault();
    };

    render() {
        const { organizations } = this.state;
        return (
            <>
                <h3 id="company-organizations-heading">
                    <Translate contentKey="company.organizations">Organizations</Translate>
                    <Button color="primary" type="button" className="float-right" onClick={this.handleAdd}>
                        <FontAwesomeIcon icon="plus" />
                        <Translate contentKey="organizations.selectLabel">Add organization</Translate>
                    </Button>
                </h3>
                <div className="organizations-manage">
                    {organizations && organizations.length > 0 ? (
                        organizations.map((organization: IOrganization, i: number) => (
                            <Card className="organization-card" key={organization.id}>
                                <CardBody>
                                    <div className="float-right">
                                        <Button color="info" size="sm" type="button" onClick={e => this.handleEdit(e, i)}>
                                            <FontAwesomeIcon icon="pencil-alt" />
                                            <Translate contentKey="entity.action.edit">Edit</Translate>
                                        </Button>{' '}
                                        <Button color="danger" size="sm" type="button" onClick={e => this.handleRemove(e, i)}>
                                            <FontAwesomeIcon icon="trash" />
                                            <Translate contentKey="entity.action.delete">Delete</Translate>
                                        </Button>
                                    </div>
                                    <Row>
                                        <Col>
                                            <dl>
                                                <dt>
                                                    <Translate contentKey="organization.self">Organization</Translate>
                                                </dt>
                                                <dd>{organization.name}</dd>
                                            </dl>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        ))
                    ) : (
                        <p>
                            <Translate contentKey="companyManager.empty.organizations">No registered phone</Translate>
                        </p>
                    )}
                </div>
            </>
        );
    }
}
