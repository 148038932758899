import { IAddress } from './address.model';
import { IBankAccount } from './bank-account.model';
import { IEmailAddress } from './email-address.model';
import { PersonType } from './enumerations/person-type.model';
import { IOrganization } from './organization.model';
import { IPhone } from './phone.model';

export interface ICompany {
    id?: string;
    name?: string;
    identification?: string;
    type?: PersonType;
    accounts?: IBankAccount[];
    addresses?: IAddress[];
    emailAddresses?: ReadonlyArray<IEmailAddress>;
    organizations?: ReadonlyArray<IOrganization>;
    phones?: IPhone[];
}

export const defaultValue: Readonly<ICompany> = {
    id: undefined,
    name: undefined,
    identification: undefined,
    type: PersonType.INDIVIDUAL,
    accounts: [] as IBankAccount[],
    addresses: [],
    emailAddresses: [] as ReadonlyArray<IEmailAddress>,
    organizations: [] as ReadonlyArray<IOrganization>,
    phones: [],
};
