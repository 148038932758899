import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { readDailyDurationFromISODuration, readMonthlyDurationFromISODuration } from 'app/shared/model/company-employee.model';
import { IRootState } from 'app/shared/reducers';
import StringUtils from 'app/util/StringUtils';
import React from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { AddressCardDetail } from '../code-base/address/address-card-detail';
import BankAccountDetail from '../employee-anticipation/components/bank-account/bank-account-detail';
import { getEntity } from './company-employee.reducer';

export interface ICompanyEmployeeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CompanyEmployeeDetail extends React.Component<ICompanyEmployeeDetailProps> {
    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    render() {
        const { companyEmployeeEntity } = this.props;

        return (
            <Row>
                <Col md="8">
                    <h2>
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.detail.title">CompanyEmployee</Translate> [<b>{companyEmployeeEntity.id}</b>]
                    </h2>
                    <dl className="jh-entity-details">
                        <dt>
                            <span id="active">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.active">Active</Translate>
                            </span>
                        </dt>
                        <dd>{companyEmployeeEntity.active ? 'true' : 'false'}</dd>
                        <dt>
                            <span id="dailyWorkload">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.dailyWorkload">Daily Workload</Translate>
                            </span>
                        </dt>
                        <dd>{readDailyDurationFromISODuration(companyEmployeeEntity)}</dd>
                        <dt>
                            <span id="monthlyWorkload">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.monthlyWorkload">Monthly Workload</Translate>
                            </span>
                        </dt>
                        <dd>{readMonthlyDurationFromISODuration(companyEmployeeEntity)}</dd>
                        <dt>
                            <span id="hourlyRate">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.hourlyRate">Hourly Rate</Translate>
                            </span>
                        </dt>
                        <dd>{companyEmployeeEntity.hourlyRate}</dd>
                        <dt>
                            <span id="salary">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.salary">Salary</Translate>
                            </span>
                        </dt>
                        <dd>{companyEmployeeEntity.salary}</dd>
                        <dt>
                            <span id="identificationNumber">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.identificationNumber">Identification Number</Translate>
                            </span>
                        </dt>
                        <dd>{companyEmployeeEntity.identificationNumber}</dd>
                        <dt>
                            <span id="canAnticipate">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.canAnticipate">Can Anticipate</Translate>
                            </span>
                        </dt>
                        <dd>{companyEmployeeEntity.canAnticipate ? 'Sim' : 'Não'}</dd>
                        <dt>
                            <span id="contractStartDate">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.contractStartDate">Contract Start Date</Translate>
                            </span>
                        </dt>
                        <dd>
                            <TextFormat value={companyEmployeeEntity.contractStartDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                        </dd>
                        <dt>
                            <span id="contractEndDate">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.contractEndDate">Contract End Date</Translate>
                            </span>
                        </dt>
                        <dd>
                            <TextFormat value={companyEmployeeEntity.contractEndDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                        </dd>
                        <dt>
                            <span id="workdays">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.workdays">Workdays</Translate>
                            </span>
                        </dt>
                        <dd>
                            {companyEmployeeEntity.workdays?.map(it => (
                                <p key={it}>{translate(`giroEmployeeSalaryAdvanceApp.companyEmployee.workdaysNames.${it}`)}</p>
                            ))}
                        </dd>

                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.maritalStatus">maritalStatus</Translate>
                        </dt>
                        <dd>
                            <Translate contentKey={`giroEmployeeSalaryAdvanceApp.maritalStatus.${companyEmployeeEntity.info?.maritalStatus}`}>maritalStatus</Translate>
                        </dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.profession">profession</Translate>
                        </dt>
                        <dd>{companyEmployeeEntity.info?.profession ?? ''}</dd>

                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.nationality">nationality</Translate>
                        </dt>
                        <dd>{companyEmployeeEntity.info?.nationality ?? ''}</dd>

                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.mainPhoneNumber">mainPhoneNumber</Translate>
                        </dt>
                        <dd>{companyEmployeeEntity.info?.mainPhoneNumber?.number ?? ''}</dd>

                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.info.address">address</Translate>
                        </dt>
                        <dd>{companyEmployeeEntity.info?.address && <AddressCardDetail key={companyEmployeeEntity.info?.address?.id} address={companyEmployeeEntity.info?.address} />}</dd>

                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.organization">Organization</Translate>
                        </dt>
                        <dd>{companyEmployeeEntity.organization ? companyEmployeeEntity.organization.name : ''}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.person">Person</Translate>
                        </dt>
                        <dd>{companyEmployeeEntity.person ? companyEmployeeEntity.person.name : ''}</dd>
                        <>
                            <dt>
                                <Translate contentKey={`giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.identification.${companyEmployeeEntity.person?.type ?? 'label'}`}>Identification</Translate>
                            </dt>
                            {companyEmployeeEntity.person?.type ? (
                                <>
                                    {
                                        {
                                            INDIVIDUAL: <dd>{StringUtils.addCpfMask(companyEmployeeEntity.person?.identification)}</dd>,
                                            CORPORATION: <dd>{StringUtils.addCnpjMask(companyEmployeeEntity.person?.identification)}</dd>,
                                        }[companyEmployeeEntity.person.type]
                                    }
                                </>
                            ) : (
                                <dd>{companyEmployeeEntity.person?.identification ?? ''}</dd>
                            )}
                        </>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.company">Company</Translate>
                        </dt>
                        <dd>
                            <Link to={`/entity/company/${companyEmployeeEntity.company?.id}`}>{companyEmployeeEntity.company?.name}</Link>
                        </dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.companyEmployee.identification">Identification</Translate>
                        </dt>
                        <dd>{StringUtils.addCnpjMask(companyEmployeeEntity.company?.identification)}</dd>
                        <dt>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.label">Bank account</Translate>
                        </dt>
                        <dd>
                            {companyEmployeeEntity?.bankAccount && <BankAccountDetail account={companyEmployeeEntity.bankAccount} />}
                        </dd>

                        <dt>
                            <span>PIX</span>
                        </dt>
                        {companyEmployeeEntity.pixList && companyEmployeeEntity.pixList.map(pix => (
                            <>
                                <dt>
                                    <span>Chave PIX</span>
                                </dt>
                                <dd>{pix?.key ?? ''}</dd>
                                <dt>
                                    <span>Tipo de Chave PIX</span>
                                </dt>
                                <dd>{pix?.keyType ? translate(`pixKeyType.${pix.keyType}`) : ''}</dd>
                            </>

                        ))}
                    </dl>
                    <Button tag={Link} to="/entity/company-employee" replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.back">Back</Translate>
                        </span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/entity/company-employee/${companyEmployeeEntity.id}/edit`} replace color="primary">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ companyEmployee }: IRootState) => ({
    companyEmployeeEntity: companyEmployee.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEmployeeDetail);
