import { IBankAccount } from 'app/shared/model/bank-account.model';
import { IPhone } from 'app/shared/model/phone.model';
import { PersonType } from './enumerations/person-type.model';
import { IUser } from './user.model';

export interface IPerson {
    id?: string;
    name?: string;
    type?: PersonType;
    identification?: string;
    acceptedTerms?: boolean;
    accounts?: IBankAccount[];
    phones?: IPhone[];
    user?: IUser;
    email?: string;
}

export const defaultValue: Readonly<IPerson> = {
    acceptedTerms: false,
};
