import { IBank } from 'app/shared/model/bank.model';
import { IPerson } from 'app/shared/model/person.model';
import { ICardEdition } from './card-edition';
import { BankAccountType } from './enumerations/bank-account-type.model';
import { PersonType } from './enumerations/person-type.model';

export interface IBankAccount extends ICardEdition {
    id?: string;
    agencyNumber?: string;
    agencyDigit?: string;
    accountNumber?: string;
    accountDigit?: string;
    type?: BankAccountType;
    variation?: string;
    active?: boolean;
    approved?: boolean;
    favoredCompanyItself?: boolean;
    favoredName?: string;
    favoredType?: PersonType;
    favoredIdentification?: string;
    observation?: string;
    bank?: IBank;
    person?: IPerson;
}

export const defaultValue: Readonly<IBankAccount> = {
    active: false,
    approved: false,
    favoredCompanyItself: false,
    isNew: true,
};
