import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { readDurationFromISODuration } from 'app/shared/model/employee-time-entry.model';
import { IRootState } from 'app/shared/reducers';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import moment from 'moment';
import React from 'react';
import { getSortState, IPaginationBaseState, JhiItemCount, JhiPagination, TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { getEntities } from './employee-time-entry.reducer';

export interface IEmployeeTimeEntryProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type IEmployeeTimeEntryState = IPaginationBaseState;

export class EmployeeTimeEntry extends React.Component<IEmployeeTimeEntryProps, IEmployeeTimeEntryState> {
    state: IEmployeeTimeEntryState = {
        ...getSortState(this.props.location, ITEMS_PER_PAGE),
    };

    componentDidMount() {
        this.getEntities();
    }

    sort = prop => () => {
        this.setState(
            {
                order: this.state.order === 'asc' ? 'desc' : 'asc',
                sort: prop,
            },
            () => this.sortEntities()
        );
    };

    sortEntities() {
        this.getEntities();
        this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }

    handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

    getEntities = () => {
        const { activePage, itemsPerPage, sort, order } = this.state;
        this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    };

    render() {
        const { employeeTimeEntryList, match, totalItems } = this.props;
        return (
            <div>
                <h2 id="employee-time-entry-heading">
                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.home.title">Employee Time Entries</Translate>
                    <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.home.createLabel">Create new Employee Time Entry</Translate>
                    </Link>
                    <Link to={`${match.url}/upload`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.home.uploadLabel">Create new Employee Time Entry</Translate>
                    </Link>
                </h2>
                <div className="table-responsive">
                    {employeeTimeEntryList && employeeTimeEntryList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="hand" onClick={this.sort('id')}>
                                        <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('hourlyRate')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.hourlyRate">Hourly Rate</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('duration')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.duration">Duration</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('employeeIdentificationNumber')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.employeeIdentificationNumber">Employee Identification Number</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('date')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.date">Date</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('creditAmount')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.creditAmount">Credit Amount</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('status')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('employee.id')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.employee">Employee</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('month.month')}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.month">Month</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {employeeTimeEntryList.map((timeEntry, i) => (
                                    <tr key={`entity-${i}`}>
                                        <td>
                                            <Button tag={Link} to={`${match.url}/${timeEntry.id}`} color="link" size="sm">
                                                {timeEntry.id}
                                            </Button>
                                        </td>
                                        <td>
                                            {timeEntry.hourlyRate?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>{readDurationFromISODuration(timeEntry)}</td>
                                        <td>{timeEntry.employeeIdentificationNumber}</td>
                                        <td>
                                            <TextFormat type="date" value={timeEntry.date} format={APP_LOCAL_DATE_FORMAT} />
                                        </td>
                                        <td>
                                            {timeEntry.creditAmount?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) ?? ''}
                                        </td>
                                        <td>
                                            <Translate contentKey={`giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.${timeEntry.status}`} />
                                        </td>
                                        <td>{timeEntry.employee ? <Link to={`company-employee/${timeEntry.employee.id}`}>{timeEntry.employee?.person?.name ?? timeEntry.employee?.id ?? ''}</Link> : ''}</td>
                                        <td>{timeEntry.month ? <Link to={`employee-monthly-credit/${timeEntry.month.id}`}>{moment(timeEntry.month.month).format('MM/YYYY')}</Link> : ''}</td>
                                        <td className="text-right">
                                            <div className="btn-group flex-btn-group-container">
                                                <Button tag={Link} to={`${match.url}/${timeEntry.id}`} color="info" size="sm">
                                                    <FontAwesomeIcon icon="eye" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.view">View</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${timeEntry.id}/edit`} color="primary" size="sm">
                                                    <FontAwesomeIcon icon="pencil-alt" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.edit">Edit</Translate>
                                                    </span>
                                                </Button>
                                                <Button tag={Link} to={`${match.url}/${timeEntry.id}/delete`} color="danger" size="sm">
                                                    <FontAwesomeIcon icon="trash" />{' '}
                                                    <span className="d-none d-md-inline">
                                                        <Translate contentKey="entity.action.delete">Delete</Translate>
                                                    </span>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-warning">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeTimeEntry.home.notFound">No Employee Time Entries found</Translate>
                        </div>
                    )}
                </div>
                <div className={employeeTimeEntryList && employeeTimeEntryList.length > 0 ? '' : 'd-none'}>
                    <Row className="justify-content-center">
                        <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                    </Row>
                    <Row className="justify-content-center">
                        <JhiPagination activePage={this.state.activePage} onSelect={this.handlePagination} maxButtons={5} itemsPerPage={this.state.itemsPerPage} totalItems={this.props.totalItems} />
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ employeeTimeEntry }: IRootState) => ({
    employeeTimeEntryList: employeeTimeEntry.entities,
    totalItems: employeeTimeEntry.totalItems,
});

const mapDispatchToProps = {
    getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimeEntry);
