import { HIDE_MODAL, SHOW_MODAL } from './modal-action-types';

export const showModal = (type, props) => ({
    type: SHOW_MODAL,
    payload: {
        type,
        props,
    },
});

export const hideModal = () => ({
    type: HIDE_MODAL,
});
