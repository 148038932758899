import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'app/components/loading/loading';
import { getEntities as getCompanyEmployees } from 'app/entities/company-employee/company-employee.reducer';
import { getEntities as getEmployeeMonthlyCredits } from 'app/entities/employee-monthly-credit/employee-monthly-credit.reducer';
import { IRootState } from 'app/shared/reducers';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './employee-anticipation.reducer';

export interface IEmployeeAnticipationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEmployeeAnticipationUpdateState {
    isNew: boolean;
}

export class EmployeeAnticipationUpdate extends React.Component<IEmployeeAnticipationUpdateProps, IEmployeeAnticipationUpdateState> {
    constructor(props) {
        super(props);
        this.state = {
            isNew: !this.props.match.params || !this.props.match.params.id,
        };
    }

    componentWillUpdate(nextProps, _nextState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }
    }

    saveEntity = (event, errors, values) => {
        values.requestedDate = convertDateTimeToServer(values.requestedDate);
        values.acceptedDate = convertDateTimeToServer(values.acceptedDate);
        values.paidDate = convertDateTimeToServer(values.paidDate);

        if (errors.length === 0) {
            const { employeeAnticipationEntity } = this.props;
            const entity = {
                ...employeeAnticipationEntity,
                ...values,
            };

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };

    handleClose = () => {
        this.props.history.push('/entity/employee-anticipation');
    };

    render() {
        const { employeeAnticipationEntity, loading, updating } = this.props;
        const { isNew } = this.state;

        if (!isNew && (employeeAnticipationEntity == null || employeeAnticipationEntity.id == null)) {
            return <Loading />;
        }

        return (
            <div>
                <Row className="justify-content-center">
                    <Col md="8">
                        <h2 id="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.createOrEditLabel">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.home.createOrEditLabel">Create or edit a EmployeeAnticipation</Translate>
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8">
                        {loading ? (
                            <Loading />
                        ) : (
                            <AvForm model={isNew ? {} : employeeAnticipationEntity} onSubmit={this.saveEntity}>
                                {!isNew ? (
                                    <AvGroup>
                                        <Label for="employee-anticipation-id">
                                            <Translate contentKey="global.field.id">ID</Translate>
                                        </Label>
                                        <AvInput id="employee-anticipation-id" type="text" className="form-control" name="id" required readOnly />
                                    </AvGroup>
                                ) : null}
                                <AvGroup>
                                    <Label for="employee-anticipation-external-uid">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.externalUid">External UID</Translate>
                                    </Label>
                                    <AvInput id="employee-anticipation-external-uid" type="text" className="form-control" name="externalUid" />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="amountLabel" for="employee-anticipation-amount">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.amount">Amount</Translate>
                                    </Label>
                                    <AvField
                                        id="employee-anticipation-amount"
                                        type="text"
                                        name="amount"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            min: { value: 0, errorMessage: translate('entity.validation.min', { min: 0 }) },
                                            max: { value: 10000, errorMessage: translate('entity.validation.max', { max: 10000 }) },
                                            number: { value: true, errorMessage: translate('entity.validation.number') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="requestedDateLabel" for="employee-anticipation-requestedDate">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.requestedDate">Requested Date</Translate>
                                    </Label>
                                    <AvInput
                                        id="employee-anticipation-requestedDate"
                                        type="datetime-local"
                                        className="form-control"
                                        name="requestedDate"
                                        placeholder={'YYYY-MM-DD HH:mm'}
                                        value={isNew ? null : convertDateTimeFromServer(this.props.employeeAnticipationEntity.requestedDate)}
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="acceptedDateLabel" for="employee-anticipation-acceptedDate">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.acceptedDate">Accepted Date</Translate>
                                    </Label>
                                    <AvInput
                                        id="employee-anticipation-acceptedDate"
                                        type="datetime-local"
                                        className="form-control"
                                        name="acceptedDate"
                                        placeholder={'YYYY-MM-DD HH:mm'}
                                        value={isNew ? null : convertDateTimeFromServer(this.props.employeeAnticipationEntity.acceptedDate)}
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                        }}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="paidDateLabel" for="employee-anticipation-paidDate">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.paidDate">Paid Date</Translate>
                                    </Label>
                                    <AvInput
                                        id="employee-anticipation-paidDate"
                                        type="datetime-local"
                                        className="form-control"
                                        name="paidDate"
                                        placeholder={'YYYY-MM-DD HH:mm'}
                                        value={isNew ? null : convertDateTimeFromServer(this.props.employeeAnticipationEntity.paidDate)}
                                    />
                                </AvGroup>
                                <AvGroup>
                                    <Label id="statusLabel" for="employee-anticipation-status">
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.status">Status</Translate>
                                    </Label>
                                    <AvInput id="employee-anticipation-status" type="select" className="form-control" name="status" value={(!isNew && employeeAnticipationEntity.status) || 'REQUESTED'}>
                                        <option value="NEW">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.NEW')}</option>
                                        <option value="PENDING">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.PENDING')}</option>
                                        <option value="REJECTED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.REJECTED')}</option>
                                        <option value="PROCESSED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.PROCESSED')}</option>
                                        <option value="ERROR">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.ERROR')}</option>
                                        <option value="EXPIRED">{translate('giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.EXPIRED')}</option>
                                    </AvInput>
                                </AvGroup>
                                <Button tag={Link} id="cancel-save" to="/entity/employee-anticipation" replace color="info">
                                    <FontAwesomeIcon icon="arrow-left" />
                                    &nbsp;
                                    <span className="d-none d-md-inline">
                                        <Translate contentKey="entity.action.back">Back</Translate>
                                    </span>
                                </Button>
                                &nbsp;
                                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                                    <FontAwesomeIcon icon="save" />
                                    &nbsp;
                                    <Translate contentKey="entity.action.save">Save</Translate>
                                </Button>
                            </AvForm>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    employeeMonthlyCredits: storeState.employeeMonthlyCredit.entities,
    companyEmployees: storeState.companyEmployee.entities,
    employeeAnticipationEntity: storeState.employeeAnticipation.entity,
    loading: storeState.employeeAnticipation.loading,
    updating: storeState.employeeAnticipation.updating,
    updateSuccess: storeState.employeeAnticipation.updateSuccess,
});

const mapDispatchToProps = {
    getEmployeeMonthlyCredits,
    getCompanyEmployees,
    getEntity,
    updateEntity,
    createEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAnticipationUpdate);
