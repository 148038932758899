import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hideModal, showModal } from 'app/components/modal/modal-action';
import { MODAL_TYPE_SELECT_OPTION } from 'app/components/modal/modal-types';
import { InputPersonIdentification } from 'app/components/person/person-identification-input';
import { getBanks } from 'app/entities/code-base/bank/bank.reducer';
import { IBankAccount } from 'app/shared/model/bank-account.model';
import { IBank } from 'app/shared/model/bank.model';
import { BankAccountType } from 'app/shared/model/enumerations/bank-account-type.model';
import { PersonType } from 'app/shared/model/enumerations/person-type.model';
import { IRootState } from 'app/shared/reducers';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';

export interface IBankAccountCardManagerProps extends StateProps, DispatchProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    getBanks: typeof getBanks;
    account: IBankAccount;
    updateCallback?: (bankAccount: IBankAccount) => void;
}

export interface IBankAccountCardManagerState {
    account: IBankAccount;
}

export class BankAccountCardManager extends React.Component<IBankAccountCardManagerProps, IBankAccountCardManagerState> {
    constructor(props: Readonly<IBankAccountCardManagerProps>) {
        super(props);
        this.state = {
            account: props.account,
        };
    }

    handleOnBankPressed = (event: any) => {
        this.props.showModal(MODAL_TYPE_SELECT_OPTION, {
            displayProperty: [['number'], ['name']],
            title: translate('bankAccountManager.bank.search'),
            action: this.props.getBanks,
            placeholderInput: translate('bankAccountManager.bank.placeholder'),
            stateAction: 'bank',
            cancelCallback: () => this.props.hideModal(),
            saveCallback: (item: any) => this.handleBankSelected(item),
            separator: [[' ']],
        });
        event.preventDefault();
    };

    handleBankSelected = (item: IBank) => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, bank: item },
            }),
            () => this.updateCallback()
        );
        this.props.hideModal();
    };

    handleAccountNumberChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, accountNumber: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleAccountDigitChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, accountDigit: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleAgencyNumberChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, agencyNumber: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleAgencyDigitChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, agencyDigit: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleAccountTypeChange = (event: OnChangeEvent, value: BankAccountType): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, type: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleFavoredNameChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, favoredName: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleFavoredIdentificationChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, favoredIdentification: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleFavoredTypeChange = (event: OnChangeEvent, value: PersonType): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, favoredType: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleObservationChange = (event: OnChangeEvent, value: string): void => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, observation: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleVariationChange = (event: OnChangeEvent, value: string) => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, variation: value },
            }),
            () => this.updateCallback()
        );
        event.preventDefault();
    };

    handleActiveChange = (event: OnChangeEvent, value: boolean) => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, active: value },
            }),
            () => this.updateCallback()
        );
    };

    handleFavoredCompanyItselfChange = (event: OnChangeEvent, value: boolean) => {
        this.setState(
            ps => ({
                ...ps,
                account: { ...ps.account, favoredCompanyItself: value },
            }),
            () => this.updateCallback()
        );
    };

    updateCallback(): void {
        if (this.props.updateCallback) {
            this.props.updateCallback(this.state.account);
        }
    }

    render() {
        const { account } = this.state;
        return (
            <>
                <Row>
                    <Col md="11">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.bank">Bank</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="bank"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('bankAccountManager.validate.bank.required'),
                                    },
                                }}
                                onFocus={this.handleOnBankPressed}
                                onClick={this.handleOnBankPressed}
                                readOnly
                                value={account.bank ? account.bank.name : ''}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="1">
                        <Button color="primary" style={{ marginTop: '30px' }} type="button" onClick={this.handleOnBankPressed}>
                            <FontAwesomeIcon icon="search" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.agencyNumber">Agency number</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="agencyNumber"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('bankAccountManager.validate.agencyNumber.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('bankAccountManager.validate.agencyNumber.minlength'),
                                    },
                                    maxLength: {
                                        value: 16,
                                        errorMessage: translate('bankAccountManager.validate.agencyNumber.maxlength'),
                                    },
                                }}
                                onChange={this.handleAgencyNumberChange}
                                value={account.agencyNumber}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="6">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.agencyDigit">Agency digit</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="agencyDigit"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('bankAccountManager.validate.agencyDigit.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('bankAccountManager.validate.agencyDigit.minlength'),
                                    },
                                    maxLength: {
                                        value: 8,
                                        errorMessage: translate('bankAccountManager.validate.agencyDigit.maxlength'),
                                    },
                                }}
                                onChange={this.handleAgencyDigitChange}
                                value={account.agencyDigit}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.accountNumber">Account number</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="accountNumber"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('bankAccountManager.validate.accountNumber.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('bankAccountManager.validate.accountNumber.minlength'),
                                    },
                                    maxLength: {
                                        value: 16,
                                        errorMessage: translate('bankAccountManager.validate.accountNumber`.maxlength'),
                                    },
                                }}
                                onChange={this.handleAccountNumberChange}
                                value={account.accountNumber}
                            />
                        </AvGroup>
                    </Col>
                    <Col md="6">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.accountDigit">Account digit</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="accountDigit"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: translate('bankAccountManager.validate.accountDigit.required'),
                                    },
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('bankAccountManager.validate.accountDigit.minlength'),
                                    },
                                    maxLength: {
                                        value: 8,
                                        errorMessage: translate('bankAccountManager.validate.accountDigit.maxlength'),
                                    },
                                }}
                                onChange={this.handleAccountDigitChange}
                                value={account.accountDigit}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.type">Account Type</Translate>
                            </Label>
                            <AvField type="select" className="form-control" name="type" onChange={this.handleAccountTypeChange} value={account.type}>
                                <option value={BankAccountType.CHECKING_ACCOUNT}>{translate('bankAccountType.CHECKING_ACCOUNT')}</option>
                                <option value={BankAccountType.SAVINGS_ACCOUNT}>{translate('bankAccountType.SAVINGS_ACCOUNT')}</option>
                            </AvField>
                        </AvGroup>
                    </Col>

                    <Col md="2">
                        <AvGroup check>
                            <Label check>
                                <AvInput type="checkbox" name="active" onChange={this.handleActiveChange} value={account.active} checked={account.active === true} /> <Translate contentKey="bankAccount.active">Active</Translate>
                            </Label>
                        </AvGroup>
                    </Col>

                    <Col md="6">
                        <AvGroup check>
                            <Label check>
                                <AvInput type="checkbox" name="favoredCompanyItself" onChange={this.handleFavoredCompanyItselfChange} value={account.favoredCompanyItself} checked={account.favoredCompanyItself === true} />{' '}
                                <Translate contentKey="bankAccount.favoredCompanyItself">Favored is the company itself</Translate>
                            </Label>
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.favoredName">Favored Name</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="favoredName"
                                validate={{
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('bankAccountManager.validate.favoredName.minlenght'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('bankAccountManager.validate.favoredName.maxlength'),
                                    },
                                }}
                                onChange={this.handleFavoredNameChange}
                                value={account.favoredName}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.favoredType">Favored type</Translate>
                            </Label>
                            <AvField type="select" className="form-control" name="favoredType" onChange={this.handleFavoredTypeChange} value={account.favoredType}>
                                <option value={PersonType.INDIVIDUAL}>{translate('favoredType.INDIVIDUAL')}</option>
                                <option value={PersonType.CORPORATION}>{translate('favoredType.CORPORATION')}</option>
                            </AvField>
                        </AvGroup>
                    </Col>
                    <Col md="8">
                        <InputPersonIdentification
                            identification={account.favoredIdentification}
                            type={account.favoredType}
                            label={{
                                INDIVIDUAL: 'bankAccount.favoredIdentification.cpf',
                                CORPORATION: 'bankAccount.favoredIdentification.cnpj',
                            }}
                            invalidMessage={{
                                INDIVIDUAL: 'bankAccountManager.validate.favoredIdentification.cpf',
                                CORPORATION: 'bankAccountManager.validate.favoredIdentification.cnpj',
                            }}
                            changeCallback={this.handleFavoredIdentificationChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <AvGroup>
                            <Label>
                                <Translate contentKey="bankAccount.observation">Observation</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="observation"
                                validate={{
                                    minLength: {
                                        value: 1,
                                        errorMessage: translate('bankAccountManager.validate.observation.minlenght'),
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: translate('bankAccountManager.validate.observation.maxlength'),
                                    },
                                }}
                                onChange={this.handleObservationChange}
                                value={account.observation}
                            />
                        </AvGroup>
                    </Col>
                </Row>
                {account.type === BankAccountType.SAVINGS_ACCOUNT && (
                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label>
                                    <Translate contentKey="bankAccount.variation">Variation</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="variation"
                                    validate={{
                                        minLength: {
                                            value: 1,
                                            errorMessage: translate('bankAccountManager.validate.variation.minlenght'),
                                        },
                                        maxLength: {
                                            value: 8,
                                            errorMessage: translate('bankAccountManager.validate.variation.maxlength'),
                                        },
                                    }}
                                    onChange={this.handleVariationChange}
                                    value={account.variation}
                                />
                            </AvGroup>
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = dispatch => {
    // return {
    //   hideModal: () => dispatch(hideModal()),
    //   dispatch
    // };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountCardManager);
