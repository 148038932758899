import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as MaterialButton, Card } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from 'app/components/loading/loading';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { EmployeeAnticipationStatus } from 'app/shared/model/enumerations/employee-anticipation-status.model';
import { SessionTermStatus } from 'app/shared/model/session-term.model';
import { IRootState } from 'app/shared/reducers';
import moment from 'moment';
import React from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import BankAccountDetail from './components/bank-account/bank-account-detail';
import { CompanyEmployeeDetail } from './components/company-employee/company-employee-detail';
import {
    acceptAnticipation,
    declineAnticipation,
    expireAnticipation,
    getEntity,
    regenerateAssignmentTerm,
    sendAnticipationAssignmentSignatureEmail,
    sendAnticipationAssignmentSignatureSms,
    sendEmployeeAssignmentSignatureEmail,
    sendEmployeeAssignmentSignatureSms,
} from './employee-anticipation.reducer';

export interface IEmployeeAnticipationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEmployeeAnticipationDetailState {
    openAccept: boolean;
    openDecline: boolean;
    openExpire: boolean;
    openResult: boolean;
    sendSuccess: boolean;
    sendError: boolean;
    openAnticipationAssigmentRegenerate: boolean;
}

export class EmployeeAnticipationDetail extends React.Component<IEmployeeAnticipationDetailProps, IEmployeeAnticipationDetailState> {
    constructor(props) {
        super(props);
        this.state = {
            openAccept: false,
            openDecline: false,
            sendError: false,
            openExpire: false,
            sendSuccess: false,
            openResult: false,
            openAnticipationAssigmentRegenerate: false,
        };
    }

    componentDidMount() {
        this.props.getEntity(this.props.match.params.id);
    }

    acceptAnticipation = () => {
        this.setState({
            openAccept: true,
        });
    };

    openAnticipationAssignment = () => {
        window.open(this.props.employeeAnticipationEntity.assignmentTerm?.file?.url);
    };

    openAnticipationAssignmentDocx = () => {
        window.open(this.props.employeeAnticipationEntity.assignmentTermTemplate?.file?.url);
    };

    openEmployeeAssignmentDocx = () => {
        window.open(this.props.employeeAnticipationEntity.employee?.assignmentTermTemplate?.file?.url);
    };

    openEmployeeAssignment = () => {
        window.open(this.props.employeeAnticipationEntity.employee?.assignmentTerm?.file?.url);
    };

    sendAnticipationAssignmentSignatureEmail = async () => {
        const result = await this.props.sendAnticipationAssignmentSignatureEmail(this.props.employeeAnticipationEntity?.id);
        if (result?.payload?.status === 200) {
            this.setState({
                sendSuccess: true,
            });
        } else {
            this.setState({
                sendError: true,
            });
        }
    };

    sendAnticipationAssignmentSignatureSms = () => {};

    sendEmployeeAssignmentSignatureEmail = () => {};

    sendEmployeeAssignmentSignatureSms = () => {};

    rejectAnticipation = () => {
        this.setState({
            openDecline: true,
        });
    };
    expireAnticipation = () => {
        this.setState({
            openExpire: true,
        });
    };

    handleAcceptCancel = () => {
        this.setState({ openAccept: false });
    };

    handleAcceptConfirm = () => {
        this.setState({ openAccept: false }, () => {
            this.props.acceptAnticipation(this.props.employeeAnticipationEntity.id);
        });
    };

    handleExpireCancel = () => {
        this.setState({ openExpire: false });
    };

    handleSendClose = () => {
        this.setState({ sendSuccess: false, sendError: false });
    };

    handleExpireConfirm = () => {
        this.setState({ openExpire: false }, () => {
            this.props.expireAnticipation(this.props.employeeAnticipationEntity.id);
        });
    };

    handleDeclineCancel = () => {
        this.setState({ openDecline: false });
    };

    handleDeclineConfirm = () => {
        this.setState({ openDecline: false }, () => {
            this.props.declineAnticipation(this.props.employeeAnticipationEntity.id);
        });
    };

    handleAnticipationAssignmentTermRegenerate = () => {
        this.setState({ openAnticipationAssigmentRegenerate: true });
    };

    handleAnticipationAssignmentTermRegenerateCancel = () => {
        this.setState({ openAnticipationAssigmentRegenerate: false });
    };

    handleAnticipationAssignmentTermRegenerateConfirm = () => {
        this.setState({ openAnticipationAssigmentRegenerate: false }, () => {
            this.props.regenerateAssignmentTerm(this.props.employeeAnticipationEntity.id);
        });
    };

    render() {
        const { employeeAnticipationEntity: anticipation } = this.props;

        if (this.props.loading) {
            return <Loading />;
        }

        return (
            <div>
                <Dialog open={this.state.openAccept} onClose={this.handleAcceptCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.antecipation-confirmation.title">Accept</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.antecipation-confirmation.question">Accept</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleAcceptCancel} color="secondary">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.antecipation-confirmation.cancel">Cancel</Translate>
                        </MaterialButton>
                        <MaterialButton onClick={this.handleAcceptConfirm} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.antecipation-confirmation.confirm">Confirm</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.sendSuccess} onClose={this.handleSendClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.send-success.title">Success</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.send-success.message">Sent</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleSendClose} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.send-success.confirm">Close</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.sendError} onClose={this.handleSendClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.send-error.title">Error</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.send-error.message">Error</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleSendClose} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.send-error.confirm">Close</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openDecline} onClose={this.handleDeclineCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.decline.title">Decline</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.decline.question">Are you sure?</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleDeclineCancel} color="secondary">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.decline.cancel">Cancel</Translate>
                        </MaterialButton>
                        <MaterialButton onClick={this.handleDeclineConfirm} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.decline.confirm">Confirm</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openExpire} onClose={this.handleExpireCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.expire.title">Expire</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.expire.question">Are you sure?</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleExpireCancel} color="secondary">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.expire.cancel">Cancel</Translate>
                        </MaterialButton>
                        <MaterialButton onClick={this.handleExpireConfirm} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.expire.confirm">Confirm</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openAnticipationAssigmentRegenerate} onClose={this.handleAnticipationAssignmentTermRegenerateCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.anticipation-assingment-term-regenerate.title">Renerate</Translate>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.anticipation-assingment-term-regenerate.question">Are you sure?</Translate>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={this.handleAnticipationAssignmentTermRegenerateCancel} color="secondary">
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.anticipation-assingment-term-regenerate.cancel">Cancel</Translate>
                        </MaterialButton>
                        <MaterialButton onClick={this.handleAnticipationAssignmentTermRegenerateConfirm} color="primary" autoFocus>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.dialog.anticipation-assingment-term-regenerate.confirm">Confirm</Translate>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>

                <Row>
                    <Col md="8">
                        <h2>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.title">EmployeeAnticipation</Translate> [<b>{anticipation.id}</b>]
                        </h2>
                        <dl className="jh-entity-details">
                            <dt>
                                <span id="amount">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.externalUid">External UID</Translate>
                                </span>
                            </dt>
                            <dd>{anticipation.externalUid}</dd>
                            <dt>
                                <span id="amount">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.employeeAmount">EmployeeAmount</Translate>
                                </span>
                            </dt>
                            <dd>{anticipation.employeeAmount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? ''}</dd>
                            <dt>
                                <span id="amount">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.taxAmount">Tax amount</Translate>
                                </span>
                            </dt>
                            <dd>{anticipation.taxAmount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? ''}</dd>
                            <dt>
                                <span id="amount">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.totalAmount">Total Amount</Translate>
                                </span>
                            </dt>
                            <dd>{anticipation.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) ?? ''}</dd>
                            <dt>
                                <span id="requestedDate">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.requestedDate">Requested Date</Translate>
                                </span>
                            </dt>
                            <dd>
                                <TextFormat value={anticipation.requestedDate} type="date" format={APP_DATE_FORMAT} />
                            </dd>
                            <dt>
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.ratePerMonth">Rate per month</Translate>
                            </dt>
                            <dd>{anticipation?.ratePerMonth}%</dd>
                            <dt>
                                <span id="acceptedDate">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.acceptedDate">Accepted Date</Translate>
                                </span>
                            </dt>
                            <dd>
                                <TextFormat value={anticipation.acceptedDate} type="date" format={APP_DATE_FORMAT} />
                            </dd>
                            <dt>
                                <span id="paidDate">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.paidDate">Paid Date</Translate>
                                </span>
                            </dt>
                            <dd>
                                <TextFormat value={anticipation.paidDate} type="date" format={APP_DATE_FORMAT} />
                            </dd>
                            <dt>
                                <span id="status">
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.status">Status</Translate>
                                </span>
                            </dt>
                            <dd>
                                <Translate contentKey={`giroEmployeeSalaryAdvanceApp.EmployeeAnticipationStatus.${anticipation.status}`}>Status</Translate>
                            </dd>
                            <dt>
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.month">Month</Translate>
                            </dt>
                            <dd>{moment(anticipation.month?.month).format('MM/YYYY')}</dd>
                        </dl>
                        <div className="anticipation-employee">
                            <h3>
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.employee">Employee</Translate>
                            </h3>
                            {anticipation.employee && <CompanyEmployeeDetail employee={anticipation.employee} />}
                        </div>
                        <div className="anticipation-bank-account">
                            <h3>
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.bank-account.label">Bank Account</Translate>
                            </h3>
                            {anticipation.employee?.bankAccount && <BankAccountDetail account={anticipation.employee.bankAccount} />}
                        </div>
                        <div className="anticipation-company">
                            <h3>
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.company.title">Company</Translate>
                            </h3>
                            <dl>
                                <dt>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.company.name">Company name</Translate>
                                </dt>
                                <dd>
                                    <Link to={`/entity/company/${anticipation.employee?.company?.id}`}>{anticipation.employee?.company?.name}</Link>
                                </dd>
                                <dt>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.company.identification">Identification</Translate>
                                </dt>
                                <dd>{anticipation.employee?.company?.identification}</dd>
                            </dl>
                        </div>
                        {anticipation?.assignmentTerm && (
                            <div className="anticipation-assignment-term">
                                <h3>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.assignment-term.title">AssignmentTerm</Translate>
                                </h3>
                                <dl>
                                    <dt>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.assignment-term.status.label">Status</Translate>
                                    </dt>
                                    <dd>
                                        <Translate contentKey={`giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.assignment-term.status.${anticipation.assignmentTerm?.status}`}>Status</Translate>
                                    </dd>
                                </dl>
                                {SessionTermStatus.GENERATING !== anticipation.assignmentTerm?.status && (
                                    <dl>
                                        <MaterialButton variant="contained" color="primary" onClick={this.handleAnticipationAssignmentTermRegenerate}>
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.assignment-term.regenerate">Regenerate</Translate>
                                        </MaterialButton>
                                    </dl>
                                )}
                                {[SessionTermStatus.GENERATING, SessionTermStatus.ERROR].every(it => it !== anticipation.assignmentTerm?.status) && (
                                    <dl>
                                        <MaterialButton variant="contained" color="primary" onClick={this.openAnticipationAssignment}>
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.assignment-term.download-term">Download</Translate>
                                        </MaterialButton>
                                    </dl>
                                )}
                                {[SessionTermStatus.GENERATING, SessionTermStatus.ERROR].every(it => it !== anticipation.assignmentTermTemplate?.status) && (
                                    <dl>
                                        <MaterialButton variant="contained" color="primary" onClick={this.openAnticipationAssignmentDocx}>
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.assignment-term.download-template">Download</Translate>
                                        </MaterialButton>
                                    </dl>
                                )}
                            </div>
                        )}
                        <hr />
                        {anticipation?.employee?.assignmentTerm && (
                            <div className="employee-assignment-term">
                                <h3>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.assignment-term.title">AssignmentTerm</Translate>
                                </h3>
                                <dl>
                                    <dt>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.assignment-term.status.label">Status</Translate>
                                    </dt>
                                    <dd>
                                        <Translate contentKey={`giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.assignment-term.status.${anticipation.employee?.assignmentTerm?.status}`}>Status</Translate>
                                    </dd>
                                </dl>
                                {[SessionTermStatus.GENERATING, SessionTermStatus.ERROR].every(it => it !== anticipation.employee?.assignmentTerm?.status) && (
                                    <dl>
                                        <MaterialButton variant="contained" color="primary" onClick={this.openEmployeeAssignment}>
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.assignment-term.download-term">Download</Translate>
                                        </MaterialButton>
                                    </dl>
                                )}
                                {[SessionTermStatus.GENERATING, SessionTermStatus.ERROR].every(it => it !== anticipation.employee?.assignmentTermTemplate?.status) && (
                                    <dl>
                                        <MaterialButton variant="contained" color="primary" onClick={this.openEmployeeAssignmentDocx}>
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.employee.assignment-term.download-template">Download</Translate>
                                        </MaterialButton>
                                    </dl>
                                )}
                            </div>
                        )}
                        <br />
                        <br />
                        <hr />
                        <br />
                        <br />
                        <h3>
                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.signature.title">Signature</Translate>
                        </h3>
                        {anticipation?.assignmentTermTemplate && (anticipation?.employee?.assignmentTermTemplate?.status === SessionTermStatus.WAITING_SUBSCRIPTION || anticipation?.assignmentTermTemplate?.status === SessionTermStatus.CREATED) && (
                            <div>
                                <p>
                                    <MaterialButton variant="contained" color="primary" onClick={this.sendAnticipationAssignmentSignatureEmail}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.signature.anticipation-term.send-email">Send by email</Translate>
                                    </MaterialButton>
                                </p>
                                <p>
                                    <MaterialButton variant="contained" color="primary" onClick={this.sendAnticipationAssignmentSignatureSms}>
                                        <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.signature.anticipation-term.send-sms">Send by sms</Translate>
                                    </MaterialButton>
                                </p>
                            </div>
                        )}
                        {anticipation?.employee?.assignmentTermTemplate &&
                            (anticipation?.employee?.assignmentTermTemplate?.status === SessionTermStatus.WAITING_SUBSCRIPTION || anticipation?.employee?.assignmentTermTemplate?.status === SessionTermStatus.CREATED) && (
                                <div>
                                    <p>
                                        <MaterialButton variant="contained" color="primary" onClick={this.sendEmployeeAssignmentSignatureEmail}>
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.signature.employee-term.send-email">Send by email</Translate>
                                        </MaterialButton>
                                    </p>
                                    <p>
                                        <MaterialButton variant="contained" color="primary" onClick={this.sendEmployeeAssignmentSignatureSms}>
                                            <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.signature.employee-term.send-sms">Send by sms</Translate>
                                        </MaterialButton>
                                    </p>
                                </div>
                            )}
                        <hr />
                        <br />
                        <br />
                        <hr />
                        <br />
                        <br />
                        {anticipation.status === EmployeeAnticipationStatus.PENDING && (
                            <Card style={{ padding: 10 }}>
                                <h3>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.actions.title">Actions</Translate>
                                </h3>
                                <MaterialButton variant="contained" color="primary" onClick={this.acceptAnticipation}>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.actions.accept-anticipation">Accept</Translate>
                                </MaterialButton>
                                <MaterialButton variant="contained" color="secondary" onClick={this.rejectAnticipation}>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.actions.reject-anticipation">Reject</Translate>
                                </MaterialButton>
                                <MaterialButton variant="contained" color="secondary" onClick={this.expireAnticipation}>
                                    <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.actions.expire-anticipation">Expire</Translate>
                                </MaterialButton>
                            </Card>
                        )}
                        <br />
                        <br />
                        <Button tag={Link} to={`/entity/company-employee/${anticipation.employee?.id}/edit`} replace color="primary">
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                                <Translate contentKey="giroEmployeeSalaryAdvanceApp.employeeAnticipation.detail.editEmployee">Edit</Translate>
                            </span>
                        </Button>
                        <br />
                        <br />
                        <Button tag={Link} to="/entity/employee-anticipation" replace color="info">
                            <FontAwesomeIcon icon="arrow-left" />{' '}
                            <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.back">Back</Translate>
                            </span>
                        </Button>
                        &nbsp;
                        {/* <Button tag={Link} to={`/entity/employee-anticipation/${employeeAnticipationEntity.id}/edit`} replace color="primary">
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                        </Button> */}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    employeeAnticipationEntity: storeState.employeeAnticipation.entity,
    loading: storeState.employeeAnticipation.loading,
    updateSuccess: storeState.employeeAnticipation.updateSuccess,
});

const mapDispatchToProps = {
    getEntity,
    acceptAnticipation,
    sendAnticipationAssignmentSignatureEmail,
    sendAnticipationAssignmentSignatureSms,
    sendEmployeeAssignmentSignatureEmail,
    sendEmployeeAssignmentSignatureSms,
    expireAnticipation,
    declineAnticipation,
    regenerateAssignmentTerm,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAnticipationDetail);
