import { PersonType } from 'app/shared/model/enumerations/person-type.model';
import { OnChangeEvent } from 'app/util/types';
import { AvField, AvGroup } from 'availity-reactstrap-validation';
import { isCNPJ, isCPF } from 'brazilian-values';
import React from 'react';
import InputMask from 'react-input-mask';
import { Translate, translate } from 'react-jhipster';
import { Input, Label } from 'reactstrap';

export interface ICNPJProps {
    identification: string;
    type: PersonType;
    required?: boolean;
    label: Record<PersonType, string>;
    invalidMessage: Record<PersonType, string>;
    requiredMessage?: Record<PersonType, string>;
    changeCallback: (event: OnChangeEvent, value: string) => void;
}

export const InputPersonIdentification = (props: ICNPJProps): JSX.Element => {
    const validate = (): string | boolean => {
        switch (props.type) {
            case PersonType.INDIVIDUAL:
                return !props.required || isCPF(props.identification) ? true : translate(props.invalidMessage.INDIVIDUAL);
            case PersonType.CORPORATION:
                return !props.required || isCNPJ(props.identification) ? true : translate(props.invalidMessage.CORPORATION);
            default:
                return true;
        }
    };

    const onChange = (event: OnChangeEvent, value: string) => {
        return props.changeCallback(event, value.replace(/\D/g, ''));
    };

    switch (props.type) {
        case PersonType.INDIVIDUAL:
            return (
                <AvGroup>
                    <Label>
                        <Translate contentKey={props.label.INDIVIDUAL} />
                    </Label>
                    {props.required ? (
                        <AvField
                            type="text"
                            className="form-control"
                            name="cpf"
                            mask="999.999.999-99"
                            tag={[Input, InputMask]}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: translate(props.requiredMessage.INDIVIDUAL),
                                },
                                identification: validate,
                            }}
                            onChange={onChange}
                            value={props.identification}
                        />
                    ) : (
                        <AvField type="text" className="form-control" name="cpf" mask="999.999.999-99" tag={[Input, InputMask]} validate={{ identification: validate }} onChange={onChange} value={props.identification} />
                    )}
                </AvGroup>
            );
        case PersonType.CORPORATION:
            return (
                <AvGroup>
                    <Label>
                        <Translate contentKey={props.label.CORPORATION} />
                    </Label>
                    {props.required ? (
                        <AvField
                            type="text"
                            className="form-control"
                            name="cnpj"
                            mask="99.999.999/9999-99"
                            tag={[Input, InputMask]}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: translate(props.requiredMessage.INDIVIDUAL),
                                },
                                identification: validate,
                            }}
                            onChange={onChange}
                            value={props.identification}
                        />
                    ) : (
                        <AvField
                            type="text"
                            className="form-control"
                            name="cnpj"
                            mask="99.999.999/9999-99"
                            tag={[Input, InputMask]}
                            validate={{
                                identification: validate,
                            }}
                            onChange={onChange}
                            value={props.identification}
                        />
                    )}
                </AvGroup>
            );
        default:
            return <></>;
    }
};
