import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { createOrganization, getOrganization, reset, updateOrganization } from './organization.reducer';

export interface IOrganizationManagerProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const OrganizationManager = (props: IOrganizationManagerProps) => {
    const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

    useEffect(() => {
        if (isNew) {
            props.reset();
        } else {
            props.getOrganization(props.match.params.id);
        }
        return () => props.reset();
    }, []);

    const handleClose = () => {
        props.history.push('/entity/organization');
    };

    const isInvalid = false;
    const { organization, loading, updating } = props;

    const saveOrganization = (event, values) => {
        if (isNew) {
            props.createOrganization(values);
        } else {
            values.id = organization.id;
            props.updateOrganization(values);
        }
        handleClose();
    };

    return (
        <div>
            <Row className="justify-content-center">
                <Col md="8">
                    <h1>
                        <Translate contentKey="organizationManager.createOrEditLabel">Create or edit a Organization</Translate>
                    </h1>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="8">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AvForm onValidSubmit={saveOrganization}>
                            <AvGroup>
                                <Label for="name">
                                    <Translate contentKey="organization.name">Name</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: translate('organizationManager.validate.name.required'),
                                        },
                                        minLength: {
                                            value: 1,
                                            errorMessage: translate('organizationManager.validate.name.minlength'),
                                        },
                                        maxLength: {
                                            value: 255,
                                            errorMessage: translate('organizationManager.validate.name.maxlength'),
                                        },
                                    }}
                                    value={organization.name}
                                />
                            </AvGroup>
                            <AvGroup>
                                <Label for="organizationKey">
                                    <Translate contentKey="organization.organizationKey">Identifier</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="organizationKey"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: translate('organizationManager.validate.organizationKey.required'),
                                        },
                                        pattern: {
                                            value: '^[_.@A-Za-z0-9-]*$',
                                            errorMessage: translate('organizationManager.validate.organizationKey.pattern'),
                                        },
                                        minLength: {
                                            value: 1,
                                            errorMessage: translate('organizationManager.validate.organizationKey.minlength'),
                                        },
                                        maxLength: {
                                            value: 255,
                                            errorMessage: translate('organizationManager.validate.name.maxlength'),
                                        },
                                    }}
                                    value={organization.organizationKey}
                                />
                            </AvGroup>
                            <Button tag={Link} to="/entity/organization" replace color="info">
                                <FontAwesomeIcon icon="arrow-left" />
                                &nbsp;
                                <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.back">Back</Translate>
                                </span>
                            </Button>
                            &nbsp;
                            <Button color="primary" type="submit" disabled={isInvalid || updating}>
                                <FontAwesomeIcon icon="save" />
                                &nbsp;
                                <Translate contentKey="entity.action.save">Save</Translate>
                            </Button>
                        </AvForm>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    organization: storeState.organization.organization,
    loading: storeState.organization.loading,
    updating: storeState.organization.updating,
});

const mapDispatchToProps = { getOrganization, updateOrganization, createOrganization, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationManager);
