import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import React from 'react';
import { Switch } from 'react-router-dom';
import EmployeeMonthlyCredit from './employee-monthly-credit';
import EmployeeMonthlyCreditDeleteDialog from './employee-monthly-credit-delete-dialog';
import EmployeeMonthlyCreditDetail from './employee-monthly-credit-detail';
import EmployeeMonthlyCreditUpdate from './employee-monthly-credit-update';

const Routes = ({ match }) => (
    <>
        <Switch>
            <ErrorBoundaryRoute exact path={`${match.url}/new`} component={EmployeeMonthlyCreditUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={EmployeeMonthlyCreditUpdate} />
            <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={EmployeeMonthlyCreditDetail} />
            <ErrorBoundaryRoute path={match.url} component={EmployeeMonthlyCredit} />
        </Switch>
        <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={EmployeeMonthlyCreditDeleteDialog} />
    </>
);

export default Routes;
